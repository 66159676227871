.add-custom-nft-container {
  background-color: var(--content-background-color) !important;
  min-height: 540px;
  position: relative;
  @media screen and (max-height: 600px) {
    height: 100% !important;
  }
  & > .wrapper {
    background-color: var(--content-background-color) !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }
}
