.choice-token-list-container {
  > .wrapper {
    background-color: var(--content-background-color) !important;
  }
  .price {
    font-size: 16px;
    font-weight: 600;
    color: var(--text-color-1);
  }
  .no-record-container {
    padding: 0;
    padding-bottom: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrap-name {
    width: 400px;
    @media (max-width: 600px) {
      width: 120px;
      word-break: break-all;
      word-wrap: break-word;
      white-space: pre-wrap;
    }
  }
}
