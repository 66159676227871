.icon-demo-container {
  background: #1d1d22;
  .wrapper {
    width: 840px;
    margin: auto;
    .list {
      background: #000;
      height: 100%;
      border-radius: 18px;
      overflow: auto;
    }
    .item {
      display: inline-block;
      text-align: center;
      margin-top: 20px;

      .name {
        font-size: 12px;
        color: #fff;
      }
      .icon-wrap {
        width: 120px;
        height: 120px;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .anticon {
          font-size: 60px;
          transition: font-size 0.2s linear, width 0.2s linear;
        }
        &:hover {
          .anticon {
            font-size: 120px;
          }
        }
      }
    }
  }

  .wrapper {
    justify-content: center;
  }
  .color-content {
    display: flex;
    gap: 40px;
    justify-content: center;
    margin-top: 340px;
    .color-item-title {
      color: #fff;
      font-size: 16px;
    }
  }
}

.theme-light {
  .icon-demo-container {
    .wrapper {
      .list {
        background: #fff;
        .name {
          color: #000;
        }
      }
    }
  }
}
