.custom-style-icon {
  position: fixed;
  bottom: 290px;
  right: 3vh;
  z-index: 99;
  background: #7673f9;
  box-shadow: 0px 0px 4px 4px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 600px) {
    display: none;
  }
}

.custom-style-popover {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  .ant-popover-title {
    height: 60px;
    line-height: 50px;
    font-size: 18px;
  }
}

.color-popover {
  .ant-popover-inner-content {
    padding: 0;
  }
}
.tab-content {
  width: 442px;
  height: 446px;
  &.json-content {
    overflow-y: auto;
    user-select: text;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    position: relative;
    .copy-icon {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
}
.color-list {
  .item {
    display: flex;
    align-items: center;
    height: 34px;
    padding: 0 16px;
    &:hover {
      background: #f5f5f5;
    }
    .label {
      margin-right: 10px;
      width: 270px;
    }
    .color-box {
      width: 130px;
      display: flex;
      gap: 10px;
      justify-content: flex-end;
      .color {
        width: 18px;
        height: 18px;
        border: 1px solid #c0beff;
        cursor: pointer;
        &:hover {
          border-color: #7673f9;
          border-width: 2px;
        }
      }
    }
  }
}
