.div-input-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  .ant-input {
    padding: 0;
    border: none !important;
    height: 22px;
    min-height: 22px;
    max-height: 44px;
    overflow-y: hidden;
    resize: none;
  }
}
