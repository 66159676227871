.switch-chain-container {
  .ant-modal-body {
    padding-left: 0;
    padding-right: 0;
  }
  .content {
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    .logo {
      width: 58px;
      height: 58px;
      margin-top: 23px;
    }
    .website_name {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      color: var(--text-color-1);
      opacity: 1;
      margin-top: 13px;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 19px;
      color: var(--text-color-1);
      opacity: 1;
      margin-top: 33px;
      padding: 0 var(--text-distance-1);
    }
    .switch {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 23px;
      margin-bottom: 40px;
      .left {
        width: 56px;
        height: 56px;
        border-radius: 56px;
        position: relative;
        .img {
          border-radius: 56px;
        }
        .switch_word_left {
          position: absolute;
          bottom: -30px;
          left: -60px;
          width: 180px;
          text-align: center;
          color: var(--text-color-1);
        }
      }
      .center {
        width: 160px;
        height: 27px;
      }
      .right {
        width: 56px;
        height: 56px;
        position: relative;
        .img {
          border-radius: 56px;
        }
        .switch_word_right {
          position: absolute;
          bottom: -30px;
          left: -60px;
          width: 180px;
          text-align: center;
          color: var(--text-color-1);
        }
      }
    }
    .buttons {
      display: flex;
      gap: 20px;
      width: 100%;
      padding: 0 var(--text-distance-1);
      margin-top: 30px;
      button {
        flex: 1;
      }
    }
    .wallet_footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      line-height: 40px;
      @media screen and (min-width: 600px) {
        display: none;
      }
      .logo_logo {
        width: 171px;
        height: 17px;
      }
    }
  }
}
