.add-custom-token-container {
  background-color: var(--content-background-color) !important;
  min-height: 540px;
  position: relative;
  @media screen and (max-height: 600px) {
    height: 100% !important;
  }
  > .wrapper {
    background-color: var(--content-background-color) !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }
  .add-custom-token-main {
    padding: 0 4px;
    padding-top: 10px;
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 600px) {
      height: 0;
      flex: 1;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0 8px;
    }
    .tips-Nx9 {
      display: flex;
      align-items: center;
      padding: 0 12px;
      margin-top: 10px;
      .wrap {
        background: rgb(234 185 129 / 20%);
        border-radius: 18px;
        display: flex;
        align-items: flex-start;
        padding: 0 14px;
        width: 100%;
        height: 60px;
        align-items: center;
        .left {
          .icon {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
        }
        .right {
          font-size: 15px;
          font-weight: 400;
          color: var(--text-color-1);
          line-height: 1.2;
        }
      }
    }
    .form-content {
      padding-top: 14px;
      flex: 1;
      height: 0;
      @media screen and (min-width: 600px) {
        height: 0;
        flex: 1;
      }
      .ant-form {
        height: 100%;
        .scroll-content {
          height: 100%;
          overflow: auto;
          padding: 0 12px;
          padding-bottom: 100px;
        }
      }

      .ant-form-item-label {
        padding: 0;
        label {
          font-size: 16px;
          line-height: 1;
          color: var(--text-color-1);
          font-weight: bold;
          &:before {
            display: none;
          }
        }
      }
      .form-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 12px;
        z-index: 1;
        margin-bottom: 0;
        padding-bottom: calc(var(--footer-height) - 8px + constant(safe-area-inset-bottom) / 3);
        background-color: var(--content-background-color);
        @media screen and (max-width: 600px) {
          position: fixed;
          bottom: 50px;
        }
        @media screen and (max-width: 600px) and (max-height: 600px) {
          position: fixed;
          bottom: 0;
        }
        @media screen and (max-height: 600px) {
          height: 56px;
        }
        @media screen and (min-width: 600px) {
          height: 46px;
          align-items: center;
          padding: 0;
        }
        .ant-form-item-control-input-content {
          display: flex;
          justify-content: center;
          @media screen and (min-width: 600px) {
            padding: 0 70px;
          }
          .ant-btn-primary {
            width: 380px;
            max-width: 100%;
            font-size: 17px;
            font-weight: bold;
            @media screen and (max-width: 600px) {
              width: 94%;
            }
          }
        }
      }
    }
  }
}
