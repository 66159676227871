.no-record-container {
  padding-top: 6vh;
  text-align: center;
  @media screen and (max-height: 800px) {
    padding: 2vh;
  }
  .empty-title {
    font-size: 18px;
    color: var(--text-color-1);
  }
  .empty-sub-title {
    font-size: 12px;
    color: var(--text-color-2);
    width: 300px;
    margin: auto;
    max-width: 100%;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 8px;
    font-size: 14px;
    @media screen and (min-width: 600px) {
      width: 500px;
    }
  }
  .ant-empty-image {
    display: flex;
    justify-content: center;
    position: relative;
    img {
      width: 120px;
      height: 120px;
    }
    &::after {
      content: '';
      width: 120px;
      height: 120px;
      position: absolute;
      background-image: var(--no-record-image);
      background-repeat: no-repeat;
      background-size: 120px 120px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .ant-empty-img-default {
      display: none;
    }
  }
  .ant-empty-description {
    font-size: 20px;
    font-weight: 600;
    color: #ccd4ec;
    padding-top: 14px;
  }
}
