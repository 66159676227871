.erc4337-transaction-container {
  .ant-drawer-content {
    overflow: hidden;
    background: var(--bg-color-4);
    color: var(--text-color-1);

    .ant-drawer-header {
      border-bottom: none;
      .ant-drawer-close {
        color: var(--text-color-1);
        position: absolute;
        right: 6px;
      }
      .ant-drawer-title {
        color: var(--text-color-1);
        text-align: center;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .ant-drawer-body {
      width: 80%;
      max-width: 1000px;
      margin: auto;
      padding: 0 16px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}

.erc4337-modal-container {
  z-index: 999899 !important;
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-body {
    padding-left: 30px;
    padding-right: 30px;
  }
  .ant-modal-content {
    .ant-modal-body {
      .wallet_footer {
        display: none;
      }
    }
  }
}

.fee-drawer-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  .fee-list {
    max-height: 70vh;
    width: 100%;
    overflow: auto;
    .gas-fee-item {
      display: flex;
      align-items: center;
      padding: 12px 10px;
      background: var(--border-color-1);
      border: 1px solid var(--border-color-1);
      border-radius: 14px;
      cursor: pointer;
      margin-bottom: 10px;
      height: 60px;

      &[data-selected='true'] {
        border-color: var(--accent-color);
        position: relative;
        overflow: hidden;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: var(--accent-color);
          opacity: 0.1;
          pointer-events: none;
          box-sizing: initial;
          animation: initial;
        }
      }
      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      .fee-radio {
        margin-right: 10px;
        color: var(--accent-color);
        &[data-checked='false'] {
          color: var(--text-color-3);
        }
      }
      .fee-token-icon {
        height: 35px;
        width: 35px;
        border-radius: 50%;
        object-fit: contain;
      }
      .fee-token-info {
        margin-left: 12px;
        font-size: 15px;
        .fee-name {
          font-weight: 500;
          color: var(--text-color-1);
        }
        .fee-address {
          font-size: 13px;
          color: var(--text-color-2);
        }
        .universal-tag-wrap {
          width: 130px;
          overflow: hidden;
          @media screen and (max-width: 350px) {
            width: 87px;
          }
        }
        .universal-tag {
          background: #a257fa;
          height: 20px;
          border-radius: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #fff;
          padding-top: 1px;
          transform: scale(0.8);
          position: relative;
          left: -12px;
          min-width: 135px;
          @media screen and (max-width: 350px) {
            transform: scale(0.6);
            left: -26px;
          }
        }
      }
      .fee-token-balance {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        flex-grow: 1;
        flex: 1;
        overflow: hidden;
        color: var(--text-color-1);
        .gas-fee {
          font-size: 15px;
          color: #ea4335;
          font-weight: 500;
          @media screen and (max-width: 350px) {
            font-size: 12px;
          }
        }
        .token-balance {
          font-size: 13px;
          color: var(--text-color-2);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          text-align: right;
          @media screen and (max-width: 350px) {
            font-size: 12px;
          }
        }
      }
    }
  }
  .bootom-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    .btn-gasless {
      width: 48%;
      font-size: 17px;
    }
    .btn-user-paid {
      width: 100%;
      font-size: 17px;
      transition: none;
      height: 47px;
    }
  }
  .wallet_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    .logo_logo {
      width: 171px;
      height: 17px;
    }
  }
  .particle-cloudflare-iframe {
    position: absolute !important;
    left: 50%;
    top: auto !important;
    transform: translateX(-50%) !important;
    bottom: 60px !important;
    @media screen and (max-width: 600px) {
      bottom: 100px !important;
    }
  }
}
