._page_setting {
  .default-layout {
    background-color: var(--content-background-color);
  }
}
.setting-container {
  // 屏幕 最大高 600px
  @media screen and (max-height: 500px) {
    height: 100% !important;
  }
  > .wrapper {
    background: var(--content-background-color) !important;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 600px) {
      min-height: initial;
    }
  }
  .main-content {
    position: relative;
    height: 0;
    flex: 1;
    .setting-list-wrapper {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: calc(100% - 24px);
      overflow-y: auto;
    }
    .list {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      .item {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--border-color-1);

        padding: 0 var(--text-distance-1);
        .mini-link-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
        // &.theme-item {
        //   @media screen and (min-width: 600px) {
        //     display: none;
        //   }
        // }
        .left {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          color: var(--text-color-1);
          position: relative;
          &.show-red-dot .icon {
            &:after {
              content: '';
              position: absolute;
              border-radius: 100%;
              top: 0px;
              right: -2px;
              width: 8px;
              height: 8px;
              position: absolute;
              border-radius: 50%;
              background-color: #ff0201;
              border: 1px solid #ffffff;
            }
          }
          .icon {
            width: 27px;
            height: 27px;
            margin-right: 10px;
          }
        }
        .right {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: var(--text-color-1);
          span {
            margin-right: 0px;
          }
          .micon {
            margin-left: 8px;
          }
        }
        &.language-item {
          .right {
            span {
              margin-right: 0;
            }
          }
        }
      }
    }
    .footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 12px;
      z-index: 1;
      margin-bottom: 0;
      padding-bottom: 0px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: var(--text-color-2);
      height: 20px;

      .ant-btn-primary {
        width: 100%;
        height: 47px;
        border-radius: 10px;
        font-size: 17px;
        font-weight: bold;
      }
    }
  }
}
