.deploy-wallet-contract-modal {
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-content {
    border-radius: 13px;
    .ant-modal-close {
      color: var(--text-color-1);
    }
    .ant-modal-header {
      background: transparent;
      border-bottom: none;
      .ant-modal-title {
        text-align: center;
      }
    }

    .ant-modal-body {
      .wallet_footer {
        display: none;
      }
    }
  }
}

.deploy-wallet-contract-drawer {
  .ant-drawer-content {
    overflow: hidden;
    background: var(--bg-color-4);
    color: var(--text-color-1);

    .ant-drawer-body {
      width: 80%;
      max-width: 968px;
      margin: auto;
      padding: 0 16px;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}

.deploy-wallet-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  .deploy-tip {
    font-size: 20px;
    font-weight: 500;
    color: var(--text-color-1);
    margin: 40px 0 8px 0;
    text-align: center;
    @media screen and (max-width: 600px) {
      margin-top: 0;
    }
  }
  .bootom-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 0 0;
    width: 100%;
    .btn-modal {
      width: 48%;
      font-size: 17px;
    }
  }
  .wallet_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    .logo_logo {
      width: 171px;
      height: 17px;
    }
  }
}
