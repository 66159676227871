._page_index {
  .layout-main {
    background-color: var(--content-background-color);
  }
}
.index-container {
  height: calc(100% - var(--footer-height) - constant(safe-area-inset-bottom) / 3);
  height: calc(100% - var(--footer-height) - env(safe-area-inset-bottom) / 3);
  overflow: auto;
  .wrapper {
    height: 100%;
  }
  .pc-logo {
    display: none;
  }
  .index-header {
    display: flex;
    justify-content: space-between;
    padding: 0 18px;
    height: var(--header-height);
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    background: var(--content-background-color);
    z-index: 1;
    @media screen and (min-width: 600px) {
      padding: 0 var(--text-distance-1);
    }
    .left {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .middle {
      text-align: center;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .right {
      width: 20%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .setting-icon {
        width: 30px;
        height: 30px;
        &.show-red-dot {
          &:after {
            content: '';
            position: absolute;
            border-radius: 100%;
            top: 0px;
            right: 0px;
            width: 8px;
            height: 8px;
            position: absolute;
            border-radius: 50%;
            background-color: #ff0201;
            border: 1px solid #ffffff;
          }
        }
      }
      .pn-wc-scan-btn {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        cursor: pointer;

        &.hide {
          display: none;
        }
      }
    }
  }
  .import-token-btn {
    display: none;
  }
  .main-content {
    padding-top: var(--header-height);

    height: 100%;
    background: var(--content-background-color);
  }
  .wallet-info-content {
    text-align: center;
    padding-bottom: 20px;
    // background: var(--content-background-color);
    background-color: var(--content-background-color);
    .product-name {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      span {
        font-size: 18px;
        font-weight: bold;
        color: var(--text-color-1);
      }
      .aa-tag {
        display: flex;
        align-items: center;
      }
    }
    .address-copy {
      height: 24px;
      margin-top: 8px;
      margin-bottom: 28px;
      display: flex;
      justify-content: center;
      position: relative;
      gap: 8px;

      .wrap {
        padding-top: 1px;
        height: 24px;
        border-radius: 24px;
        padding: 0 10px;
        overflow: hidden;
        line-height: 1;
        opacity: 1;
        border-radius: 12px;
        font-size: 12px;
        font-weight: 400;
        color: var(--text-color-2);
        display: flex;
        align-items: center;
        justify-content: center;
        background: none;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: var(--address-icon-color);
          opacity: 0.15;
          pointer-events: none;
          box-sizing: initial;
          animation: initial;
        }
        .copy-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          .copy-icon {
            margin-left: 6px;
            cursor: pointer;
            font-size: 14px;
            color: var(--text-color-2);
          }
          .address-arrow-wrap {
            height: 24px;
            width: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            left: 4px;
            cursor: pointer;
            .address-arrow {
              color: var(--text-color-2);
              font-size: 10px;
              transform: rotate(0deg);
              // transition: transform 0.3s;
              position: relative;
              top: 0px;

              &.open {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }
    .account-info-Nx9 {
      padding-bottom: 30px;
      height: 92px;
      .row1 {
        font-size: 40px;
        color: var(--text-color-1);
        font-weight: 900;
        line-height: 1;
      }
      .row2 {
        font-size: 14px;
        color: var(--text-color-2);
      }
    }
    .operate-content {
      display: flex;
      width: 90%;
      margin: auto;
      justify-content: space-between;
      max-width: 360px;
      align-items: flex-start;
      @media screen and (max-width: 360px) {
        max-width: 90%;
        width: 90%;
      }
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        width: 62px;
        .wrap {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        &.disabled {
          opacity: 0.5;
        }
        .icon-button-default {
          margin: auto;
        }
        // .icon {
        //   width: 40px;
        //   height: 40px;

        //   background-size: 100% 100%;
        //   &.icon1 {
        //     background-image: var(--icon-src-1);
        //   }
        //   &.icon2 {
        //     background-image: var(--icon-src-2);
        //   }
        //   &.icon3 {
        //     background-image: var(--icon-src-3);
        //   }
        //   &.icon4 {
        //     background-image: var(--icon-src-15);
        //   }
        // }
        .micon {
          font-size: 40px;
        }
        .name {
          font-size: 15px;
          font-weight: 600;
          color: var(--text-color-1);
          line-height: 1.3;
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }

  .list-content {
    .ant-tabs-nav {
      padding: 0 18px;
      position: sticky;
      top: var(--header-height);
      left: 0;
      background: var(--content-background-color);
      z-index: 2;
      padding-bottom: 4px;
      margin: 0;
      margin-bottom: 10px;

      &:before {
        content: '';
        height: 14px;
        bottom: -14px;
        position: absolute;
        width: 100%;
        left: 0;
        background: var(--list-transition-color-1);
        border: none;
      }
      .ant-tabs-nav-wrap {
        .ant-tabs-ink-bar {
          background: none;
          &:after {
            content: '';
            position: absolute;
            width: 22px;
            height: 3px;
            bottom: 0;
            left: 0;
            // left: 50%;
            // transform: translateX(-50%);
            width: 100%;
            display: none;
          }
        }
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            font-size: 18px;
            line-height: 1;
            font-weight: 400;
            color: var(--text-color-2);

            .ant-tabs-tab-btn:focus,
            .ant-tabs-tab-remove:focus,
            .ant-tabs-tab-btn:active,
            .ant-tabs-tab-remove:active {
              background-color: none;
              color: var(--text-color-2);
            }
            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: var(--text-color-1);
              }
            }
          }
          .ant-tabs-tab + .ant-tabs-tab {
            @media screen and (max-width: 500px) {
              margin: 0 0 0 14px;
            }
          }
        }
      }
      .ant-tabs-extra-content {
        .token-add-btn {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          right: -3px;
          .icon {
            width: 20px;
            height: 20px;
            // background-image: var(--icon-src-6);
            // background-size: 100% 100%;
          }
        }
      }
    }

    .skeleton-token-list {
      padding: 0 28px;
      > div {
        margin-bottom: 4px;
      }
      .ant-skeleton {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 60px;
        margin: 0;
        // background: #fff;
        // border-bottom: 1px solid red;
      }
      .ant-skeleton-title {
        margin-top: 2px;
        display: none;
      }
      .ant-skeleton-avatar-circle {
        width: 30px;
        height: 30px;
        border-radius: 30px;
      }
      .ant-skeleton-paragraph {
        margin: 0;
        height: 60px;
        position: relative;
        li {
          position: absolute;
          margin: 0;
        }

        li:nth-child(1) {
          width: 54px;
          height: 16px;
          border-radius: 4px;
          top: 12px;
          @media screen and (min-width: 600px) {
            width: 160px;
          }
        }
        li:nth-child(2) {
          width: 100px;
          height: 16px;
          border-radius: 4px;
          top: 32px;
          @media screen and (min-width: 600px) {
            width: 100%;
          }
        }
        li:nth-child(3) {
          width: 70px;
          height: 16px;
          border-radius: 4px;
          right: 0;
          top: 12px;
          @media screen and (min-width: 600px) {
            display: none;
          }
        }
        li:nth-child(4) {
          width: 64px;
          height: 12px;
          border-radius: 4px;
          right: 0;
          top: 33px;
          @media screen and (min-width: 600px) {
            display: none;
          }
        }
      }
    }
    .ant-tabs-content.ant-tabs-content-top {
      // 解决 ios12 mini 点击出现蓝色边框的问题
      outline: 3px solid var(--content-background-color);
    }
    .token-list {
      // padding-bottom: 10px;

      min-height: 1px;
      .wrap {
        height: auto !important;
      }
      .token-item {
        height: 62px;
        line-height: 62px;
        margin: 0 var(--text-distance-1);
        margin-bottom: 10px;
        padding: 0 var(--text-distance-2);
        display: flex;
        justify-content: space-between;
        position: relative;
        border-radius: 18px;
        cursor: pointer;
        background-color: var(--background-color-1);

        &:after {
          content: '';
          width: 100%;
          border-bottom: 1px solid var(--border-color-1);
          bottom: 0;
          right: 0;
          position: absolute;
          width: calc(100% - 18px - 30px - 8px);
          display: none;
        }
        .left {
          display: flex;
          align-items: center;
          .token-icon {
            width: 30px;
            height: 30px;
            margin-right: 8px;
            border-radius: 30px;
            overflow: hidden;
            object-fit: cover;
          }
          .wrap-name {
            .name {
              font-size: 14px;
              line-height: 1.2;
              font-weight: 600;
              line-height: 1;
              color: var(--text-color-1);
              margin-top: 4px;
            }
            .item-left-row2,
            .item-left-row3 {
              display: flex;
              align-items: center;
              line-height: 1;
              margin-top: 4px;
              height: 20px;
              .unit-price {
                color: var(--text-color-2);
                font-size: 12px;
                margin-right: 10px;
              }
              .riseee {
                line-height: 20px;
                font-size: 12px;
                height: 20px;
                border-radius: 16px;
                color: var(--message-success-color);
                padding: 0 8px;
                transform: scale(0.84);
                position: relative;
                left: -4px;
                position: relative;
                overflow: hidden;
                &::after {
                  content: '';
                  position: absolute;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  background: var(--message-success-color);
                  opacity: 0.1;
                }
                &.negative {
                  color: var(--message-error-color);
                  background: rgba(234, 67, 53, 0.1);
                  &::after {
                    content: '';

                    background: var(--message-error-color);
                  }
                }
              }
            }
            .item-left-row3 {
              height: 18px;
            }
            .item-left-row4 {
              height: 18px;
              display: flex;
              margin-top: 4px;
              .icon {
                height: 18px;
                width: 11px;
                line-height: 1;
                .ant-image {
                  width: 18px;
                  height: 18px;
                  border: 2px solid var(--content-background-color);
                  border-radius: 100%;
                  .token-icon {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                  }
                }
              }
            }
          }
        }
        .right {
          line-height: 1.2;
          text-align: right;
          display: flex;
          align-items: center;
          .wrap {
            .p1 {
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              color: var(--text-color-1);
            }
            .p2,
            .p3 {
              font-size: 12px;
              font-weight: 400;
              line-height: 1;
              height: 12px;
              color: var(--text-color-2);
              margin-top: 4px;
            }
            .right-arrow {
              @media screen and (max-width: 600px) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) {
  .index-container {
    background: #f2f4f6;
    width: 100vw;
    height: 100vh;
    position: relative;

    .wrapper {
      .pc-logo {
        position: absolute;
        width: 143px;
        height: 31px;
        display: block;
        left: 0%;
        top: calc(-1vh - 31px);
      }
      .index-header {
        position: sticky;
        height: 66px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        &[data-theme='dark'] {
          border-bottom-color: rgba(0, 0, 0, 0);
        }

        .right {
          padding-right: 0px;
        }
      }
      .main-content {
        padding-top: 0px;
        display: flex;
        flex-direction: column;
        height: calc(100% - 70px);

        .list-content {
          flex: 1;
          overflow: auto;
          height: 0;
        }
      }

      .operate-content {
        max-width: 400px;
      }
      .wallet-info-content {
        padding-top: 20px;
      }

      .import-token-btn {
        width: 100%;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--content-background-color);
        display: none;
        button {
          display: flex;
          align-items: center;
          font-size: 18px;
          font-weight: 500;
          color: var(--accent-color);
          .icon {
            width: 20px;
            height: 20px;
            margin-right: 4px;
            position: relative;
            .micon {
              position: absolute;
              top: 0;
              left: 0;
            }
            // background-image: var(--icon-src-6);
            // background-size: 100% 100%;
          }
        }
      }
      .list-content {
        padding: 0;
        border-top: none;
        overflow: hidden;
        .ant-tabs {
          height: 100%;
          display: flex;
          flex-direction: column;
          &[data-theme='dark'] {
            .ant-tabs-nav {
              &:after {
                border-bottom: none;
              }
            }
          }
        }
        .ant-tabs-content-holder {
          height: 0;
          flex: 1;
          overflow: auto;
          padding-top: 14px;
        }
        .ant-tabs-nav {
          padding: 0 var(--text-distance-1);
          top: 0px;
          margin: 0;
          position: relative;
          &:after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            border-bottom: 2px solid var(--border-color-0);
            left: 0;
            top: 0;
            z-index: -1;
            display: none;
          }
          .ant-tabs-ink-bar {
            top: 0;
            display: none;
          }
          .ant-tabs-tab-active {
            .ant-tabs-tab-btn {
              color: var(--text-color-1) !important;
            }
          }

          .ant-tabs-ink-bar {
            &:after {
              display: none;
            }
          }
          .ant-tabs-nav-wrap {
            width: 100%;
            .ant-tabs-nav-list {
              width: 100%;
              .ant-tabs-tab {
                // width: 50%;

                .ant-tabs-tab-btn {
                  width: 100%;
                  text-align: center;
                  line-height: 1.6;
                }
              }
            }
          }
        }
        .right {
          position: relative;
          .wrap {
            padding-right: 0px;
            .right-arrow {
              position: absolute;
              right: -8px;
              top: 19px;
            }
          }
        }
      }
    }
  }
}

// .import-token-btn[data-type='nft'],
// .token-add-btn[data-type='nft'] {
//   display: none !important;
// }

.address-list-popover {
  width: 240px;
  left: 50% !important;
  transform: translate(-50%, -0px) !important;

  .ant-popover-content {
    opacity: 0 !important;
    &.show1 {
      opacity: 1 !important;
    }
  }

  .ant-popover-inner {
    background-color: var(--bg-color-4);
    border-radius: var(--border-radius-1);
  }
  .ant-popover-arrow {
    left: 177px !important;
    .ant-popover-arrow-content {
      &::before {
        background: var(--bg-color-4);
      }
    }
  }
  .address-list {
    .item {
      color: var(--text-color-1);
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      line-height: 30px;
      .right {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }
}
