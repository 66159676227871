.default-layout-footer.page-nftSend {
  display: none;
}
._page_nftSend .layout-main {
  overflow: hidden;
}

.nft-send-container {
  position: relative;
  background-color: var(--content-background-color);
  height: 100% !important;
  overflow: auto !important;
  &[data-device='firefox'] {
    overflow: initial !important;
  }
  input[type='number']:focus {
    outline: none;
    border: none;
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;

    &:focus {
      display: none;
      opacity: 0;
      font-size: 0;

      padding: 0;
      width: 0;
      height: 0;
    }
  }

  .foot-logo {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 4;
    background-image: var(--footer-icon-src);
    background-size: 171px 17px;
    background-repeat: no-repeat;
    background-position: 50% 0;
    width: 100%;
    height: 40px;
    margin-top: 4px;
    box-sizing: content-box;
    padding-bottom: 16px;
    padding: 0;
    margin: 0;
    background-position: center center;

    @media screen and (max-width: 600px) {
      background-color: var(--content-background-color);
      position: fixed;
    }
  }

  .wrapper-bg {
    filter: blur(150px);
    // position: absolute;
    // left: 0;
    // top: 0;
    width: 100%;
    height: 100%;
    background-size: 100%;
    background-repeat: repeat-y;
    display: none;

    @media screen and (min-width: 600px) {
      display: none;
    }
  }

  > .wrapper {
    background-color: var(--content-background-color) !important;
    .header-container {
      @media screen and (max-width: 600px) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .input-val {
    font-size: 14px !important;
    color: var(--text-color-1);
    padding: 0;
    height: 30px !important;
  }

  .info-content {
    padding: 0px 24px;
    .info1 {
      padding-bottom: 20px;
      .p1 {
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2;
        color: var(--text-color-1);
        margin-top: 10px;
        word-break: break-all;
        position: relative;
      }
      .p2 {
        font-size: 14px;
        font-weight: 600;
        color: #2d6af6;
        line-height: 14px;
      }
    }
  }

  .main-content {
    padding-bottom: 130px;
    @media screen and (min-width: 600px) {
      overflow: auto;
      height: calc(100% - var(--header-height));
    }

    .nft-send-form {
      padding: 0 var(--text-distance-1);

      @media screen and (min-width: 600px) {
        margin-bottom: 30px;
      }

      padding-top: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      // min-height: 360px;
      .ant-form {
        // width: 320px;
        width: 100%;
        .input-content {
          border: 1px solid var(--background-color-1);
          border-radius: 18px;
          overflow: hidden;
          color: var(--text-color-1);
          background-color: var(--background-color-1);

          input {
            border: none;
            outline: none;
          }

          .ant-input-number {
            border: none;
            outline: none;
          }

          .input-number {
            background-color: rgba(0, 0, 0, 0);
            border: none;
            outline: none;
            width: 100%;
            height: 100%;
          }
          &:focus,
          &[data-focus='true'] {
            border-color: var(--accent-color);
          }
          &.to-input-content,
          &.amount-input-content {
            .wrap {
              width: 100%;
              display: flex;
              height: 60px;
              align-items: center;

              .left {
                flex: 1;
                padding-right: 10px;
                overflow: hidden;
                position: relative;
                .masker {
                  display: none;
                  width: 20px;
                  height: 100%;
                  position: absolute;
                  z-index: 3;
                  background-color: var(--content-background-color);
                  right: 0;
                  bottom: 0;
                }
              }
            }
          }
          .ant-input {
            height: 44px;
            font-size: 18px;
          }
          .suffix-content {
            display: flex;
            align-items: center;
            .icon1 {
              width: 22px;
              height: 22px;
              cursor: pointer;
              // background-image: var(--icon-src-13);
              // background-size: 100% auto;
            }

            .icon2 {
              width: 22px;
              height: 22px;
              cursor: pointer;
              background-image: var(--icon-src-23);
              background-size: 100% auto;
            }
          }
          &.fee-input-content {
            height: 60px;
            display: flex;
            align-items: center;
            // padding: 0 14px;

            font-size: 12px;
            font-weight: normal;
            color: var(--text-color-1);
            display: flex;
            align-items: center;
            .wrap {
              width: 100%;
              display: flex;
              justify-content: space-between;
              .gray-color {
                color: var(--text-color-2);
                margin-left: 4px;
              }
              .right {
                .sub-wrap {
                  display: flex;
                  .sub-left {
                    margin-right: 4px;
                    .row2 {
                      color: var(--message-success-color);
                      font-weight: bold;
                    }
                  }
                  .sub-right {
                    display: flex;
                    align-items: center;
                    .icon {
                      width: 17px;
                      height: 34px;
                    }
                  }
                }
              }
            }
          }
        }
        .ant-form-item {
          margin-bottom: 14px;
        }
        .ant-form-item-label {
          padding-bottom: 0;
          width: 100%;
          & > label {
            width: 100%;
          }
          label {
            font-size: 16px;
            color: var(--text-color-1);
          }
          .amount-label-content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .tips-Nx9 {
              font-size: 12px;
              font-weight: normal;
              color: #44169f;
              line-height: 1;
            }
          }
          .fee-label-content {
            display: flex;
            align-items: center;
            .refresh-content {
              font-size: 14px;
              font-weight: 500;
              color: var(--text-color-2);
            }
          }
        }
        .ant-form-item-extra {
          font-size: 12px;
          font-weight: 400;
          color: var(--accent-color);
          padding-top: 6px;
          padding-left: 0px;
          cursor: pointer;
        }
      }
    }
    .form-footer {
      position: relative;
      z-index: 2;
      width: 100%;
      padding: 0 12px;
      padding-top: 10px;
      padding: 0;
      display: flex;
      justify-content: center;
      height: 86px;
      position: absolute;
      bottom: 0;
      background: var(--content-background-color);
      @media screen and (max-width: 600px) {
        position: fixed;
        bottom: 0;
        z-index: 4;
      }
      @media screen and (min-width: 600px) {
        padding: 0;
        gap: 40px;
        align-items: self-start;
      }
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
      }
      .cancel-btn {
        width: 45%;

        font-weight: 400;

        @media screen and (min-width: 600px) {
        }
      }
      .send-btn {
        width: 45%;
        margin-left: 10px;
        max-width: 400px;
      }
    }
  }
}
.tip-container {
  width: 100%;
  .warning-tip {
    width: 100%;
    font-size: 12px;
    line-height: 1.2;
    font-weight: 600;
    color: #ff7676;
  }
}
.network-fee-sol {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 14px;
  color: var(--text-color-1);
  font-size: 16px;
  span {
    color: var(--text-color-2);
  }
}

.nft-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  padding: 0 var(--text-distance-1);
  box-sizing: border-box;
  // position: sticky;
  // top: 0;
  // left: 0;

  z-index: 99;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: var(--text-color-1);

  .nft-back-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-image: var(--back-icon-src);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .nft-right-icon {
    width: 15px;
    height: 15px;
  }

  .nft-right-box {
    width: 15px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
  }

  .nft-left-box {
    width: 15px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 20px;
  }
}

.amount-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .max-amount {
    font-size: 14px;
    color: var(--pending-color);
    cursor: pointer;
  }
}

.address-icons {
  display: flex;
  align-content: center;
}

.recend-tips {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  color: #2d6af6;
  margin-bottom: 18px;
}

.loading-box.network-fee-loading {
  position: absolute;
  width: 100%;
  height: 62px;
  background-color: rgba(0, 0, 0, 0.01);
  border-radius: 8px;
  z-index: 3;
  .lds-default {
    margin: 0;
    padding: 0;
    transform: scale(0.5);
    position: relative;
    top: -3px;
  }
}
