.no-style-btn {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  *[ant-click-animating-without-extra-node='true']::after {
    display: none;
  }
  &:hover,
  &:focus {
    background: transparent;
    color: #fff;
    border-bottom: transparent;
  }
}
