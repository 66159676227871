:root {
  .switch-chain-container {
    > .name {
      font-size: 16px;
      line-height: 1;
      font-weight: 600;
      color: var(--text-color-1);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .aa-tag {
        height: 17px;
        border-radius: 9px;
        color: var(--text-color-1);
        font-size: 11px;
        font-weight: bold;
        line-height: 19px;
        margin-right: 4px;
        padding: 0 4px;
      }
    }
    &[data-source='swap'] {
      > .name {
        top: 6px;
        display: flex;
        align-items: center;
        .aa-tag {
          background: none;
          margin: 0;
          position: relative;
          top: -2px;
        }
      }
      .pa-pc-network,
      .m-network {
        position: relative;
        top: 4px;
      }
      .ant-btn {
        background: none;
        border: none;
        box-shadow: none;
        .micon {
          transform: rotate(0deg);
          position: relative;
          top: 2px;
        }
        &:after {
          display: none;
          border: none;
        }
      }
      .m-network {
        &:after {
          display: none;
          border: none;
        }
        .select-arrow {
          transform: rotate(0deg);
          position: relative;
          top: 0px;
        }
      }
    }
    .pa-pc-network {
      display: none;
    }
    .m-network {
      font-size: 12px;
      font-weight: 500;
      height: 30px;
      border-radius: 30px;
      padding: 0 10px;
      color: var(--text-color-1);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: var(--address-icon-color);
        opacity: 0.15;
        border-radius: 30px;
      }
      span {
        position: relative;
        top: 0px;
      }
      .middle-type-icon {
        width: 15px;
        height: 15px;
        margin-right: 4px;
      }
      .select-arrow {
        width: 15px;
        height: 15px;
        background-image: var(--icon-src-7);
        background-size: 100% 100%;
        transform: rotate(-90deg);
        position: relative;
        right: -3px;
      }
    }
    .skeleton-wrap {
      .ant-skeleton-input {
        min-width: 20px;
        width: 60px;
        height: 14px;
        position: relative;
        top: 2px;
      }
    }
    @media screen and (min-width: 600px) {
      .type {
        display: none;
      }
      .pa-pc-network {
        line-height: 1;
        display: block;
        .skeleton-wrap {
          .ant-skeleton-input {
            min-width: 20px;
            width: 60px;
            height: 14px;
            top: 0px;
          }
        }
        .icon1 {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          opacity: 1;
          margin-right: 6px;
          overflow: hidden;
          display: flex;
          align-items: center;
          .chain-icon {
            width: 100%;
            height: 100%;
          }
        }
        .icon1 + span {
          line-height: 1;
        }
        .icon3 {
          width: 5px;
          height: 100%;
        }
        button {
          height: 30px;
          border-radius: 30px;
          display: flex;
          align-items: center;
          padding: 0 12px;
          padding-right: 6px;
          font-size: 12px;
          font-weight: 500;
          color: var(--text-color-1);
          overflow: hidden;
          position: relative;
          background: none !important;
          left: 0px;
          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--address-icon-color);
            opacity: 0.15;
            pointer-events: none;
            box-sizing: initial;
            animation: initial;
          }
        }
        .icon2 {
          margin-left: 2px;
          position: relative;
          top: 0px;
          transform: rotate(-90deg);
        }
      }
    }
  }

  .swaitch-chain-modal {
    .ant-modal-body {
      padding: 0;
      z-index: 4;
      width: 100%;
      padding-top: 0;
    }
    .list {
      padding: 14px 0px;
      max-height: 400px;
      min-height: 100px;
      overflow: auto;
      .item {
        padding: 0 30px;
        line-height: 50px;
        font-size: 15px;
        font-weight: 400;
        color: var(--text-color-1);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin-bottom: 8px;

        &.no-data {
          cursor: default !important;
          justify-content: center !important;
          border-bottom: none !important;
          background: none;
          .name {
            position: relative;
            color: var(--text-color-2) !important;
            top: 6px;
          }
        }

        .left {
          display: flex;
          align-items: center;
          .left-img {
            width: 36px;
            height: 36px;
            margin-right: 10px;
          }
        }
        .right {
          .right-icon {
            width: 18px;
            height: 18px;
            position: relative;
            > span {
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
        .icon {
          width: 11px;
          height: 11px;
          background: #59b3ae;
          border-radius: 50%;
          opacity: 1;
          margin-right: 13px;
        }
      }
    }
    .footer {
      padding: 17px 30px;
      line-height: 30px;
      font-size: 15px;
      font-weight: 400;
      color: var(--text-color-1);
      .switch-content {
        display: flex;
        justify-content: space-between;
      }
      span {
        margin-right: 10px;
      }
    }
  }
  .search-input-content {
    padding: 0 30px !important;
    padding-bottom: 4px !important;
    color: var(--text-color-1);
    background: none;
    margin-top: 20px;
    @media screen and (max-width: 600px) {
      display: none;
    }
    .ant-input-affix-wrapper {
      background: var(--input-bg-color);
      border: 1px solid var(--input-bg-color);
      box-shadow: none;
      position: relative;
      top: -4px;
      border-radius: 38px;
      box-shadow: none !important;
      // &[data-focus='true'] {
      //   border-color: var(--accent-color);
      // }
    }
    input {
      height: 32px;
      &:focus,
      &:hover {
        box-shadow: none !important;
        border: none !important;
      }
    }
  }
  .ant-drawer-content-wrapper {
    height: auto !important;
    .content-wallet {
      min-height: 100px;
      max-height: 60vh;
      .list {
        padding-bottom: 94px;
        border-top: 2px solid var(--border-color-1);
        background-color: var(--content-background-color);
      }
    }
  }
}
