.address-book-container {
  // padding-bottom: calc(var(--footer-height) + 55px);
  > .wrapper {
    display: flex;
    flex-direction: column;
    background: var(--content-background-color) !important;
    min-height: 100%;
    @media screen and (min-width: 600px) {
      min-height: initial;
    }
  }
  .no-record-container {
    padding-top: 0;
  }
  .main-content {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    color: var(--text-color-1);
    .no-record-container {
      height: 0;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .list {
      height: 0;
      flex: 1;
      overflow: auto;
      .item {
        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        line-height: 60px;
        margin: 0 var(--text-distance-1);
        padding: 0 var(--text-distance-2);
        height: 60px;
        color: var(--text-color-1);
        cursor: pointer;
        background: var(--background-color-1);
        border-radius: 18px;
        margin-bottom: 10px;
        .left {
          display: flex;
          align-items: center;
          flex: 1;
          overflow: hidden;
          max-width: 500px;
          .icon {
            width: 40px;
            height: 40px;
            border-radius: 40px;
            overflow: hidden;
            margin-right: 10px;
          }
          .name {
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding-right: 15px;
          }
        }
        .right {
          width: 190px;
          text-align: right;
          .edit-btn {
            cursor: pointer;
            margin-left: 16px;
          }
          .delete-icon-btn {
            cursor: pointer;
            margin-left: 16px;
          }
        }
      }
    }
    .footer {
      position: fixed;
      bottom: calc(var(--footer-height));
      height: 65px;
      background: var(--content-background-color);
      display: flex;
      align-items: flex-end;
      justify-content: center;
      left: 0;
      padding: 0 var(--text-distance-1);
      padding-bottom: 0px;
      padding-top: 10px;
      width: 100%;
      @media screen and (min-width: 600px) {
        position: initial;
        padding: 0 70px;
        height: 70px;
        align-items: center;
        align-items: flex-end;
      }
      button {
        height: 46px;
        width: 440px;
        max-width: 100%;
        font-size: 17px;
        @media screen and (min-width: 600px) {
          width: 380px;
        }
      }
    }
  }
}
