@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
.ant-message {
  z-index: 9999999;
}
.ant-switch {
  background-image: none;
  &:focus {
    box-shadow: none;
  }
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: none;
}
.ant-modal-mask {
  background-color: var(--modal-mask-background-color, rgba(0, 0, 0, 0.45));
}

.ant-btn {
  height: 46px;
  border-radius: var(--border-radius-1);
  font-size: 17px;
  font-weight: bold;
  box-shadow: none;
}
.ant-btn-default {
  background: var(--cancelButtonBackgroundColor);
  border: none;
  color: var(--text-color-1);
  &:hover,
  &:focus,
  &:active {
    background: var(--cancelButtonBackgroundColor);
    border: none;
    color: var(--text-color-1);
  }
}
.ant-btn-primary {
  border: none;
  background: var(--primaryButtonBackgroundColor);
  color: var(--primaryButtonTextColor, #ffffff);
  overflow: hidden;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  border-color: #000;
  color: var(--primaryButtonTextColor, #ffffff);
  background: var(--primaryButtonBackgroundColor);
}
.ant-input {
  color: var(--text-color-1);
  border-color: var(--input-border-color);
  padding-left: 14px;
  padding-right: 14px;
}
.ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: none;
}
.anticon.ant-input-clear-icon,
.ant-input-clear-icon {
  color: var(--border-color-1);
}

.ant-input[disabled] {
  background-color: var(--input-disabled-bg-color);
  color: var(--border-color-1);
}
.ant-form-item-control-input-content {
  input {
    color: var(--text-color-1);
  }
}
.ant-input-affix-wrapper-lg {
  padding: 3.5px 11px;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  border: none !important;
  background: var(--primaryButtonBackgroundColor) !important;
  color: var(--content-background-color, #ffffff) !important;
  opacity: var(--disbled-button-opacity) !important;
  span {
    opacity: var(--disbled-button-opacity) !important;
  }
}
.ant-tabs-nav-operations {
  display: none !important;
}
.ant-tabs-tab-btn {
  color: var(--text-color-2);
}

.ant-notification {
  .ant-notification-notice-with-icon {
    position: relative;
  }
  .ant-notification-notice {
    border-radius: 10px;
    padding: 14px 0 0 0;
    display: flex;
    box-sizing: border-box;
    align-items: center;

    .ant-notification-notice-message {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 0;
      color: #ffffff;
    }
    .ant-notification-notice-description {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;

      color: #ffffff;
    }

    &.ant-notification-notice-success {
      background-color: #52c176;
      color: #fff;

      .anticon-check-circle {
        background: url('/images/img_4_9.svg') no-repeat;
        width: 16px;
        height: 16px;
        background-size: cover;
        position: absolute;
        top: calc(50% - 8px);
        margin-left: 16px;
        svg {
          display: none;
        }
      }
    }

    &.ant-notification-notice-error {
      background-color: #ea4335;
      color: #fff;

      .ant-notification-notice-icon {
        background: url('/images/img_4_10.svg') no-repeat;
        width: 16px;
        height: 16px;
        background-size: cover;
        position: absolute;
        top: calc(50% - 8px);
        margin-left: 16px;
        svg {
          display: none;
        }
      }
    }
  }

  .ant-notification-notice-close {
    display: none;
  }
}
.ant-message-notice-content {
  border-radius: 42px;
  overflow: hidden;
  padding: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  box-shadow: none;
  .ant-message-custom-content {
    padding: 10px 16px;
    &.ant-message-success {
      background: var(--message-success-color, #52c176);
      .anticon-check-circle {
        background: url('/images/img_4_9.svg') no-repeat;
        width: 16px;
        height: 16px;
        background-size: cover;
        svg {
          display: none;
        }
      }
    }
    &.ant-message-error {
      background: var(--message-error-color, #ed5d51);
      .anticon-close-circle {
        background: url('/images/img_4_10.svg') no-repeat;
        width: 16px;
        height: 16px;
        background-size: cover;
        svg {
          display: none;
        }
      }
    }
    &.ant-message-info {
      .anticon-info-circle {
        background: url('/images/img_4_9.svg') no-repeat;
        width: 16px;
        height: 16px;
        background-size: cover;
        svg {
          display: none;
        }
      }
    }

    &.ant-message-loading {
      .anticon-spin {
        background-image: var(--icon-src-16);
        width: 16px;
        height: 16px;
        background-size: cover;
        animation: none;
        svg {
          display: none;
        }
      }
    }
  }
}
.ant-spin-nested-loading {
  > div {
    width: 100%;
    height: 100%;
    .ant-spin.ant-spin-spinning {
      width: 100%;
      height: 100%;
      max-height: initial;
      .ant-spin-dot {
        background-image: var(--icon-src-16);
        background-size: cover;
        width: 60px;
        height: 60px;
        top: 40%;
        // transform: translate(-50%, -50%);
        animation: none;
        margin: 0;
        i {
          display: none;
        }
      }
    }
  }
}
.ant-spin-dot {
  background-image: var(--icon-src-16);
  background-size: cover;
  width: 60px;
  height: 60px;
  animation: none;
  i {
    display: none;
  }
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  color: var(--text-color-1);
}

.w-drawer-container {
  .ant-drawer-close {
    display: none;
  }
  .ant-drawer-content {
    background-color: var(--bg-color-4);
  }

  .ant-drawer-title {
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    color: var(--text-color-1);
  }
  .ant-drawer-header {
    border-bottom: none;
    padding-top: 26px;
    position: relative;
    .ant-drawer-extra {
      position: absolute;
      right: var(--text-distance-1);
    }
  }
  .ant-drawer-body {
    padding: 0;
  }
  .anticon-close {
    color: var(--text-color-1);
  }
}
.w-model-container {
  .ant-modal-header {
    padding-top: 26px;
    padding-bottom: 8px;
    border: none;
    .ant-modal-title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
    }
  }
  .ant-modal-close-x {
    position: relative;
    top: 13px;
    right: 8px;
  }
  .ant-modal-content {
    overflow: hidden;
  }
  .ant-modal-footer {
    display: none;
  }
}

.transaction-result-container {
  font-size: 16px;
  text-align: center;
  color: var(--text-color-2);
  padding: 0 24px;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: var(--text-color-1);
    position: relative;
    top: -6px;
    margin-bottom: 10px;
  }
  .content {
    padding: 0 var(--text-distance-1);
    margin-bottom: 24px;
    line-height: 1.2;
  }
  .btns {
    width: 100%;
    button {
      width: 100%;
      font-size: 17px;
    }
  }
  .footer {
    display: none;
    @media screen and (max-width: 600px) {
      display: block;
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo {
        width: 171px;
        height: 17px;
        background-image: var(--footer-icon-src);
        background-size: 100% 100%;
      }
    }
  }
}

.m-spin {
  .ant-spin-dot {
    width: 1em !important;
    height: 1em !important;
    position: relative !important;
    animation: antRotate 1.2s infinite linear !important;
    top: auto !important;
    left: auto !important;
    background: none !important;
    margin: -10px !important;
    i {
      display: block !important;
    }
  }
}

/* 选择特定类型的输入框的占位符文本 */
input[type='text']::placeholder {
  color: var(--text-color-3);
}

.ant-switch-checked {
  background-color: var(--accent-color, #c63ec8);
}

.ant-skeleton-active .ant-skeleton-title::after,
.ant-skeleton-active .ant-skeleton-paragraph > li::after,
.ant-skeleton-active .ant-skeleton-avatar::after,
.ant-skeleton-active .ant-skeleton-button::after,
.ant-skeleton-active .ant-skeleton-input::after,
.ant-skeleton-active .ant-skeleton-image::after,
.ant-skeleton-content .ant-skeleton-paragraph > li,
.ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-header .ant-skeleton-avatar {
  background: var(--skeleton-color-1);
}

.ant-form-item-explain-error {
  color: var(--message-error-color);
}
.ant-input {
  &:hover {
    box-shadow: none;
    border-color: var(--input-border-color);
  }
  &:focus,
  &.ant-input-focused {
    box-shadow: none;
    border-color: var(--accent-color) !important;
  }
}

.ant-modal {
  @media screen and (min-height: 1200px) {
    top: -60px !important;
  }

  .ant-modal-body {
    padding-left: var(--text-distance-1);
    padding-right: var(--text-distance-1);
  }
}

.ant-modal-top {
  .ant-modal {
    top: 100px !important;
  }
  @media screen and (min-height: 1200px) {
    .ant-modal {
      top: 100px !important;
    }
  }
}

.ant-modal-confirm-confirm {
  .ant-btn {
    height: 32px;
    line-height: 1;
  }
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within,
.ant-radio-button-wrapper:focus-within {
  box-shadow: none;
}

.w-input {
  border: 1px solid var(--background-color-1);
  border-radius: 18px !important;
  overflow: hidden;
  color: var(--text-color-1);
  background-color: var(--background-color-1);
  height: 60px;
  &:hover {
    border: 1px solid var(--background-color-1);
  }
  &:focus {
    border-color: var(--accent-color);
  }
  &[disabled] {
    border: none !important;
    color: var(--text-color-1);
  }
}

.ant-drawer-content {
  border-radius: 18px 18px 0 0;
}

[class*='input-content'] {
  &:focus,
  &[data-focus='true'] {
    border-color: var(--accent-color) !important;
  }
}

.ant-modal-content {
  border-radius: 20px;
}

.ant-message-notice-content {
  border-radius: var(--border-radius-1);
  max-width: 500px;
}

.ant-form {
  .input-content {
    .wrap {
      padding-left: 14px;
      padding-right: 14px;
    }
  }
}

.ant-btn-loading {
  opacity: var(--disbled-button-opacity);
  span {
    opacity: var(--disbled-button-opacity);
  }

  &::before {
    display: none !important;
  }
  .ant-btn-loading-icon {
    opacity: 1;
    position: relative;
    background-image: var(--loading-icon);
    background-size: 19px 19px;
    height: 19px !important;
    width: 19px !important;
    position: relative;
    top: 3px;
    left: -3px;
    transition: initial !important;
    animation: none !important;
    .anticon {
      display: none;
    }
  }
}

.div-input,
input {
  caret-color: var(--accent-color);
}

.ant-click-animating-node {
  display: none;
}
