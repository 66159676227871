.nft-list-container {
  .no-record-container {
    padding-top: 6vh;
    @media screen and (max-height: 800px) {
      padding: 2vh;
    }
  }
  .nft-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 92%;
    margin: auto;
    padding-bottom: 0px;
    @media screen and (min-width: 600px) {
      width: 95%;
      justify-content: flex-start;
      display: grid;
      grid-template-columns: repeat(auto-fill, 165px);
      grid-gap: 22px 20px;
      justify-content: center;
      padding-bottom: 20px;
    }
    .item {
      width: 48%;
      margin-bottom: 16px;
      @media screen and (min-width: 600px) {
        width: 100%;
        margin-bottom: 0;
      }
      .img-content {
        background: var(--background-color-1);
      }
      .info-content {
        .name {
          text-align: center;
          padding-top: 8px;
          padding-bottom: 6px;
          font-size: 14px;
          font-weight: 600;
          color: var(--text-color-1);
          line-height: 1.2;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding: 0;
          margin-top: 8px;
        }
      }
    }
  }
}
