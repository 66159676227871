.default-layout .layout-main > #keep-alive-container > div > div.swap-container {
  @media screen and (max-height: 500px) {
    height: 760px !important;
    padding-bottom: var(--footer-height);
  }
}

.swap-container {
  > .wrapper {
    background-color: var(--content-background-color) !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    &[data-device='firefox'] {
      @media screen and (max-width: 600px) {
        height: calc(100vh - var(--footer-height));
        min-height: calc(100vh - var(--footer-height));
      }
      @media screen and (max-height: 500px) {
        height: 760px;
        min-height: 760px;
      }
    }

    @media screen and (min-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }
  .main-content {
    padding-top: 22px;
    height: 0;
    flex: 1;
    overflow: auto;
    // min-height: 600px;
    .ant-spin-spinning {
      position: fixed;
      .ant-spin-dot {
        top: 50% !important;
      }
    }
    .details {
      padding: 0 var(--text-distance-1) 30px;
      @media screen and (min-width: 600px) {
        padding: 0 22px;
      }
      .card {
        background: var(--bg-color-4);
        border-radius: 18px;
        margin-bottom: 15px;
        padding: 10px 0px;
        box-shadow: 0px 0px 15px 0px var(--shadow-color-2);
        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 10px 16px;
          .mid-img {
            width: 50px;
            height: 50px;
            border-radius: 50px;
            cursor: pointer;
            border: 1px solid var(--border-color-1);
            position: relative;
            min-width: 50px;
            .micon {
              position: absolute;
              top: 4px;
              left: 4px;
            }
          }
          &.swap-mode-title {
            font-size: 14px;
            font-weight: 600;
            padding-bottom: 0 !important;
            color: var(--text-color-1);
          }
          &.swap-mode-row {
            display: flex;
            gap: 14px;
            color: var(--text-color-2);
            .item {
              color: var(--text-color-1);
              flex: 1;
              height: 60px;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 18px;
              background-color: var(--input-bg-color);
              font-weight: bold;
              &[data-active='true'] {
                border: none;
                color: var(--accent-color);
                position: relative;
                overflow: hidden;
                &:after {
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  opacity: 0.15;
                  pointer-events: none;
                  box-sizing: initial;
                  animation: initial;
                  background-color: var(--accent-color);
                }
              }
              &[data-disabled='true'] {
                cursor: not-allowed;
                opacity: 0.8;
              }
            }
          }
          &.row-1 {
            align-items: flex-start;
          }
          &.row-mid {
            padding-left: 0;
            padding-right: 0;
            span {
              width: calc(50% - 20px);
              height: 1px;
              background: var(--border-color-1);
            }
          }
          .ant-form-item {
            margin: 0;
          }
          .ant-form-item-control-input-content {
            height: 30px;
          }
          .left {
            font-size: 14px;
            color: var(--text-color-1);
            font-weight: 500;

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
              -webkit-text-fill-color: var(--text-color-1);
              -webkit-box-shadow: 0 0 0px 1000px var(--content-background-color) inset;
              transition: background-color 5000s ease-in-out 0s;
            }
            .pay-input {
              border: none;
              font-size: 24px;
              padding: 0;
              width: 80%;
              height: 30px;
              @media screen and (min-width: 600px) {
                width: 320px;
              }
            }
            .info-tip {
              background-image: var(--icon-src-17);
              background-size: cover;
              width: 13px;
              height: 13px;
              margin-left: 8px;
              cursor: pointer;
            }
            .left0 {
              color: var(--text-color-2);
            }
            .left1 {
              font-size: 24px;
            }
            &.left0 {
              color: var(--text-color-2);
            }
            &.left1 {
              font-size: 24px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              &.loading {
                opacity: 0.3;
              }
            }
            &.left2 {
              display: flex;
              align-items: center;
            }
          }
          .right {
            font-size: 14px;
            font-weight: 500;
            color: var(--text-color-1);
            &.right-max {
              cursor: pointer;
              color: var(--text-color-1);
              text-align: right;
            }
            .right0 {
              color: var(--text-color-2);
            }
            &.right-arrow {
              display: flex;
              align-items: center;
            }
            .icon2 {
              width: 16px;
              height: 16px;
              //   margin-right: 2px;
              background-image: var(--icon-src-7);
              background-size: cover;
            }
            .chain-box {
              cursor: pointer;
              min-width: fit-content;
              height: 30px;
              background: var(--input-bg-color);
              border-radius: 30px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 4px;
              color: var(--text-color-1);
              .select-token {
                color: var(--text-color-2);
              }
              .default-icon,
              .chain-icon {
                width: 22px;
                height: 22px;
                border-radius: 22px;
                overflow: hidden;
                margin-right: 3px;
                min-width: 22px;
                min-height: 22px;
              }
              .default-icon {
                // background-image: var(--icon-src-20);
                // background-size: 100% 100%;
                // background-repeat: no-repeat;
                .micon {
                  color: var(--accent-color);
                }
              }
              .icon2 {
                margin-left: 3px;
              }
            }
          }
          &.receive-row {
            height: 56px;
            .ant-skeleton-button {
              width: 80px;
              min-width: 80px;
            }
            .right {
              width: 60%;
              text-align: right;
              padding-top: 2px;
              & > div {
                display: inline-block;
              }
              .chain-box {
                width: 100%;
                display: flex;
                align-items: center;
                min-height: 30px;
                height: auto;
                .chain-icon {
                  min-width: 22px;
                  min-height: 22px;
                }
                .label {
                  white-space: pre-wrap;
                  word-break: break-all;
                  word-wrap: break-word;
                  line-height: 1.2;
                  text-align: center;
                }
                .icon2 {
                  min-width: 16px;
                  min-height: 16px;
                }
              }
            }
          }
        }
        &.card1 {
          .row {
            padding: 7px 16px;
            .ant-skeleton-button {
              height: 22px;
              width: 80px;
              min-width: 80px;
            }
          }
        }
        .icon {
          width: 10px;
          height: 10px;
          margin-left: 8px;
          background-image: var(--icon-src-9);
          background-size: 100% 100%;
        }
      }
    }
  }

  .content-footer {
    z-index: 1;
    width: 100%;
    text-align: center;
    position: initial;
    // position: absolute;
    bottom: 50px;
    background-color: var(--content-background-color);
    @media screen and (min-width: 600px) {
      // bottom: 52px;
    }
    .confirm-time {
      font-size: 14px;
      font-weight: 400;
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      width: 100%;
      justify-content: center;
      color: var(--text-color-1);
      .limit-time {
        background-image: var(--icon-src-19);
        background-size: cover;
        width: 15px;
        height: 15px;
        margin-right: 8px;
      }
    }
    .swap-btn {
      width: 340px;
      font-size: 17px;
      font-weight: 600;
      transition: none;
      @media screen and (min-width: 600px) {
        width: 45%;
      }
    }
    .please-add {
      color: rgba(119, 119, 119, 1);
      padding-bottom: 9px;
    }
  }

  .swap-footer {
    z-index: 2;
    width: 100%;
    text-align: center;
    bottom: 50px;
    background-color: var(--content-background-color);
    @media screen and (min-width: 600px) {
      bottom: 0;
    }
  }
}
.swap-tip-container {
  display: flex;
  flex-direction: column;
  color: var(--text-color-2);
  text-align: center;
  .close-btn {
    font-size: 17px;
    font-weight: 600;
    margin-top: 35px;
  }
  .footer {
    padding-bottom: calc(constant(safe-area-inset-bottom) / 3);
    padding-bottom: calc(env(safe-area-inset-bottom) / 3);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 171px;
      height: 17px;
      background-image: var(--footer-icon-src);
      background-size: 100% 100%;
    }
  }
}

.w-model-container.slip-modal {
  .footer {
    display: none;
  }
}
