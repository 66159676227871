.add-address-dialog-container {
  .copy-area {
    padding-top: 10px;
    // text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
    .wrap {
      cursor: pointer;
      width: fit-content;
      background-color: var(--background-color-1);
      color: var(--text-color-2);
      padding: 3px 10px;
      font-size: 13px;
      border-radius: 20px;
      white-space: nowrap;
      display: inline-block;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      .copy-icon {
        svg {
          color: var(--text-color-2);
        }
      }
    }
  }
  .name-input {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 18px;
    background-color: var(--input-bg-color);
    border-color: var(--input-bg-color);
  }
  .save-btn {
    width: 100%;
    font-weight: bold;
  }
  .ant-drawer-body {
    padding: 18px;
    padding-bottom: 10px;
    padding-top: 0;
  }
  .ant-modal-content {
    .ant-modal-body {
      padding-bottom: 4px;
      padding-top: 4px;
    }
  }
}
