.img-content {
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  .nft-img-1 {
    border-radius: 10px;
  }
  .img {
    width: 100%;
    padding-top: 100%;
    .ant-image {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      &[data-type='horizontal'] {
        img {
          width: 100%;
          height: auto;
        }
      }
      &[data-type='vertical'] {
        img {
          width: auto;
          height: 100%;
        }
      }

      img {
        border-radius: 18px;
        margin: auto;
        width: 100%;
        height: 100%;
        @media screen and (min-width: 600px) {
          width: auto;
        }
      }
    }
  }
  .error-text {
    position: absolute;
    bottom: 50px;
    text-align: center;
    width: 100%;
    font-size: 18px;
    font-weight: 500;
    color: var(--text-color-2);
  }
  .icon-content {
    position: absolute;
    top: 4px;
    right: 4px;
    [name='nft_icon'] {
      color: var(--accent-color, #ca40cc);
    }
  }
}
