:root {
  --border-radius-1: 18px;
  --disbled-button-opacity: 0.6;
  --text-distance-1: 20px; // 外边吗距离 距外面
  --text-distance-2: 14px; // 内边框距离 距里面
  --accent-color: #a257fa; // 主色/#A257FA-主色
  --message-success-color: #58c08f;
  --message-error-color: #ea4335;
  --pending-color: var(--accent-color);
  --header-height: 64px;
  --footer-height: 40px;
  --max-content-width: 570px;
  --max-mobile-height: 100vh;
  --primaryIconButtonTextColor: var(--accent-color); // 同 --accent-color
  --primaryIconButtonBackgroundColor: rgba(162, 87, 250, 0.15); // --accent-color 透明 15%
  --modal-mask-background-color: rgba(0, 0, 0, 0.45);
}

// 禁用按钮：在普通按钮的基础上设置透明度 40%
// 禁用Input：同普通Input

:root,
.theme-light {
  --border-color-0: #e0e5f0; // 特殊线颜色粗边框，在背景上面的线颜色
  --border-color-1: #f5f6fb; // Light/中性色/#F5F6FB-首页文本框、分割线
  --input-border-color: #dcdfe6; // 默认输入框线颜色
  --border-color-3: #eef2f9; // 内容有背景描边颜色
  --address-icon-color: #8b8e9f; // 中性色/#8B8E9F-地址复制框
  --shadow-color: #8a8da0; // 中性色/#8A8DA0-投影色
  --shadow-color-2: rgba(138, 141, 160, 0.15);
  --shadow-color-3: rgba(0, 0, 0, 0.1);
  --content-background-color: #fff;
  --nft-item-bg-color: #fff;
  --list-transition-color-1: linear-gradient(0deg, rgba(255, 255, 255, 0), var(--content-background-color));
  --list-transition-color-1-0: linear-gradient(180deg, rgba(255, 255, 255, 0), var(--content-background-color));
  --background-color-1: #f9f9fa; // Light/中性色/#F9F9FA -二级界面文本框/输入框
  --bg-color-4: #fff; // Light/中性色/#FFFFFFF-Swap界面文本框
  --bg-color-5: #eaecf0; // Light/中性色/#EAECF0-三级文本框、灰底上的灰按钮
  --bg-color-6: #f5f6fb; // Light/中性色/#F5F6FB-弹窗文本框 输入框
  --icon-bg-color-1: #fff;
  --icon-bg-color-3: #fff;
  --skeleton-color-1: #dcdfe6;
  --button-color-0: #181b1e; // Dark/中性色/#181B1E-按钮
  --button-color-1: #eff0f2; // Light/中性色/#EFF0F2-灰色按钮
  --text-color-1: #181b1e; // 文本色/高强调
  --text-color-2: #8b8ea1; // 文本色/中强调
  --text-color-3: #dcdfe6; // 文本色/低强调
  --app-background-color: #eaecf0;
  --input-bg-color: #f9f9fa;
  --input-disabled-bg-color: #f5f5f5;
  --primaryButtonTextColor: #fff;
  --primaryButtonBackgroundColor: linear-gradient(271deg, var(--button-color-0) 0%, var(--button-color-0) 100%);
  --cancelButtonBackgroundColor: var(--button-color-1);
  --mack-color-1: rgb(156 156 156 / 70%);
  --icon-src-1: url('/images/img_1_14.png');
  --icon-src-2: url('/images/img_1_13.png');
  --icon-src-3: url('/images/img_3_51.png');
  --icon-src-4: url('/images/img_3_10.png');
  --icon-src-5: url('/images/img_1_6.png');
  --icon-src-6: url('/images/img_1_7.png');
  --icon-src-7: url('/images/img_3_11.png');
  --icon-src-8: url('/images/img_4_6_1.png');
  --icon-src-9: url('/images/img_3_9.png');
  --icon-src-10: url('/images/img_3_12.png');
  --icon-src-13: url('/images/img_2_3.png');
  --icon-src-14: url('/images/img_1_2.png');
  --icon-src-15: url('/images/img_1_10.png');
  --icon-src-16: url('/images/img_5_1.gif');
  --icon-src-17: url('/images/img_7_4.png');
  --icon-src-18: url('/images/img_7_2.png');
  --icon-src-19: url('/images/img_7_5.png');
  --icon-src-20: url('/images/img_7_7.png');
  --icon-src-21: url('/images/img_7_8.png');
  --icon-src-22: url('/images/double_arrow_2.png');
  --icon-src-23: url('/images/img_8_1.png');
  --icon-src-24: url('/images/close_icon.png');
  --no-record-image: url('/images/img_4_11_1.gif?_=1.1');
  --back-icon-src: url('/images/img_1_1.png');
  --nft-icon-share: url('/images/nft_right_icon_000.png');
  --nft-icon-copy: url('/images/nft_copy_icon_000.png');
  --footer-icon-src: url('/images/img_1_9.png');
  --action_menu_item: url('/images/action_000.png');
  --hide-token-icon: url('/images/hide_token_fff.png');
  --token-info-icon: url('/images/token_info_fff.png');
  --loading-icon: url('/images/loading_fff.png');
  @media screen and (min-width: 600px) {
    --max-mobile-height: 600px;
  }
}

// dark 主题
.theme-dark {
  --border-color-0: #2f2f2f;
  --border-color-1: #292b36; // Dark/中性色/#292B36-首页文本框、分割线
  --input-border-color: #707070;
  --border-color-3: #2f2f2f;
  --address-icon-color: #8b8e9f; // 中性色/#8B8E9F-地址复制框
  --shadow-color: #181b1e; // 中性色/#181b1e-投影色
  --shadow-color-2: rgba(24, 27, 30, 0.15);
  --shadow-color-3: rgba(0, 0, 0, 0.25);
  --content-background-color: #1c1d22; // Dark/中性色/1C1D22-背景
  --nft-item-bg-color: none;
  --list-transition-color-1: linear-gradient(0deg, rgba(28, 29, 34, 0), var(--content-background-color));
  --list-transition-color-1-0: linear-gradient(180deg, rgba(28, 29, 34, 0), var(--content-background-color));
  --background-color-1: #232529; //Dark/中性色/#232529 -二级界面文本框/输入框
  --bg-color-4: #232529; // Dark/中性色/#232529-Swap界面文本框
  --bg-color-5: #303139; // Dark/中性色/#303139-三级文本框、灰底上的灰按钮
  --bg-color-6: #303139; // Dark/中性色/#303139-弹窗文本框 输入框
  --icon-bg-color-1: #3a3d4b;
  --icon-bg-color-3: #45464c;
  --skeleton-color-1: #292b36;
  --button-color-0: #ffff; // Dark/中性色/#FFFFFFF-按钮
  --button-color-1: #41424a; // Dark/中性色/#41424A-灰色按钮
  --text-color-1: #fff; // 文本色/高强调
  --text-color-2: #8b8ea1; // 文本色/中强调
  --text-color-3: #42444b; // 文本色/低强调
  --app-background-color: #0c0e0f;
  --input-bg-color: #303139;
  --input-disabled-bg-color: #232529;
  --primaryButtonTextColor: #181b1e;
  --primaryButtonBackgroundColor: linear-gradient(271deg, var(--button-color-0) 0%, var(--button-color-0) 100%);
  --cancelButtonBackgroundColor: var(--button-color-1);
  --mack-color-1: rgba(25, 28, 31, 0.7);
  --icon-src-1: url('/images/img_4_2.png');
  --icon-src-2: url('/images/img_4_3.png');
  --icon-src-3: url('/images/img_3_51_1.png');
  --icon-src-4: url('/images/img_4_1.png');
  --icon-src-5: url('/images/img_1_6_1.png');
  --icon-src-6: url('/images/img_4_1.png');
  --icon-src-7: url('/images/img_3_11_1.png');
  --icon-src-8: url('/images/img_4_6.png');
  --icon-src-9: url('/images/img_3_9_1.png');
  --icon-src-10: url('/images/img_3_12_1.png');
  --icon-src-13: url('/images/img_2_3_1.png');
  --icon-src-14: url('/images/img_1_2_1.png');
  --icon-src-15: url('/images/img_4_4.png');
  --icon-src-16: url('/images/img_5_2.gif');
  --icon-src-17: url('/images/img_7_3.png');
  --icon-src-18: url('/images/img_7_1.png');
  --icon-src-19: url('/images/img_7_6.png');
  --icon-src-20: url('/images/img_7_7_1.png');
  --icon-src-21: url('/images/img_7_8_1.png');
  --icon-src-22: url('/images/double_arrow_1.png');
  --icon-src-23: url('/images/img_8_1_1.png');
  --icon-src-24: url('/images/close_icon_d.png');
  --no-record-image: url('/images/img_4_11.gif?_=1.1');
  --back-icon-src: url('/images/img_1_1_1.png');
  --footer-icon-src: url('/images/img_1_9_1.png');
  --action_menu_item: url('/images/action_fff.png');
  --nft-icon-share: url('/images/nft_right_icon_fff.png');
  --nft-icon-copy: url('/images/nft_copy_icon_fff.png');
  --hide-token-icon: url('/images/hide_token_000.png');
  --token-info-icon: url('/images/token_info_000.png');
  --loading-icon: url('/images/loading_000.png');

  .ant-switch-checked:focus {
    box-shadow: initial;
  }
  .ant-spin-container:after {
    background: none;
  }
}

body {
  .particle-connect-ui-theme-dark,
  .particle-connect-ui-theme-light {
    --bg-color: var(--content-background-color);
    --font-color2: var(--text-color-2);
  }
}

.delete-btn {
  background-color: #ea4335 !important;
  color: #fff !important;
  font-weight: bold;
}

html,
body {
  background-color: var(--content-background-color);
}

iframe {
  color-scheme: none;
}
