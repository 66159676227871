.currency-list-container {
  > .wrapper {
    background-color: var(--content-background-color) !important;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .main-content {
    height: 0px;
    flex: 1;
  }

  .list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 var(--text-distance-1);
      padding: 0 var(--text-distance-2);
      height: 60px;
      color: var(--text-color-1);
      cursor: pointer;
      background: var(--background-color-1);
      border-radius: 18px;
      margin-bottom: 10px;
      cursor: pointer;
      .left {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        color: var(--text-color-1);
      }
      .right {
        width: 14px;
        height: 14px;
        .icon.selected {
          width: 100%;
          height: 100%;
          background-image: var(--icon-src-8);
          background-size: 100% 100%;
        }
      }
    }
  }
}
