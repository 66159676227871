.token-list-container {
  > .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--content-background-color) !important;
  }
  .add-custom-token {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 60px;
    margin: 0 var(--text-distance-1);
    padding: 0 var(--text-distance-2);
    height: 60px;
    color: var(--text-color-1);
    cursor: pointer;
    background: var(--background-color-1);
    border-radius: 18px;
    margin-bottom: 10px;
    .icon {
      width: 14px;
      height: 14px;
      background-image: var(--icon-src-9);
      background-size: 100% 100%;
    }
  }
  .no-record-container {
    padding: 0;
    padding-bottom: 10%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .page-content {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    .ant-spin-nested-loading {
      height: 0;
      flex: 1;
      .ant-spin-container {
        height: 100%;
      }
    }
  }
  .token-list {
    height: 100%;
    overflow-y: auto;
    .wrap {
      .list-titleKey1,
      .list-titleKey2 {
        padding: 8px 18px;
        color: var(--text-color-2);
        height: 40px;
        display: flex;
        align-items: center;
      }
      .token-item {
        height: 60px;
        padding: 0 var(--text-distance-1);
        display: flex;
        justify-content: space-between;
        position: relative;
        cursor: pointer;
        color: var(--text-color-1);
        // border-bottom: 1px solid var(--border-color-1);
        &:after {
          display: none;
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          width: calc(100% - 18px - 30px - 6px);
          height: 1px;
          border-bottom: 1px solid var(--border-color-1);
        }
        .left {
          display: flex;
          align-items: center;

          font-size: 14px;
          font-weight: 600;
          line-height: 1;

          .token-icon {
            width: 30px;
            height: 30px;
            margin-right: 6px;
            border-radius: 30px;
            overflow: hidden;
            object-fit: cover;
          }
        }
        .right {
          display: flex;
          align-items: center;
          overflow: hidden;

          .wrap {
            height: 100%;
            .operate {
              min-width: 50px;
              display: flex;
              align-items: center;
              justify-content: flex-end;
              height: 100%;
              &[data-type='remove'] {
                .micon {
                  color: var(--text-color-3);
                }
              }
              .add-icon {
                width: 20px;
                height: 20px;
                background: red;
              }
            }
          }
        }
      }
    }
  }
}
