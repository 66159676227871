@import './flex.less';
@import './ant-theme.less';
@import './minter-theme-overrides.less';
// @import '../../public/fonts/apercu/index.css';
@import '../../public/fonts/SF-Pro-Text/index.css';
@import '../../public/fonts/iconfont/iconfont.css';
@import './theme.less';
@import './walletEntry.less';

@font-family: 'SF Pro Text', 'PingFang SC', 'Lantinghei SC', 'Helvetica Neue', 'Helvetica, Arial', 'Microsoft YaHei', '微软雅黑', 'STHeitiSC-Light', 'simsun', '宋体', 'WenQuanYi Zen Hei',
  'WenQuanYi Micro Hei', 'sans-serif';

:root {
  --vh: 1vh;
}
* {
  outline: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

html,
body,
#__next,
.ant-layout {
  height: 100%;
  font-family: @font-family;
  @media screen and (min-width: 600px) {
    overflow: hidden;
  }
}

a {
  cursor: pointer;
}

html,
body,
button {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.25em;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.25em;
  letter-spacing: 0.2;
}

input,
textarea {
  text-align: inherit;
  border: none;
}

body {
  background-repeat: no-repeat;
  min-width: 330px;
}

a {
  color: inherit;
  text-decoration: none;
}
p {
  margin: 0;
}
.ant-layout {
  overflow-y: auto;
}

.ant-radio,
.ant-checkbox {
  top: 0.165em !important;
  margin-right: 4px !important;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: inherit;
}

:focus-visible {
  outline: none;
}

img {
  display: block;
  width: 100%;
}

.default-layout {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  // padding-bottom: calc(var(--footer-height) + constant(safe-area-inset-bottom) / 3);
  // padding-bottom: calc(var(--footer-height) + env(safe-area-inset-bottom) / 3);
  .layout-main {
    width: 100%;
    height: 0;
    flex: 1;
    // overflow-y: auto;
    background-color: var(--content-background-color);

    > div[class*='-container'],
    > #keep-alive-container > div > div[class*='-container'] {
      height: calc(100% - var(--footer-height));
      width: 100%;
      overflow: auto;

      // @media screen and (min-width: 600px) {
      //   width: 400px;
      //   height: 700px;
      //   position: relative;
      //   position: fixed;
      //   left: 50%;
      //   top: 42%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
      //   box-shadow: 0 0 9px 4px rgba(0, 0, 0, 0.1);
      //   border-radius: 10px;
      //   overflow: hidden;
      //   background: #fff;
      // }

      @media screen and (max-width: 600px) {
        -webkit-overflow-scrolling: touch;
      }

      @media screen and (max-height: 850px) and (min-width: 600px) {
        top: 10px;
        transform: translate(-50%, 0%);
      }
    }
    > div[class*='-container'],
    > #keep-alive-container > div > div[class*='-container'],
    .index-container {
      @media screen and (min-width: 600px) {
        width: 100% !important;
        height: 100% !important;
        position: initial !important;
        top: initial !important;
        left: initial !important;
        transform: initial !important;
        box-shadow: initial !important;
        background: var(--app-background-color) !important;
        > .wrapper {
          width: 80%;
          max-width: 968px;
          margin: 0 auto;
          background: var(--content-background-color);
          height: calc(100% - 60px);
          max-height: calc(100% - 60px);
          position: relative;
          top: 60px;
          box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.05);
          overflow: initial;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          z-index: 1;
          min-height: 600px;

          &:before {
            content: '';
            position: absolute;
            top: -46px;
            background-image: var(--icon-src-10);
            width: 133px;
            height: 32px;
            background-size: 100% 100%;
          }
        }
      }
    }
    #keep-alive-container {
      height: 100%;
      @media screen and (max-width: 600px) {
        @media screen and (-moz-min-device-pixel-ratio: 0) {
          min-height: 100%;
          height: auto;
        }
      }
      > div {
        height: 100%;
        @media screen and (max-width: 600px) {
          @media screen and (-moz-min-device-pixel-ratio: 0) {
            min-height: 100%;
            height: auto;
          }
        }
      }
    }
    @media screen and (min-width: 600px) {
      background: #f2f4f6;
    }
  }
  .default-layout-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    height: var(--footer-height);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    // background-color: var(--content-background-color);
    background-color: var(--content-background-color);
    &.page-index {
      background: var(--content-background-color);
    }

    @media screen and (max-height: 500px) {
      display: none;
    }
    @media screen and (min-width: 600px) {
      display: none;
    }

    .logo {
      width: 171px;
      height: 17px;
      background-image: var(--footer-icon-src);
      background-size: 100% 100%;
    }
  }
}

.m-image-wrapper {
  position: relative;
}

.list-spin-content {
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 5750px) {
  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.scroll-content-1 {
  @media screen and (min-width: 600px) {
    height: calc(100% - var(--header-height));
    overflow: auto;
    // padding-bottom: 60px;
  }
}

.hidden {
  display: none;
}

.web-wallet-loading {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 99999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.5);
  .ant-spin-blur {
    width: 100%;
    height: 100%;
  }
}

.model-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  model-viewer {
    width: 100%;
    height: 100%;
  }
}
.c-pointer {
  cursor: pointer;
}

.mini-link-hidden {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}
.mini-link-content {
  position: relative;
  cursor: pointer;
  > a {
    position: absolute;
    // z-index: 1;
    width: 0;
    height: 0;
    top: 0;
    left: 0;
  }
}

div[contenteditable] {
  &:focus {
    outline: none;
  }
}

// [data-1155='true'] {
//   background: red !important;
// }

.skeleton-rc-5 {
  border-radius: 5px;
}

.particle-connect-modal-container.show {
  background-color: var(--app-background-color);
}

.particle-connect-modal-close {
  display: none;
}

.wallet-drawer-1 {
  .ant-drawer-content {
    overflow: hidden;
    background-color: var(--bg-color-4);
  }
  .ant-drawer-body {
    padding: 0;
  }

  .ant-drawer-header {
    border: none;
    background-color: var(--bg-color-4);
    .ant-drawer-header-title {
      text-align: center;
      .ant-drawer-title {
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        color: var(--text-color-1);
      }
    }
    .ant-drawer-extra {
      font-size: 18px;
      color: var(--text-color-1);
    }
    .ant-drawer-close {
      display: none;
    }
  }
}
:root {
  .wallet-model-1 {
    .ant-modal-header {
      border-bottom: 1px solid var(--border-color-1);
    }
    .ant-btn {
      height: 40px;
      border-radius: 40px;
    }
    .ant-btn-default {
      min-width: 100px;
      color: #fff;
      &:hover {
        background-color: rgba(0, 0, 0, 0.4) !important;
      }
    }
    .ant-btn-primary {
      min-width: 80px;
    }
  }
}
.system-warning-confirm {
  .ant-btn-primary {
    min-width: 80px;
    height: 40px;
    border-radius: 40px;
  }
}

.theme-dark {
  .system-warning-confirm {
    .ant-modal-confirm-content {
      color: #fff;
      font-size: 14px;
    }
    .ant-btn-primary {
      min-width: 80px;
      height: 40px;
      border-radius: 40px;
    }
  }
}

#mock-address {
  position: fixed;
  z-index: 9;
  bottom: 50px;
  left: -80px;
  width: 240px;
  height: 40px;
  line-height: 40px;
  background: blue;
  text-align: center;
  transform: rotate(45deg);
  padding-left: 40px;
  &:after {
    content: 'Observation mode';
    font-size: 15px;
    line-height: 40px;
    color: #fff;
  }
}

.ant-message-error {
  max-width: 600px;
  span:last-child {
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
  }
}

.break-word {
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.inline-block {
  display: inline-block;
}

body[data-device='firefox'] {
  .particle-approve-popup {
    display: none !important;

    &.particle-approve-popup-show {
      display: flex !important;
    }
  }
}
.word-wrap {
  word-break: break-all !important;
  word-wrap: break-word !important;
  white-space: pre-wrap !important;
}
.max-width-80 {
  max-width: 80% !important;
}

.text-right {
  text-align: right !important;
}

::selection {
  color: #ffffff;
  background-color: var(--accent-color);
}

.height-100 {
  height: 100%;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.icons_1 {
  .ant-image {
    border-color: transparent !important;
  }
}

.status-success,
.status-confirmed {
  color: var(--message-success-color) !important;
}
.status-pending {
  color: var(--pending-color) !important;
}
.status-failed {
  color: var(--message-error-color) !important;
}

.error-page {
  color: var(--text-color-1);
  padding: 20px;
  a {
    color: var(--message-error-color);
  }
  div {
    height: auto !important;
  }
}
