.transaction-detail-container {
  min-height: 500px;
  > .wrapper {
    background-color: var(--content-background-color) !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }
  .no-detail-record {
    flex: 1;
    height: 0;
    padding-top: 16vh;
  }
  .main-content {
    height: 0;
    flex: 1;
    overflow: auto;
    .info {
      display: flex;
      justify-items: center;
      justify-content: center;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 20px;
      .wrap {
        .icon-wrap {
          height: 52px;
        }
        .icon {
          width: 52px;
          height: 52px;
          margin: auto;
        }
        .micon[data-type='success'] {
          color: var(--message-success-color);
        }
        .micon[data-type='failed'] {
          color: var(--message-error-color);
        }
        .micon[data-type='pending'] {
          color: var(--pending-color);
        }
        .title {
          font-size: 31px;
          // color: #00dd85;
          opacity: 1;
          margin-bottom: 10px;
          color: var(--text-color-1);
          height: 48px;
        }
        .date {
          font-size: 12px;
          font-weight: 400;
          color: var(--text-color-2);
          height: 38px;
        }
      }
    }
  }
}

.solana-transaction-detail-container {
  .main-content {
    .details {
      padding: 0 16px;
      .card {
        background: var(--background-color-1);
        // border: 1px solid var(--border-color-3);
        border-radius: 18px;
        margin-bottom: 10px;
        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px 14px;
          .left {
            font-size: 16px;
            color: var(--text-color-1);
            font-weight: 400;
          }
          .right {
            font-size: 14px;
            font-weight: 400;
            color: var(--text-color-2);
            &.right-success {
              color: var(--message-success-color);
            }
            &.right-failed {
              color: var(--message-error-color);
            }
            &.right-Failed {
              color: var(--message-error-color);
            }
            &[data-type='out'] {
              color: var(--message-error-color) !important;
            }
          }
        }
        &.card2,
        &.card3 {
          .row:first-child {
            &:before {
              display: none;
            }
          }
          .row:last-child {
            position: relative;
            padding: 15px 14px;
            // &:before {
            //   content: '';
            //   position: absolute;
            //   width: calc(100% - 8px - 8px);
            //   height: 1px;
            //   top: 0;
            //   left: 8px;
            //   border-top: 1px solid var(--border-color-0);
            // }
          }
          .icon {
            width: 16px;
            height: 16px;
            background-image: var(--icon-src-9);
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}

.evm-transaction-detail-container {
  .main-content {
    .details {
      padding: 0 var(--text-distance-1);
      .card {
        background: var(--background-color-1);
        border: 1px solid var(--border-color-3);
        border-radius: 10px;
        margin-bottom: 10px;
        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 14px 8px;
          .left {
            font-size: 16px;
            color: var(--text-color-1);
            font-weight: 400;
          }
          .right {
            font-size: 14px;
            font-weight: 400;
            color: var(--text-color-2);
            &.right-failed {
              color: #ff7676;
            }
          }
        }
        &.card1,
        &.card2 {
          .row:first-child {
            padding-bottom: 0;
          }
        }
        &.card1,
        &.card3 {
          .row:first-child {
            &:before {
              display: none;
            }
          }
          .row:last-child {
            position: relative;
            padding: 14px 8px;
            // margin-bottom: 10px;
            &:before {
              content: '';
              position: absolute;
              width: calc(100% - 8px - 8px);
              height: 1px;
              top: 0;
              left: 8px;
              border-top: 1px solid var(--border-color-0);
            }
          }
          .icon {
            width: 16px;
            height: 16px;
            background-image: var(--icon-src-9);
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .footer {
    .ant-btn-default:hover,
    .ant-btn-default:focus,
    .ant-btn-default:active {
      background: rgba(0, 0, 0, 0.4) !important;
      color: #fff;
    }
  }
}
.theme-dark {
  .transaction-detail-container {
    .footer {
      .ant-btn-default:hover,
      .ant-btn-default:focus,
      .ant-btn-default:active {
        background: #666 !important;
      }
    }
  }
}
