.token-detail-container {
  > .wrapper {
    background-color: var(--content-background-color) !important;
    min-height: 100%;
    @media screen and (min-width: 600px) {
      min-height: initial;
    }
    &[data-page-mode='simple'] {
      --header-height: 64px;
    }
    .header-container {
      @media screen and (max-width: 600px) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .action-bar {
    cursor: pointer;
    display: flex;
    align-items: center;
    svg {
      color: var(--text-color-1);
    }
  }
  .header-title {
    display: flex;
    align-items: center;
    position: relative;
    .icon {
      width: 20px;
      height: auto;
      max-height: 30px;
      margin-right: 4px;
      border-radius: 100%;
      overflow: hidden;
    }
  }

  .main-content {
    &.scroll-content-1 {
      padding-bottom: 0;
    }
    .wallet-info-content {
      text-align: center;
      padding-bottom: 20px;
      padding-top: 30px;
      // background-color: var(--content-background-color);
      background-color: var(--content-background-color);
      .address-copy {
        padding-bottom: 28px;
        display: flex;
        justify-content: center;
        .wrap {
          padding-top: 3px;
          width: 138px;
          height: 24px;
          background: var(--background-color-1);
          line-height: 1;
          opacity: 1;
          border-radius: 12px;
          font-size: 12px;
          font-weight: 400;
          color: var(--text-color-2);
          display: flex;
          align-items: center;
          justify-content: center;
          .copy-icon {
            display: flex;
            width: 14px;
            height: 14px;
            margin-left: 6px;
            position: relative;
            top: -1px;
            cursor: pointer;
            svg {
              color: var(--text-color-2);
            }
          }
        }
      }
      .account-info-Nx9 {
        padding-bottom: 30px;
        .row1 {
          font-size: 40px;
          color: var(--text-color-1);
          font-weight: 900;
          line-height: 1;
          margin-bottom: 8px;
        }
        .row2 {
          font-weight: 600;
          font-size: 16px;
          line-height: 14px;
          color: var(--text-color-2);
        }
      }
      .operate-content {
        display: flex;
        margin: auto;
        width: 90%;
        justify-content: space-between;
        max-width: 360px;
        align-items: flex-start;
        @media screen and (min-width: 600px) {
          max-width: 400px;
        }
        @media screen and (max-width: 360px) {
          max-width: 90%;
          width: 90%;
        }

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          width: 70px;
          &.disabled {
            opacity: 0.5;
          }
          .wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
          }

          .icon-button-default {
            margin: auto;
          }
          .micon {
            font-size: 40px;
          }
          .name {
            font-size: 15px;
            font-weight: 600;
            color: var(--text-color-1);
            line-height: 1.3;
            margin-top: 10px;
            text-align: center;
          }
        }
      }
    }

    .list-content {
      @media screen and (min-width: 600px) {
        border-top: none;
        padding-top: 0;
      }

      .ant-spin-dot {
        background-image: var(--icon-src-16);
        background-size: cover;
        width: 60px;
        height: 60px;
        top: 40%;
        animation: none;
        i {
          display: none;
        }
      }
      .page-mode-simple {
        .ant-tabs-nav {
          display: none;
        }
      }
      .page-mode-simple {
        .lds-default {
          margin: 0;
        }
      }
      .ant-tabs-nav {
        padding: 0 18px;
        position: sticky;
        top: var(--header-height);
        left: 0;
        background: #fff;
        z-index: 2;
        padding-bottom: 4px;
        width: 100%;
        background: var(--content-background-color);
        top: -1px;
        &:after {
          display: none;
          content: '';
          width: 100%;
          height: 1px;
          position: absolute;
          border-bottom: 2px solid var(--border-color-1);
          left: 0;
          top: 0px;
          z-index: -1;
        }
        @media screen and (min-width: 600px) {
          padding: 0 28px;
        }
        &:before {
          display: none;
        }
        .ant-tabs-nav-wrap {
          width: 100%;
          .ant-tabs-ink-bar {
            display: none;
            background: none;
            @media screen and (min-width: 600px) {
              top: 0;
            }
            &:after {
              content: '';
              position: absolute;
              width: 22px;
              height: 3px;
              bottom: 0;
              left: 0;
              left: 50%;
              transform: translateX(-50%);
              max-width: 100%;
              @media screen and (min-width: 600px) {
                width: 100%;
              }
            }
          }
          .ant-tabs-nav-list {
            width: 100%;
            .ant-tabs-tab {
              font-size: 18px;
              line-height: 1;
              font-weight: 400;
              color: var(--text-color-2);
              text-align: center;
              display: flex;
              justify-content: center;
              .ant-tabs-tab-btn:focus,
              .ant-tabs-tab-remove:focus,
              .ant-tabs-tab-btn:active,
              .ant-tabs-tab-remove:active {
                background-color: none;
                color: var(--text-color-2);
              }
              &.ant-tabs-tab-active {
                .ant-tabs-tab-btn {
                  color: var(--text-color-1) !important;
                }
              }
            }
          }
        }
        .ant-tabs-extra-content {
          .token-add-btn {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .page-loading {
    display: none;
    &.ant-spin-spinning {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
    }
  }
}
.ant-drawer-content {
  background-color: var(--bg-color-4);
}
.drawer-token {
  display: flex;
  align-items: center;
  flex-direction: column;

  .drawer-token-item {
    width: 100%;
    height: 60px;
    background: var(--bg-color-6);
    border-radius: 18px;
    opacity: 1;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: var(--text-color-1);
    padding: 0 15px;
    box-sizing: border-box;
    cursor: pointer;
    .drawer-token-icon {
      width: 41px;
      height: 41px;
      border-radius: 50%;
      margin-right: 15px;
    }
  }

  .footer-logo {
    width: 171px;
    height: 17px;
    background-image: var(--footer-icon-src);
    background-size: 100% 100%;
  }
}
.hide-token-popover {
  left: auto !important;
  right: 0 !important;
  .ant-popover-arrow {
    right: 24px;
  }
  .ant-popover-arrow-content {
    --antd-arrow-background-color: var(--background-color-1);
  }
  .ant-popover-inner {
    background: var(--bg-color-4);
    border-radius: 18px;
  }
  .footer-logo {
    display: none;
  }
  .drawer-token {
    .drawer-token-item {
      width: 280px;
    }
    .drawer-token-item:nth-child(2) {
      margin-bottom: 0;
    }
  }
}
