.wallet-connect-container {
  .ant-drawer-content {
    .ant-drawer-body {
      padding: 0;
    }
  }
  .content {
    position: relative;
    text-align: center;
    min-height: 400px;
    max-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 14px;
    padding-right: 14px;
    @media screen and (min-width: 600px) {
      padding-left: 0;
      padding-right: 0;
    }
    .logo {
      width: 58px;
      height: 58px;
      margin-top: 23px;
      border-radius: 50%;
      overflow: hidden;
    }
    .website_name {
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      color: var(--text-color-1);
      opacity: 1;
      margin-top: 13px;
    }
    .title {
      font-size: 18px;
      font-weight: bold;
      line-height: 19px;
      color: var(--text-color-1);
      opacity: 1;
      margin-top: 33px;
      margin-bottom: 12px;
    }
    .desc {
      width: 300px;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      // color: #020202;
      color: var(--text-color-2);
      opacity: 1;
      margin-top: 10px;
    }
    .wallet_info_all {
      width: 339px;
      height: 72px;
      border-radius: 18px;
      margin-top: 25px;
      margin-bottom: 10px;
      background: var(--bg-color-5);
      .wallet_info {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .wallet_info_left {
          margin-left: 20px;
          margin-top: 10px;
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            overflow: hidden;
          }
        }
        .wallet_info_right {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          margin-top: 20px;
          align-items: flex-start;
          .right_wallet_name {
            font-size: 16px;
            font-weight: bold;
            line-height: 9px;
            color: var(--text-color-1);
          }
          .right_address {
            font-size: 14px;
            font-weight: 400;
            line-height: 9px;
            margin-top: 10px;
            text-align: left;
            color: var(--text-color-2);
          }
        }
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      width: 100%;
      justify-content: space-between;
      gap: 20px;
      button {
        flex: 1;
      }
    }

    .wallet_footer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      @media screen and (min-width: 600px) {
        display: none;
      }
      .logo_logo {
        width: 171px;
        height: 17px;
      }
    }
  }
}
