._page_login {
  .login-container {
    color: var(--text-color-1);
    font-size: 50px;
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }
  #particle-network-container {
    background: none !important;
    [name='particle-network-iframe'][src*='/login'] {
      width: 100% !important;
      height: 100% !important;
      border-radius: 0 !important;
    }
  }
}
