.particle-wallet-entry-container .aa {
  z-index: 6534575367456741;
}

.particle-wallet-entry-container .particle-pwe-btn {
  background: none;
  border: none;
  cursor: pointer;
  height: 60px;
  margin: 0;
  outline: none;
  padding: 0;
  position: fixed;
  width: 60px;
}
.particle-wallet-entry-container .particle-pwe-btn:not(.is-dragging) {
  transition: all 0.2s;
}

.particle-wallet-entry-container .particle-pwe-btn > img {
  height: 100%;
  width: 100%;
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.1);
  border-radius: 60px;
}

.particle-wallet-entry-container .particle-pwe-btn .particle-pwe-wallet-icon {
  display: block;
}

.particle-wallet-entry-container .particle-pwe-btn .particle-pwe-wallet-icon:not(.particle-pwe-wallet-icon-hide) {
  animation: particle-pwe-wallet-icon-show 0.3s ease-in-out;
}

@keyframes particle-pwe-wallet-icon-show {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}

.particle-wallet-entry-container .particle-pwe-btn .particle-pwe-wallet-icon.particle-pwe-wallet-icon-hide {
  display: none;
}

.particle-wallet-entry-container .particle-pwe-btn .particle-pwe-down-arrow {
  display: block;
}

.particle-wallet-entry-container .particle-pwe-btn .particle-pwe-down-arrow:not(.particle-pwe-down-arrow-hide) {
  animation: particle-pwe-down-arrow-show 0.3s ease-in-out;
}

@keyframes particle-pwe-down-arrow-show {
  0% {
    transform: scale(0.6);
  }
  100% {
    transform: scale(1);
  }
}

.particle-wallet-entry-container .particle-pwe-btn .particle-pwe-down-arrow.particle-pwe-down-arrow-hide {
  display: none;
}

.particle-wallet-entry-container .particle-pwe-iframe-content {
  background-color: #fff;
  border: none;
  border-radius: 10px;
  box-shadow: -1px 3px 11px 2px #00000073;
  display: none;
  height: 650px;
  overflow: hidden;
  position: fixed;
  width: 400px;
  z-index: 2147483647;
}

.particle-wallet-entry-container .particle-pwe-iframe-content.particle-pwe-iframe-content-show {
  display: block;
}

.particle-wallet-entry-container .particle-pwe-iframe-content .particle-pwe-iframe {
  border: none;
  height: 100%;
  width: 100%;
}
