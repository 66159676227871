.double-arrows-container {
  [data-pathname='/tokenDetail.html'],
  [data-pathname='/tokenDetail'],
  [data-pathname='tokenDetail.html'] {
    width: 30px !important;
    height: 30px !important;
    position: relative;
    .close-icon {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0 !important;
      left: 7px !important;
      .anticon {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .double-arrow-icon {
      position: absolute;
      top: -3px !important;
      left: 7px !important;
      .anticon {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
  .double_arrow {
    cursor: pointer;
    display: block;
    background: none;
    width: 50px;
    height: 50px;
    position: relative;
    top: 0px;
    left: -10px;
    margin-right: -10px;

    .double-arrow-icon {
      display: block;
      width: 30px;
      height: 30px;
      position: relative;
      top: 10px;
      left: 8px;
      svg {
        color: var(--text-color-1);
      }
    }
    .close-icon {
      display: none;
      position: absolute;
      top: 10px;
      left: 5px;
    }
    &.close {
      background: none;
      .double-arrow-icon {
        display: none;
      }
      .close-icon {
        display: block;
        width: 30px;
        height: 30px;
        position: relative;
        top: 10px;
        left: 8px;
      }
    }
  }
}
