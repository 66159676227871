.swap-modal-confirm {
  &.slip-modal {
    .confirm-footer-img {
      display: none;
    }
  }
  .ant-drawer-body {
    .confirm-footer-img {
      display: flex !important;
    }
  }
  .confirm-footer-img {
    padding-bottom: calc(constant(safe-area-inset-bottom) / 3);
    padding-bottom: calc(env(safe-area-inset-bottom) / 3);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      width: 171px;
      height: 17px;
      background-image: var(--footer-icon-src);
      background-size: 100% 100%;
    }
  }
  .confirm-title {
    font-size: 16px;
    font-weight: 600;
    .title-type {
      font-size: 11px;
      font-weight: 500;
      display: flex;
      justify-content: center;
      padding-top: 5px;
      align-items: center;
      .title-icon {
        width: 11px;
        height: 11px;
        margin-right: 3px;
      }
    }
  }
  .confirm-content0 {
    color: var(--text-color-1);
    .content-wrap {
      .content-row:last-child {
        border-bottom: 1px solid var(--border-color-1);
      }
    }
    .content-row {
      display: flex;
      justify-content: space-between;
      padding: 18px 0;
      border-top: 1px solid var(--border-color-1);
      &.last-row {
        border-bottom: 1px solid var(--border-color-1);
      }
      .row-left {
        display: flex;
        .left-icon {
          width: 24px;
          height: 24px;
          overflow: hidden;
          border-radius: 12px;
          margin-right: 8px;
        }
      }
    }
    .confirm-footer {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 21px;
      .confirm-time {
        font-size: 14px;
        font-weight: 400;
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        .limit-time {
          background-image: var(--icon-src-19);
          background-size: cover;
          width: 15px;
          height: 15px;
          margin-right: 8px;
        }
      }
      .swap-now-btn {
        font-size: 17px;
        font-weight: 600;
        width: 100%;
      }
    }
    .confirm-update {
      p {
        text-align: center;
      }
      .content {
        margin-bottom: 20px;
        color: var(--text-color-2);
      }
    }
    .confirm-submitted {
      .list {
        .item {
          display: flex;
          justify-content: space-between;
          padding: 18px 0;
          border-top: 1px solid var(--border-color-1);
          .left {
            display: flex;
            align-items: center;
            gap: 8px;
          }
          .right {
            display: flex;
            align-items: center;
            .micon {
              transform: rotate(270deg);
              position: relative;
              top: 2px;
            }
          }
        }
      }
    }
  }
}
