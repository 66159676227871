.header-container {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: var(--content-background-color);
  height: var(--header-height);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.header-container .wrapper {
  height: var(--header-height);
  padding: 0 var(--text-distance-1);
  line-height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-container .wrapper .left {
  width: 36px;
  height: 100%;
  display: flex;
  align-items: center;
}
.header-container .wrapper .left .back-btn-wrap {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: -8px;
}
.header-container .wrapper .left .back-btn-wrap.hidden {
  display: none;
}
.header-container .wrapper .left .back-btn-wrap .back-btn {
  width: 19px;
  height: 19px;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.header-container .wrapper .middle {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color-1);
  line-height: 1;
}
.header-container .wrapper .right {
  width: 36px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.header-container.header-search-container .middle .wrap {
  width: 100%;
}
.header-container.header-search-container .middle .wrap .ant-input-affix-wrapper {
  background: var(--background-color-1);
  border-radius: 32px;
  border: 1px solid var(--background-color-1);
}
.header-container.header-search-container .middle .wrap .ant-input-affix-wrapper[data-focus='true'] {
  border-color: var(--accent-color);
}
.header-container.header-search-container .middle .wrap .ant-input-affix-wrapper .anticon-close-circle {
  color: var(--text-color-3);
}
.header-container.header-search-container .middle .wrap .ant-input-affix-wrapper .ant-input-prefix .anticon {
  color: var(--text-color-3);
}
.header-container.header-search-container .right {
  width: 0px;
}
._page_buy .back-btn {
  background: none !important;
  --text-color: #000;
}
._page_index .layout-main {
  background-color: var(--content-background-color);
}
.index-container {
  height: calc(100% - var(--footer-height) - constant(safe-area-inset-bottom) / 3);
  height: calc(100% - var(--footer-height) - env(safe-area-inset-bottom) / 3);
  overflow: auto;
}
.index-container .wrapper {
  height: 100%;
}
.index-container .pc-logo {
  display: none;
}
.index-container .index-header {
  display: flex;
  justify-content: space-between;
  padding: 0 18px;
  height: var(--header-height);
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  background: var(--content-background-color);
  z-index: 1;
}
@media screen and (min-width: 600px) {
  .index-container .index-header {
    padding: 0 var(--text-distance-1);
  }
}
.index-container .index-header .left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.index-container .index-header .middle {
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.index-container .index-header .right {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.index-container .index-header .right .setting-icon {
  width: 30px;
  height: 30px;
}
.index-container .index-header .right .setting-icon.show-red-dot:after {
  content: '';
  border-radius: 100%;
  top: 0px;
  right: 0px;
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
  background-color: #ff0201;
  border: 1px solid #ffffff;
}
.index-container .index-header .right .pn-wc-scan-btn {
  width: 30px;
  height: 30px;
  margin-right: 10px;
  cursor: pointer;
}
.index-container .index-header .right .pn-wc-scan-btn.hide {
  display: none;
}
.index-container .import-token-btn {
  display: none;
}
.index-container .main-content {
  padding-top: var(--header-height);
  height: 100%;
  background: var(--content-background-color);
}
.index-container .wallet-info-content {
  text-align: center;
  padding-bottom: 20px;
  background-color: var(--content-background-color);
}
.index-container .wallet-info-content .product-name {
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
}
.index-container .wallet-info-content .product-name span {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-color-1);
}
.index-container .wallet-info-content .product-name .aa-tag {
  display: flex;
  align-items: center;
}
.index-container .wallet-info-content .address-copy {
  height: 24px;
  margin-top: 8px;
  margin-bottom: 28px;
  display: flex;
  justify-content: center;
  position: relative;
  gap: 8px;
}
.index-container .wallet-info-content .address-copy .wrap {
  padding-top: 1px;
  height: 24px;
  border-radius: 24px;
  padding: 0 10px;
  overflow: hidden;
  line-height: 1;
  opacity: 1;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  color: var(--text-color-2);
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  position: relative;
}
.index-container .wallet-info-content .address-copy .wrap:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--address-icon-color);
  opacity: 0.15;
  pointer-events: none;
  box-sizing: initial;
  animation: initial;
}
.index-container .wallet-info-content .address-copy .wrap .copy-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.index-container .wallet-info-content .address-copy .wrap .copy-wrap .copy-icon {
  margin-left: 6px;
  cursor: pointer;
  font-size: 14px;
  color: var(--text-color-2);
}
.index-container .wallet-info-content .address-copy .wrap .copy-wrap .address-arrow-wrap {
  height: 24px;
  width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 4px;
  cursor: pointer;
}
.index-container .wallet-info-content .address-copy .wrap .copy-wrap .address-arrow-wrap .address-arrow {
  color: var(--text-color-2);
  font-size: 10px;
  transform: rotate(0deg);
  position: relative;
  top: 0px;
}
.index-container .wallet-info-content .address-copy .wrap .copy-wrap .address-arrow-wrap .address-arrow.open {
  transform: rotate(90deg);
}
.index-container .wallet-info-content .account-info-Nx9 {
  padding-bottom: 30px;
  height: 92px;
}
.index-container .wallet-info-content .account-info-Nx9 .row1 {
  font-size: 40px;
  color: var(--text-color-1);
  font-weight: 900;
  line-height: 1;
}
.index-container .wallet-info-content .account-info-Nx9 .row2 {
  font-size: 14px;
  color: var(--text-color-2);
}
.index-container .wallet-info-content .operate-content {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-between;
  max-width: 360px;
  align-items: flex-start;
}
@media screen and (max-width: 360px) {
  .index-container .wallet-info-content .operate-content {
    max-width: 90%;
    width: 90%;
  }
}
.index-container .wallet-info-content .operate-content .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 62px;
}
.index-container .wallet-info-content .operate-content .item .wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index-container .wallet-info-content .operate-content .item.disabled {
  opacity: 0.5;
}
.index-container .wallet-info-content .operate-content .item .icon-button-default {
  margin: auto;
}
.index-container .wallet-info-content .operate-content .item .micon {
  font-size: 40px;
}
.index-container .wallet-info-content .operate-content .item .name {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-1);
  line-height: 1.3;
  margin-top: 10px;
  text-align: center;
}
.index-container .list-content .ant-tabs-nav {
  padding: 0 18px;
  position: sticky;
  top: var(--header-height);
  left: 0;
  background: var(--content-background-color);
  z-index: 2;
  padding-bottom: 4px;
  margin: 0;
  margin-bottom: 10px;
}
.index-container .list-content .ant-tabs-nav:before {
  content: '';
  height: 14px;
  bottom: -14px;
  position: absolute;
  width: 100%;
  left: 0;
  background: var(--list-transition-color-1);
  border: none;
}
.index-container .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  background: none;
}
.index-container .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-ink-bar:after {
  content: '';
  position: absolute;
  width: 22px;
  height: 3px;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
}
.index-container .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  color: var(--text-color-2);
}
.index-container .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn:focus,
.index-container .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-remove:focus,
.index-container .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn:active,
.index-container .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-remove:active {
  background-color: none;
  color: var(--text-color-2);
}
.index-container .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--text-color-1);
}
@media screen and (max-width: 500px) {
  .index-container .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 14px;
  }
}
.index-container .list-content .ant-tabs-nav .ant-tabs-extra-content .token-add-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  right: -3px;
}
.index-container .list-content .ant-tabs-nav .ant-tabs-extra-content .token-add-btn .icon {
  width: 20px;
  height: 20px;
}
.index-container .list-content .skeleton-token-list {
  padding: 0 28px;
}
.index-container .list-content .skeleton-token-list > div {
  margin-bottom: 4px;
}
.index-container .list-content .skeleton-token-list .ant-skeleton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  margin: 0;
}
.index-container .list-content .skeleton-token-list .ant-skeleton-title {
  margin-top: 2px;
  display: none;
}
.index-container .list-content .skeleton-token-list .ant-skeleton-avatar-circle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
}
.index-container .list-content .skeleton-token-list .ant-skeleton-paragraph {
  margin: 0;
  height: 60px;
  position: relative;
}
.index-container .list-content .skeleton-token-list .ant-skeleton-paragraph li {
  position: absolute;
  margin: 0;
}
.index-container .list-content .skeleton-token-list .ant-skeleton-paragraph li:nth-child(1) {
  width: 54px;
  height: 16px;
  border-radius: 4px;
  top: 12px;
}
@media screen and (min-width: 600px) {
  .index-container .list-content .skeleton-token-list .ant-skeleton-paragraph li:nth-child(1) {
    width: 160px;
  }
}
.index-container .list-content .skeleton-token-list .ant-skeleton-paragraph li:nth-child(2) {
  width: 100px;
  height: 16px;
  border-radius: 4px;
  top: 32px;
}
@media screen and (min-width: 600px) {
  .index-container .list-content .skeleton-token-list .ant-skeleton-paragraph li:nth-child(2) {
    width: 100%;
  }
}
.index-container .list-content .skeleton-token-list .ant-skeleton-paragraph li:nth-child(3) {
  width: 70px;
  height: 16px;
  border-radius: 4px;
  right: 0;
  top: 12px;
}
@media screen and (min-width: 600px) {
  .index-container .list-content .skeleton-token-list .ant-skeleton-paragraph li:nth-child(3) {
    display: none;
  }
}
.index-container .list-content .skeleton-token-list .ant-skeleton-paragraph li:nth-child(4) {
  width: 64px;
  height: 12px;
  border-radius: 4px;
  right: 0;
  top: 33px;
}
@media screen and (min-width: 600px) {
  .index-container .list-content .skeleton-token-list .ant-skeleton-paragraph li:nth-child(4) {
    display: none;
  }
}
.index-container .list-content .ant-tabs-content.ant-tabs-content-top {
  outline: 3px solid var(--content-background-color);
}
.index-container .list-content .token-list {
  min-height: 1px;
}
.index-container .list-content .token-list .wrap {
  height: auto !important;
}
.index-container .list-content .token-list .token-item {
  height: 62px;
  line-height: 62px;
  margin: 0 var(--text-distance-1);
  margin-bottom: 10px;
  padding: 0 var(--text-distance-2);
  display: flex;
  justify-content: space-between;
  position: relative;
  border-radius: 18px;
  cursor: pointer;
  background-color: var(--background-color-1);
}
.index-container .list-content .token-list .token-item:after {
  content: '';
  width: 100%;
  border-bottom: 1px solid var(--border-color-1);
  bottom: 0;
  right: 0;
  position: absolute;
  width: calc(100% - 18px - 30px - 8px);
  display: none;
}
.index-container .list-content .token-list .token-item .left {
  display: flex;
  align-items: center;
}
.index-container .list-content .token-list .token-item .left .token-icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  border-radius: 30px;
  overflow: hidden;
  object-fit: cover;
}
.index-container .list-content .token-list .token-item .left .wrap-name .name {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  line-height: 1;
  color: var(--text-color-1);
  margin-top: 4px;
}
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row2,
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row3 {
  display: flex;
  align-items: center;
  line-height: 1;
  margin-top: 4px;
  height: 20px;
}
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row2 .unit-price,
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row3 .unit-price {
  color: var(--text-color-2);
  font-size: 12px;
  margin-right: 10px;
}
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row2 .riseee,
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row3 .riseee {
  line-height: 20px;
  font-size: 12px;
  height: 20px;
  border-radius: 16px;
  color: var(--message-success-color);
  padding: 0 8px;
  transform: scale(0.84);
  left: -4px;
  position: relative;
  overflow: hidden;
}
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row2 .riseee::after,
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row3 .riseee::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: var(--message-success-color);
  opacity: 0.1;
}
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row2 .riseee.negative,
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row3 .riseee.negative {
  color: var(--message-error-color);
  background: rgba(234, 67, 53, 0.1);
}
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row2 .riseee.negative::after,
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row3 .riseee.negative::after {
  content: '';
  background: var(--message-error-color);
}
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row3 {
  height: 18px;
}
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row4 {
  height: 18px;
  display: flex;
  margin-top: 4px;
}
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row4 .icon {
  height: 18px;
  width: 11px;
  line-height: 1;
}
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row4 .icon .ant-image {
  width: 18px;
  height: 18px;
  border: 2px solid var(--content-background-color);
  border-radius: 100%;
}
.index-container .list-content .token-list .token-item .left .wrap-name .item-left-row4 .icon .ant-image .token-icon {
  width: 100%;
  height: 100%;
  border-radius: 100%;
}
.index-container .list-content .token-list .token-item .right {
  line-height: 1.2;
  text-align: right;
  display: flex;
  align-items: center;
}
.index-container .list-content .token-list .token-item .right .wrap .p1 {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--text-color-1);
}
.index-container .list-content .token-list .token-item .right .wrap .p2,
.index-container .list-content .token-list .token-item .right .wrap .p3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  height: 12px;
  color: var(--text-color-2);
  margin-top: 4px;
}
@media screen and (max-width: 600px) {
  .index-container .list-content .token-list .token-item .right .wrap .right-arrow {
    display: none;
  }
}
@media screen and (min-width: 600px) {
  .index-container {
    background: #f2f4f6;
    width: 100vw;
    height: 100vh;
    position: relative;
  }
  .index-container .wrapper .pc-logo {
    position: absolute;
    width: 143px;
    height: 31px;
    display: block;
    left: 0%;
    top: calc(-1vh - 31px);
  }
  .index-container .wrapper .index-header {
    position: sticky;
    height: 66px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .index-container .wrapper .index-header[data-theme='dark'] {
    border-bottom-color: rgba(0, 0, 0, 0);
  }
  .index-container .wrapper .index-header .right {
    padding-right: 0px;
  }
  .index-container .wrapper .main-content {
    padding-top: 0px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 70px);
  }
  .index-container .wrapper .main-content .list-content {
    flex: 1;
    overflow: auto;
    height: 0;
  }
  .index-container .wrapper .operate-content {
    max-width: 400px;
  }
  .index-container .wrapper .wallet-info-content {
    padding-top: 20px;
  }
  .index-container .wrapper .import-token-btn {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--content-background-color);
    display: none;
  }
  .index-container .wrapper .import-token-btn button {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: var(--accent-color);
  }
  .index-container .wrapper .import-token-btn button .icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
    position: relative;
  }
  .index-container .wrapper .import-token-btn button .icon .micon {
    position: absolute;
    top: 0;
    left: 0;
  }
  .index-container .wrapper .list-content {
    padding: 0;
    border-top: none;
    overflow: hidden;
  }
  .index-container .wrapper .list-content .ant-tabs {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .index-container .wrapper .list-content .ant-tabs[data-theme='dark'] .ant-tabs-nav:after {
    border-bottom: none;
  }
  .index-container .wrapper .list-content .ant-tabs-content-holder {
    height: 0;
    flex: 1;
    overflow: auto;
    padding-top: 14px;
  }
  .index-container .wrapper .list-content .ant-tabs-nav {
    padding: 0 var(--text-distance-1);
    top: 0px;
    margin: 0;
    position: relative;
  }
  .index-container .wrapper .list-content .ant-tabs-nav:after {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    border-bottom: 2px solid var(--border-color-0);
    left: 0;
    top: 0;
    z-index: -1;
    display: none;
  }
  .index-container .wrapper .list-content .ant-tabs-nav .ant-tabs-ink-bar {
    top: 0;
    display: none;
  }
  .index-container .wrapper .list-content .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--text-color-1) !important;
  }
  .index-container .wrapper .list-content .ant-tabs-nav .ant-tabs-ink-bar:after {
    display: none;
  }
  .index-container .wrapper .list-content .ant-tabs-nav .ant-tabs-nav-wrap {
    width: 100%;
  }
  .index-container .wrapper .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
    width: 100%;
  }
  .index-container .wrapper .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    width: 100%;
    text-align: center;
    line-height: 1.6;
  }
  .index-container .wrapper .list-content .right {
    position: relative;
  }
  .index-container .wrapper .list-content .right .wrap {
    padding-right: 0px;
  }
  .index-container .wrapper .list-content .right .wrap .right-arrow {
    position: absolute;
    right: -8px;
    top: 19px;
  }
}
.address-list-popover {
  width: 240px;
  left: 50% !important;
  transform: translate(-50%, 0px) !important;
}
.address-list-popover .ant-popover-content {
  opacity: 0 !important;
}
.address-list-popover .ant-popover-content.show1 {
  opacity: 1 !important;
}
.address-list-popover .ant-popover-inner {
  background-color: var(--bg-color-4);
  border-radius: var(--border-radius-1);
}
.address-list-popover .ant-popover-arrow {
  left: 177px !important;
}
.address-list-popover .ant-popover-arrow .ant-popover-arrow-content::before {
  background: var(--bg-color-4);
}
.address-list-popover .address-list .item {
  color: var(--text-color-1);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  line-height: 30px;
}
.address-list-popover .address-list .item .right {
  display: flex;
  align-items: center;
  gap: 4px;
}
.drawer-receive-container .ant-drawer-content {
  overflow: hidden;
  background-color: var(--bg-color-4);
}
.drawer-receive-container .ant-drawer-content .ant-drawer-body {
  padding: 24px;
  padding-bottom: 0;
}
.drawer-receive-container .ant-drawer-content .ant-drawer-header {
  border: none;
  background-color: var(--bg-color-4);
}
.drawer-receive-container .ant-drawer-content .ant-drawer-header .ant-drawer-close {
  display: none;
}
.drawer-receive-container .ant-drawer-content .ant-drawer-header .ant-drawer-title {
  text-align: center;
  font-size: 20px;
  line-height: 1;
  color: var(--text-color-1);
}
.drawer-receive-container .ant-drawer-content .ant-drawer-extra {
  font-size: 18px;
  color: var(--text-color-1);
}
.drawer-receive-container .content {
  text-align: center;
}
.drawer-receive-container .content .tips-Nx9 {
  font-size: 16px;
  line-height: 19px;
  color: var(--message-error-color);
  margin-bottom: 22px;
}
.drawer-receive-container .content .qr-code {
  margin: auto;
  margin-bottom: 22px;
  width: 230px;
  height: 230px;
  border: 1px solid var(--button-color-1);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawer-receive-container .content .qr-code .icon,
.drawer-receive-container .content .qr-code .canvas {
  width: 200px !important;
  height: 200px !important;
}
.drawer-receive-container .content .address {
  width: 230px;
  padding: 8px 0px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-color-2);
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 22px;
}
.drawer-receive-container .content .address span {
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre-wrap;
}
.drawer-receive-container .content .address .micon {
  display: none;
}
.drawer-receive-container .content .copy-address button {
  width: 100%;
}
.drawer-receive-container .footer {
  padding-bottom: calc(constant(safe-area-inset-bottom) / 3);
  padding-bottom: calc(env(safe-area-inset-bottom) / 3);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .drawer-receive-container .footer {
    display: none;
  }
}
.drawer-receive-container .footer .logo {
  width: 171px;
  height: 17px;
  background-image: var(--footer-icon-src);
  background-size: 100% 100%;
}
.drawer-receive-container .content-wallet {
  margin: -24px -24px 0;
  padding-bottom: 94px;
  background-color: var(--content-background-color);
}
.drawer-receive-container .content-wallet .wallet-line {
  position: absolute;
  width: 100%;
  border-top: 2px solid var(--border-color-0);
  z-index: 10;
}
.drawer-receive-container .content-wallet .list .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
  padding: 0 28px;
  border-bottom: 2px solid var(--border-color-1);
  background: var(--content-background-color);
}
.drawer-receive-container .content-wallet .list .item .left {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: var(--text-color-1);
}
.drawer-receive-container .content-wallet .list .item .left .left-img {
  margin-right: 16px;
  width: 36px;
  height: 36px;
}
.drawer-receive-container .content-wallet .list .item .right {
  width: 14px;
  height: 14px;
}
.drawer-receive-container .content-wallet .list .item .right .icon {
  width: 100%;
  height: 100%;
  background-image: var(--icon-src-8);
  background-size: 100% 100%;
}
.drawer-receive-container .footer-switch {
  border-top: 2px solid var(--border-color-1);
  padding: 20px 27px 0;
  left: 0;
  width: 100%;
  font-size: 15px;
  font-weight: 500;
  color: var(--text-color-1);
  position: absolute;
  bottom: 0;
  z-index: 10;
  background-color: var(--bg-color-4);
}
.drawer-receive-container .footer-switch .footer-son {
  display: flex;
  justify-content: space-between;
}
.receive-model * {
  color: #000;
}
.receive-model .ant-modal {
  width: 375px !important;
}
.receive-model .ant-modal-title {
  text-align: center;
}
.receive-model .ant-modal-header {
  background: #fff;
  border-color: #fff;
}
.receive-model .ant-modal-content {
  background: #fff;
  border-radius: 13px;
  overflow: hidden;
  text-align: center;
}
.receive-model .ant-modal-content .ant-modal-body {
  padding-top: 10px;
}
.receive-model .ant-modal-content .ant-modal-body .content .tips-Nx9 {
  color: #ff4444;
  margin-bottom: 20px;
}
.receive-model .ant-modal-content .ant-modal-body .content .qr-code svg {
  width: 148px !important;
  height: 148px !important;
}
.receive-model .ant-modal-content .ant-modal-body .content .address {
  margin: auto;
  line-height: 1.1;
  margin-top: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: var(--background-color-1);
  border-radius: 10px;
  height: 50px;
  padding: 0 14px;
  width: 80%;
  position: relative;
  overflow: hidden;
}
.receive-model .ant-modal-content .ant-modal-body .content .address::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--address-icon-color);
  opacity: 0.06;
  pointer-events: none;
}
.receive-model .ant-modal-content .ant-modal-body .content .address span {
  width: 210px;
  color: var(--text-color-2);
}
.receive-model .ant-modal-content .ant-modal-body .content .copy-address {
  display: none;
}
.receive-model .ant-modal-footer {
  display: none;
}
.theme-dark .receive-model * {
  color: #fff;
}
.theme-dark .receive-model .ant-modal-header {
  background: var(--bg-color-4);
  border-color: #212324;
}
.theme-dark .ant-modal-content {
  background: var(--bg-color-4);
}
.theme-dark .ant-modal-content .ant-modal-header {
  background: var(--bg-color-4);
}
.theme-dark .ant-modal-content .ant-modal-header .ant-modal-title {
  color: #fff;
}
.theme-dark .ant-modal-content .ant-modal-close {
  color: #fff;
}
.theme-dark .ant-modal-content .ant-modal-body .content .address span {
  color: var(--text-color-2);
}
:root .switch-chain-container > .name {
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  color: var(--text-color-1);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
:root .switch-chain-container > .name .aa-tag {
  height: 17px;
  border-radius: 9px;
  color: var(--text-color-1);
  font-size: 11px;
  font-weight: bold;
  line-height: 19px;
  margin-right: 4px;
  padding: 0 4px;
}
:root .switch-chain-container[data-source='swap'] > .name {
  top: 6px;
  display: flex;
  align-items: center;
}
:root .switch-chain-container[data-source='swap'] > .name .aa-tag {
  background: none;
  margin: 0;
  position: relative;
  top: -2px;
}
:root .switch-chain-container[data-source='swap'] .pa-pc-network,
:root .switch-chain-container[data-source='swap'] .m-network {
  position: relative;
  top: 4px;
}
:root .switch-chain-container[data-source='swap'] .ant-btn {
  background: none;
  border: none;
  box-shadow: none;
}
:root .switch-chain-container[data-source='swap'] .ant-btn .micon {
  transform: rotate(0deg);
  position: relative;
  top: 2px;
}
:root .switch-chain-container[data-source='swap'] .ant-btn:after {
  display: none;
  border: none;
}
:root .switch-chain-container[data-source='swap'] .m-network:after {
  display: none;
  border: none;
}
:root .switch-chain-container[data-source='swap'] .m-network .select-arrow {
  transform: rotate(0deg);
  position: relative;
  top: 0px;
}
:root .switch-chain-container .pa-pc-network {
  display: none;
}
:root .switch-chain-container .m-network {
  font-size: 12px;
  font-weight: 500;
  height: 30px;
  border-radius: 30px;
  padding: 0 10px;
  color: var(--text-color-1);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
:root .switch-chain-container .m-network:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--address-icon-color);
  opacity: 0.15;
  border-radius: 30px;
}
:root .switch-chain-container .m-network span {
  position: relative;
  top: 0px;
}
:root .switch-chain-container .m-network .middle-type-icon {
  width: 15px;
  height: 15px;
  margin-right: 4px;
}
:root .switch-chain-container .m-network .select-arrow {
  width: 15px;
  height: 15px;
  background-image: var(--icon-src-7);
  background-size: 100% 100%;
  transform: rotate(-90deg);
  position: relative;
  right: -3px;
}
:root .switch-chain-container .skeleton-wrap .ant-skeleton-input {
  min-width: 20px;
  width: 60px;
  height: 14px;
  position: relative;
  top: 2px;
}
@media screen and (min-width: 600px) {
  :root .switch-chain-container .type {
    display: none;
  }
  :root .switch-chain-container .pa-pc-network {
    line-height: 1;
    display: block;
  }
  :root .switch-chain-container .pa-pc-network .skeleton-wrap .ant-skeleton-input {
    min-width: 20px;
    width: 60px;
    height: 14px;
    top: 0px;
  }
  :root .switch-chain-container .pa-pc-network .icon1 {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    opacity: 1;
    margin-right: 6px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }
  :root .switch-chain-container .pa-pc-network .icon1 .chain-icon {
    width: 100%;
    height: 100%;
  }
  :root .switch-chain-container .pa-pc-network .icon1 + span {
    line-height: 1;
  }
  :root .switch-chain-container .pa-pc-network .icon3 {
    width: 5px;
    height: 100%;
  }
  :root .switch-chain-container .pa-pc-network button {
    height: 30px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding: 0 12px;
    padding-right: 6px;
    font-size: 12px;
    font-weight: 500;
    color: var(--text-color-1);
    overflow: hidden;
    position: relative;
    background: none !important;
    left: 0px;
  }
  :root .switch-chain-container .pa-pc-network button:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--address-icon-color);
    opacity: 0.15;
    pointer-events: none;
    box-sizing: initial;
    animation: initial;
  }
  :root .switch-chain-container .pa-pc-network .icon2 {
    margin-left: 2px;
    position: relative;
    top: 0px;
    transform: rotate(-90deg);
  }
}
:root .swaitch-chain-modal .ant-modal-body {
  padding: 0;
  z-index: 4;
  width: 100%;
  padding-top: 0;
}
:root .swaitch-chain-modal .list {
  padding: 14px 0px;
  max-height: 400px;
  min-height: 100px;
  overflow: auto;
}
:root .swaitch-chain-modal .list .item {
  padding: 0 30px;
  line-height: 50px;
  font-size: 15px;
  font-weight: 400;
  color: var(--text-color-1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 8px;
}
:root .swaitch-chain-modal .list .item.no-data {
  cursor: default !important;
  justify-content: center !important;
  border-bottom: none !important;
  background: none;
}
:root .swaitch-chain-modal .list .item.no-data .name {
  position: relative;
  color: var(--text-color-2) !important;
  top: 6px;
}
:root .swaitch-chain-modal .list .item .left {
  display: flex;
  align-items: center;
}
:root .swaitch-chain-modal .list .item .left .left-img {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}
:root .swaitch-chain-modal .list .item .right .right-icon {
  width: 18px;
  height: 18px;
  position: relative;
}
:root .swaitch-chain-modal .list .item .right .right-icon > span {
  position: absolute;
  top: 0;
  left: 0;
}
:root .swaitch-chain-modal .list .item .icon {
  width: 11px;
  height: 11px;
  background: #59b3ae;
  border-radius: 50%;
  opacity: 1;
  margin-right: 13px;
}
:root .swaitch-chain-modal .footer {
  padding: 17px 30px;
  line-height: 30px;
  font-size: 15px;
  font-weight: 400;
  color: var(--text-color-1);
}
:root .swaitch-chain-modal .footer .switch-content {
  display: flex;
  justify-content: space-between;
}
:root .swaitch-chain-modal .footer span {
  margin-right: 10px;
}
:root .search-input-content {
  padding: 0 30px !important;
  padding-bottom: 4px !important;
  color: var(--text-color-1);
  background: none;
  margin-top: 20px;
}
@media screen and (max-width: 600px) {
  :root .search-input-content {
    display: none;
  }
}
:root .search-input-content .ant-input-affix-wrapper {
  background: var(--input-bg-color);
  border: 1px solid var(--input-bg-color);
  box-shadow: none;
  position: relative;
  top: -4px;
  border-radius: 38px;
  box-shadow: none !important;
}
:root .search-input-content input {
  height: 32px;
}
:root .search-input-content input:focus,
:root .search-input-content input:hover {
  box-shadow: none !important;
  border: none !important;
}
:root .ant-drawer-content-wrapper {
  height: auto !important;
}
:root .ant-drawer-content-wrapper .content-wallet {
  min-height: 100px;
  max-height: 60vh;
}
:root .ant-drawer-content-wrapper .content-wallet .list {
  padding-bottom: 94px;
  border-top: 2px solid var(--border-color-1);
  background-color: var(--content-background-color);
}
.nft-list-container .no-record-container {
  padding-top: 6vh;
}
@media screen and (max-height: 800px) {
  .nft-list-container .no-record-container {
    padding: 2vh;
  }
}
.nft-list-container .nft-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 92%;
  margin: auto;
  padding-bottom: 0px;
}
@media screen and (min-width: 600px) {
  .nft-list-container .nft-list {
    width: 95%;
    justify-content: flex-start;
    display: grid;
    grid-template-columns: repeat(auto-fill, 165px);
    grid-gap: 22px 20px;
    justify-content: center;
    padding-bottom: 20px;
  }
}
.nft-list-container .nft-list .item {
  width: 48%;
  margin-bottom: 16px;
}
@media screen and (min-width: 600px) {
  .nft-list-container .nft-list .item {
    width: 100%;
    margin-bottom: 0;
  }
}
.nft-list-container .nft-list .item .img-content {
  background: var(--background-color-1);
}
.nft-list-container .nft-list .item .info-content .name {
  text-align: center;
  padding-top: 8px;
  padding-bottom: 6px;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color-1);
  line-height: 1.2;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding: 0;
  margin-top: 8px;
}
.img-content {
  position: relative;
  border-radius: 18px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.img-content .nft-img-1 {
  border-radius: 10px;
}
.img-content .img {
  width: 100%;
  padding-top: 100%;
}
.img-content .img .ant-image {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-content .img .ant-image[data-type='horizontal'] img {
  width: 100%;
  height: auto;
}
.img-content .img .ant-image[data-type='vertical'] img {
  width: auto;
  height: 100%;
}
.img-content .img .ant-image img {
  border-radius: 18px;
  margin: auto;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 600px) {
  .img-content .img .ant-image img {
    width: auto;
  }
}
.img-content .error-text {
  position: absolute;
  bottom: 50px;
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color-2);
}
.img-content .icon-content {
  position: absolute;
  top: 4px;
  right: 4px;
}
.img-content .icon-content [name='nft_icon'] {
  color: var(--accent-color, #ca40cc);
}
.token-detail-container > .wrapper {
  background-color: var(--content-background-color) !important;
  min-height: 100%;
}
@media screen and (min-width: 600px) {
  .token-detail-container > .wrapper {
    min-height: initial;
  }
}
.token-detail-container > .wrapper[data-page-mode='simple'] {
  --header-height: 64px;
}
@media screen and (max-width: 600px) {
  .token-detail-container > .wrapper .header-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.token-detail-container .action-bar {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.token-detail-container .action-bar svg {
  color: var(--text-color-1);
}
.token-detail-container .header-title {
  display: flex;
  align-items: center;
  position: relative;
}
.token-detail-container .header-title .icon {
  width: 20px;
  height: auto;
  max-height: 30px;
  margin-right: 4px;
  border-radius: 100%;
  overflow: hidden;
}
.token-detail-container .main-content.scroll-content-1 {
  padding-bottom: 0;
}
.token-detail-container .main-content .wallet-info-content {
  text-align: center;
  padding-bottom: 20px;
  padding-top: 30px;
  background-color: var(--content-background-color);
}
.token-detail-container .main-content .wallet-info-content .address-copy {
  padding-bottom: 28px;
  display: flex;
  justify-content: center;
}
.token-detail-container .main-content .wallet-info-content .address-copy .wrap {
  padding-top: 3px;
  width: 138px;
  height: 24px;
  background: var(--background-color-1);
  line-height: 1;
  opacity: 1;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  color: var(--text-color-2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.token-detail-container .main-content .wallet-info-content .address-copy .wrap .copy-icon {
  display: flex;
  width: 14px;
  height: 14px;
  margin-left: 6px;
  position: relative;
  top: -1px;
  cursor: pointer;
}
.token-detail-container .main-content .wallet-info-content .address-copy .wrap .copy-icon svg {
  color: var(--text-color-2);
}
.token-detail-container .main-content .wallet-info-content .account-info-Nx9 {
  padding-bottom: 30px;
}
.token-detail-container .main-content .wallet-info-content .account-info-Nx9 .row1 {
  font-size: 40px;
  color: var(--text-color-1);
  font-weight: 900;
  line-height: 1;
  margin-bottom: 8px;
}
.token-detail-container .main-content .wallet-info-content .account-info-Nx9 .row2 {
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  color: var(--text-color-2);
}
.token-detail-container .main-content .wallet-info-content .operate-content {
  display: flex;
  margin: auto;
  width: 90%;
  justify-content: space-between;
  max-width: 360px;
  align-items: flex-start;
}
@media screen and (min-width: 600px) {
  .token-detail-container .main-content .wallet-info-content .operate-content {
    max-width: 400px;
  }
}
@media screen and (max-width: 360px) {
  .token-detail-container .main-content .wallet-info-content .operate-content {
    max-width: 90%;
    width: 90%;
  }
}
.token-detail-container .main-content .wallet-info-content .operate-content .item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 70px;
}
.token-detail-container .main-content .wallet-info-content .operate-content .item.disabled {
  opacity: 0.5;
}
.token-detail-container .main-content .wallet-info-content .operate-content .item .wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.token-detail-container .main-content .wallet-info-content .operate-content .item .icon-button-default {
  margin: auto;
}
.token-detail-container .main-content .wallet-info-content .operate-content .item .micon {
  font-size: 40px;
}
.token-detail-container .main-content .wallet-info-content .operate-content .item .name {
  font-size: 15px;
  font-weight: 600;
  color: var(--text-color-1);
  line-height: 1.3;
  margin-top: 10px;
  text-align: center;
}
@media screen and (min-width: 600px) {
  .token-detail-container .main-content .list-content {
    border-top: none;
    padding-top: 0;
  }
}
.token-detail-container .main-content .list-content .ant-spin-dot {
  background-image: var(--icon-src-16);
  background-size: cover;
  width: 60px;
  height: 60px;
  top: 40%;
  animation: none;
}
.token-detail-container .main-content .list-content .ant-spin-dot i {
  display: none;
}
.token-detail-container .main-content .list-content .page-mode-simple .ant-tabs-nav {
  display: none;
}
.token-detail-container .main-content .list-content .page-mode-simple .lds-default {
  margin: 0;
}
.token-detail-container .main-content .list-content .ant-tabs-nav {
  padding: 0 18px;
  position: sticky;
  top: var(--header-height);
  left: 0;
  background: #fff;
  z-index: 2;
  padding-bottom: 4px;
  width: 100%;
  background: var(--content-background-color);
  top: -1px;
}
.token-detail-container .main-content .list-content .ant-tabs-nav:after {
  display: none;
  content: '';
  width: 100%;
  height: 1px;
  position: absolute;
  border-bottom: 2px solid var(--border-color-1);
  left: 0;
  top: 0px;
  z-index: -1;
}
@media screen and (min-width: 600px) {
  .token-detail-container .main-content .list-content .ant-tabs-nav {
    padding: 0 28px;
  }
}
.token-detail-container .main-content .list-content .ant-tabs-nav:before {
  display: none;
}
.token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-nav-wrap {
  width: 100%;
}
.token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-ink-bar {
  display: none;
  background: none;
}
@media screen and (min-width: 600px) {
  .token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-ink-bar {
    top: 0;
  }
}
.token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-ink-bar:after {
  content: '';
  position: absolute;
  width: 22px;
  height: 3px;
  bottom: 0;
  left: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 100%;
}
@media screen and (min-width: 600px) {
  .token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-ink-bar:after {
    width: 100%;
  }
}
.token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
  width: 100%;
}
.token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  color: var(--text-color-2);
  text-align: center;
  display: flex;
  justify-content: center;
}
.token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn:focus,
.token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-remove:focus,
.token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn:active,
.token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-remove:active {
  background-color: none;
  color: var(--text-color-2);
}
.token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--text-color-1) !important;
}
.token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-extra-content .token-add-btn {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.token-detail-container .main-content .list-content .ant-tabs-nav .ant-tabs-extra-content .token-add-btn .icon {
  width: 20px;
  height: 20px;
}
.token-detail-container .page-loading {
  display: none;
}
.token-detail-container .page-loading.ant-spin-spinning {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.ant-drawer-content {
  background-color: var(--bg-color-4);
}
.drawer-token {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.drawer-token .drawer-token-item {
  width: 100%;
  height: 60px;
  background: var(--bg-color-6);
  border-radius: 18px;
  opacity: 1;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--text-color-1);
  padding: 0 15px;
  box-sizing: border-box;
  cursor: pointer;
}
.drawer-token .drawer-token-item .drawer-token-icon {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  margin-right: 15px;
}
.drawer-token .footer-logo {
  width: 171px;
  height: 17px;
  background-image: var(--footer-icon-src);
  background-size: 100% 100%;
}
.hide-token-popover {
  left: auto !important;
  right: 0 !important;
}
.hide-token-popover .ant-popover-arrow {
  right: 24px;
}
.hide-token-popover .ant-popover-arrow-content {
  --antd-arrow-background-color: var(--background-color-1);
}
.hide-token-popover .ant-popover-inner {
  background: var(--bg-color-4);
  border-radius: 18px;
}
.hide-token-popover .footer-logo {
  display: none;
}
.hide-token-popover .drawer-token .drawer-token-item {
  width: 280px;
}
.hide-token-popover .drawer-token .drawer-token-item:nth-child(2) {
  margin-bottom: 0;
}
.token-list-container > .wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--content-background-color) !important;
}
.token-list-container .add-custom-token {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 60px;
  margin: 0 var(--text-distance-1);
  padding: 0 var(--text-distance-2);
  height: 60px;
  color: var(--text-color-1);
  cursor: pointer;
  background: var(--background-color-1);
  border-radius: 18px;
  margin-bottom: 10px;
}
.token-list-container .add-custom-token .icon {
  width: 14px;
  height: 14px;
  background-image: var(--icon-src-9);
  background-size: 100% 100%;
}
.token-list-container .no-record-container {
  padding: 0;
  padding-bottom: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.token-list-container .page-content {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 0px;
}
.token-list-container .page-content .ant-spin-nested-loading {
  height: 0;
  flex: 1;
}
.token-list-container .page-content .ant-spin-nested-loading .ant-spin-container {
  height: 100%;
}
.token-list-container .token-list {
  height: 100%;
  overflow-y: auto;
}
.token-list-container .token-list .wrap .list-titleKey1,
.token-list-container .token-list .wrap .list-titleKey2 {
  padding: 8px 18px;
  color: var(--text-color-2);
  height: 40px;
  display: flex;
  align-items: center;
}
.token-list-container .token-list .wrap .token-item {
  height: 60px;
  padding: 0 var(--text-distance-1);
  display: flex;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  color: var(--text-color-1);
}
.token-list-container .token-list .wrap .token-item:after {
  display: none;
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: calc(100% - 18px - 30px - 6px);
  height: 1px;
  border-bottom: 1px solid var(--border-color-1);
}
.token-list-container .token-list .wrap .token-item .left {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}
.token-list-container .token-list .wrap .token-item .left .token-icon {
  width: 30px;
  height: 30px;
  margin-right: 6px;
  border-radius: 30px;
  overflow: hidden;
  object-fit: cover;
}
.token-list-container .token-list .wrap .token-item .right {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.token-list-container .token-list .wrap .token-item .right .wrap {
  height: 100%;
}
.token-list-container .token-list .wrap .token-item .right .wrap .operate {
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
.token-list-container .token-list .wrap .token-item .right .wrap .operate[data-type='remove'] .micon {
  color: var(--text-color-3);
}
.token-list-container .token-list .wrap .token-item .right .wrap .operate .add-icon {
  width: 20px;
  height: 20px;
  background: red;
}
.send-container {
  background: var(--content-background-color);
  min-height: 520px;
  position: relative;
}
@media screen and (min-width: 600px) {
  .send-container > .wrapper {
    display: flex;
    flex-direction: column;
    min-height: 450px !important;
  }
}
.send-container .title-symbol {
  vertical-align: middle;
}
@media screen and (max-width: 600px) {
  .send-container .title-symbol {
    max-width: 144px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
}
.send-container .send-text {
  vertical-align: middle;
}
.send-container .choose-token {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 18px;
  height: 60px;
  background-color: var(--background-color-1);
  border-radius: 18px;
  padding: 0 14px;
  cursor: pointer;
}
.send-container .choose-token.choose-chain {
  margin: 0;
}
.send-container .choose-token .left .wrap {
  display: flex;
  align-items: center;
}
.send-container .choose-token .left .wrap .icon {
  width: 26px;
  height: 26px;
  border-radius: 100%;
  margin-right: 4px;
  overflow: hidden;
}
.send-container .choose-token .left .wrap span {
  font-size: 20px;
  color: var(--text-color-1);
  line-height: 1;
}
@media screen and (max-width: 600px) {
  .send-container .choose-token .left .wrap span {
    max-width: 210px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.send-container .choose-token .right .micon {
  position: relative;
  right: -2px;
}
.send-container .send-main {
  padding-bottom: 10px;
  background: var(--content-background-color);
}
@media screen and (min-width: 600px) {
  .send-container .send-main {
    height: 0;
    flex: 1;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }
}
.send-container .send-main .form-content {
  padding: 0 var(--text-distance-1);
  padding-top: 14px;
}
@media screen and (min-width: 600px) {
  .send-container .send-main .form-content {
    height: 0;
    flex: 1;
  }
}
@media screen and (min-width: 600px) {
  .send-container .send-main .form-content .ant-form {
    height: 100%;
    position: relative;
  }
}
.send-container .send-main .form-content .ant-form .click-cv {
  position: absolute;
  width: 150px;
  height: 20px;
  margin-top: 5px;
  z-index: 3;
}
.send-container .send-main .form-content .ant-form .amount-input-content .ant-input {
  height: 36px;
  font-size: 18px;
  color: var(--text-color-1);
}
.send-container .send-main .form-content .ant-form .input-content {
  border: 1px solid var(--background-color-1);
  border-radius: 18px;
  overflow: hidden;
  color: var(--text-color-1);
  background-color: var(--background-color-1);
}
.send-container .send-main .form-content .ant-form .input-content:focus,
.send-container .send-main .form-content .ant-form .input-content[data-focus='true'] {
  border-color: var(--accent-color);
}
.send-container .send-main .form-content .ant-form .input-content.to-input-content .wrap {
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: space-between;
}
.send-container .send-main .form-content .ant-form .input-content.to-input-content .wrap .left {
  flex: 1;
  padding-right: 10px;
  max-width: calc(100% - 24px);
  overflow: hidden;
}
.send-container .send-main .form-content .ant-form .input-content.to-input-content .wrap .left .div-input {
  word-wrap: break-word;
  word-break: normal;
  word-break: break-all;
  line-height: 1.1;
}
.send-container .send-main .form-content .ant-form .input-content.to-input-content .wrap .left .div-input:empty:before {
  content: attr(placeholder);
  color: var(--text-color-3);
  font-size: 16px;
}
.send-container .send-main .form-content .ant-form .input-content .suffix-content {
  display: flex;
  align-items: center;
}
.send-container .send-main .form-content .ant-form .input-content .suffix-content .icon1 {
  width: 22px;
  height: 22px;
  cursor: pointer;
  background-image: var(--icon-src-13);
  background-size: 100% 100%;
}
.send-container .send-main .form-content .ant-form .input-content .suffix-content .icon2 {
  width: 22px;
  height: 22px;
}
.send-container .send-main .form-content .ant-form .input-content .usd-content {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-color-2);
  padding-left: 14px;
  padding-bottom: 4px;
}
.send-container .send-main .form-content .ant-form .input-content.fee-input-content,
.send-container .send-main .form-content .ant-form .input-content.total-input-content {
  height: 62px;
  border-color: #cccdce;
  padding: 0 10px;
  font-size: 12px;
  font-weight: normal;
  color: var(--text-color-1);
  display: flex;
  align-items: center;
}
.send-container .send-main .form-content .ant-form .input-content.fee-input-content .wrap,
.send-container .send-main .form-content .ant-form .input-content.total-input-content .wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.send-container .send-main .form-content .ant-form .input-content.fee-input-content .wrap .gray-color,
.send-container .send-main .form-content .ant-form .input-content.total-input-content .wrap .gray-color {
  color: #cccdce;
  margin-left: 4px;
}
.send-container .send-main .form-content .ant-form .input-content.fee-input-content .wrap .right .sub-wrap,
.send-container .send-main .form-content .ant-form .input-content.total-input-content .wrap .right .sub-wrap {
  display: flex;
}
.send-container .send-main .form-content .ant-form .input-content.fee-input-content .wrap .right .sub-wrap .sub-left,
.send-container .send-main .form-content .ant-form .input-content.total-input-content .wrap .right .sub-wrap .sub-left {
  margin-right: 4px;
}
.send-container .send-main .form-content .ant-form .input-content.fee-input-content .wrap .right .sub-wrap .sub-left .row2,
.send-container .send-main .form-content .ant-form .input-content.total-input-content .wrap .right .sub-wrap .sub-left .row2 {
  color: var(--message-success-color);
  font-weight: bold;
}
.send-container .send-main .form-content .ant-form .input-content.fee-input-content .wrap .right .sub-wrap .sub-right,
.send-container .send-main .form-content .ant-form .input-content.total-input-content .wrap .right .sub-wrap .sub-right {
  display: flex;
  align-items: center;
}
.send-container .send-main .form-content .ant-form .input-content.fee-input-content .wrap .right .sub-wrap .sub-right .icon,
.send-container .send-main .form-content .ant-form .input-content.total-input-content .wrap .right .sub-wrap .sub-right .icon {
  width: 17px;
  height: 34px;
}
.send-container .send-main .form-content .ant-form .ant-form-item {
  margin-bottom: 14px;
}
.send-container .send-main .form-content .ant-form .ant-form-item-label {
  padding-bottom: 0;
  width: 100%;
}
.send-container .send-main .form-content .ant-form .ant-form-item-label > label {
  width: 100%;
}
.send-container .send-main .form-content .ant-form .ant-form-item-label > label::after {
  display: none;
}
.send-container .send-main .form-content .ant-form .ant-form-item-label label {
  font-size: 16px;
  color: var(--text-color-1);
}
.send-container .send-main .form-content .ant-form .ant-form-item-label .amount-label-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.send-container .send-main .form-content .ant-form .ant-form-item-label .amount-label-content .tips-Nx9 {
  font-size: 12px;
  font-weight: normal;
  color: var(--pending-color);
  line-height: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 600px) {
  .send-container .send-main .form-content .ant-form .ant-form-item-label .amount-label-content .tips-Nx9 .symbol {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
  }
}
.send-container .send-main .form-content .ant-form .ant-form-item-label .fee-label-content {
  padding-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.send-container .send-main .form-content .ant-form .ant-form-item-label .fee-label-content .refresh-content {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color-2);
}
.send-container .send-main .form-content .ant-form .ant-form-item-label .fee-label-content .fee-value {
  font-size: 16px;
  font-weight: 400;
}
.send-container .send-main .form-content .ant-form .fee-value-son {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-color-2);
  position: absolute;
  right: 0;
  top: 0;
}
.send-container .send-main .form-content .ant-form .ant-form-item-extra {
  font-size: 12px;
  font-weight: 400;
  color: var(--accent-color);
  padding-top: 6px;
  padding-left: 0px;
  cursor: pointer;
}
.send-container .send-main .form-content .ant-form .form-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 var(--text-distance-1);
  z-index: 1;
  text-align: center;
  margin: 0;
}
@media screen and (min-width: 600px) {
  .send-container .send-main .form-content .ant-form .form-footer {
    padding: 0 calc(70px - 24px);
    height: 46px;
    justify-content: space-between;
    align-items: center;
  }
}
.send-container .send-main .form-content .ant-form .form-footer .ant-btn-primary {
  width: 100%;
  max-width: 340px;
}
@media screen and (min-width: 600px) {
  .send-container .send-main .form-content .ant-form .form-footer .ant-btn-primary {
    width: 380px;
    max-width: 380px;
  }
}
@media screen and (max-height: 500px) {
  .default-layout .layout-main > #keep-alive-container > div > div.swap-container {
    height: 760px !important;
    padding-bottom: var(--footer-height);
  }
}
.swap-container > .wrapper {
  background-color: var(--content-background-color) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
}
@media screen and (max-width: 600px) {
  .swap-container > .wrapper[data-device='firefox'] {
    height: calc(100vh - var(--footer-height));
    min-height: calc(100vh - var(--footer-height));
  }
}
@media screen and (max-height: 500px) {
  .swap-container > .wrapper[data-device='firefox'] {
    height: 760px;
    min-height: 760px;
  }
}
@media screen and (min-width: 600px) {
  .swap-container > .wrapper {
    display: flex;
    flex-direction: column;
  }
}
.swap-container .main-content {
  padding-top: 22px;
  height: 0;
  flex: 1;
  overflow: auto;
}
.swap-container .main-content .ant-spin-spinning {
  position: fixed;
}
.swap-container .main-content .ant-spin-spinning .ant-spin-dot {
  top: 50% !important;
}
.swap-container .main-content .details {
  padding: 0 var(--text-distance-1) 30px;
}
@media screen and (min-width: 600px) {
  .swap-container .main-content .details {
    padding: 0 22px;
  }
}
.swap-container .main-content .details .card {
  background: var(--bg-color-4);
  border-radius: 18px;
  margin-bottom: 15px;
  padding: 10px 0px;
  box-shadow: 0px 0px 15px 0px var(--shadow-color-2);
}
.swap-container .main-content .details .card .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
}
.swap-container .main-content .details .card .row .mid-img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  cursor: pointer;
  border: 1px solid var(--border-color-1);
  position: relative;
  min-width: 50px;
}
.swap-container .main-content .details .card .row .mid-img .micon {
  position: absolute;
  top: 4px;
  left: 4px;
}
.swap-container .main-content .details .card .row.swap-mode-title {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 0 !important;
  color: var(--text-color-1);
}
.swap-container .main-content .details .card .row.swap-mode-row {
  display: flex;
  gap: 14px;
  color: var(--text-color-2);
}
.swap-container .main-content .details .card .row.swap-mode-row .item {
  color: var(--text-color-1);
  flex: 1;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background-color: var(--input-bg-color);
  font-weight: bold;
}
.swap-container .main-content .details .card .row.swap-mode-row .item[data-active='true'] {
  border: none;
  color: var(--accent-color);
  position: relative;
  overflow: hidden;
}
.swap-container .main-content .details .card .row.swap-mode-row .item[data-active='true']:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  pointer-events: none;
  box-sizing: initial;
  animation: initial;
  background-color: var(--accent-color);
}
.swap-container .main-content .details .card .row.swap-mode-row .item[data-disabled='true'] {
  cursor: not-allowed;
  opacity: 0.8;
}
.swap-container .main-content .details .card .row.row-1 {
  align-items: flex-start;
}
.swap-container .main-content .details .card .row.row-mid {
  padding-left: 0;
  padding-right: 0;
}
.swap-container .main-content .details .card .row.row-mid span {
  width: calc(50% - 20px);
  height: 1px;
  background: var(--border-color-1);
}
.swap-container .main-content .details .card .row .ant-form-item {
  margin: 0;
}
.swap-container .main-content .details .card .row .ant-form-item-control-input-content {
  height: 30px;
}
.swap-container .main-content .details .card .row .left {
  font-size: 14px;
  color: var(--text-color-1);
  font-weight: 500;
}
.swap-container .main-content .details .card .row .left input:-webkit-autofill,
.swap-container .main-content .details .card .row .left input:-webkit-autofill:hover,
.swap-container .main-content .details .card .row .left input:-webkit-autofill:focus,
.swap-container .main-content .details .card .row .left textarea:-webkit-autofill,
.swap-container .main-content .details .card .row .left textarea:-webkit-autofill:hover,
.swap-container .main-content .details .card .row .left textarea:-webkit-autofill:focus,
.swap-container .main-content .details .card .row .left select:-webkit-autofill,
.swap-container .main-content .details .card .row .left select:-webkit-autofill:hover,
.swap-container .main-content .details .card .row .left select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--text-color-1);
  -webkit-box-shadow: 0 0 0px 1000px var(--content-background-color) inset;
  transition: background-color 5000s ease-in-out 0s;
}
.swap-container .main-content .details .card .row .left .pay-input {
  border: none;
  font-size: 24px;
  padding: 0;
  width: 80%;
  height: 30px;
}
@media screen and (min-width: 600px) {
  .swap-container .main-content .details .card .row .left .pay-input {
    width: 320px;
  }
}
.swap-container .main-content .details .card .row .left .info-tip {
  background-image: var(--icon-src-17);
  background-size: cover;
  width: 13px;
  height: 13px;
  margin-left: 8px;
  cursor: pointer;
}
.swap-container .main-content .details .card .row .left .left0 {
  color: var(--text-color-2);
}
.swap-container .main-content .details .card .row .left .left1 {
  font-size: 24px;
}
.swap-container .main-content .details .card .row .left.left0 {
  color: var(--text-color-2);
}
.swap-container .main-content .details .card .row .left.left1 {
  font-size: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.swap-container .main-content .details .card .row .left.left1.loading {
  opacity: 0.3;
}
.swap-container .main-content .details .card .row .left.left2 {
  display: flex;
  align-items: center;
}
.swap-container .main-content .details .card .row .right {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color-1);
}
.swap-container .main-content .details .card .row .right.right-max {
  cursor: pointer;
  color: var(--text-color-1);
  text-align: right;
}
.swap-container .main-content .details .card .row .right .right0 {
  color: var(--text-color-2);
}
.swap-container .main-content .details .card .row .right.right-arrow {
  display: flex;
  align-items: center;
}
.swap-container .main-content .details .card .row .right .icon2 {
  width: 16px;
  height: 16px;
  background-image: var(--icon-src-7);
  background-size: cover;
}
.swap-container .main-content .details .card .row .right .chain-box {
  cursor: pointer;
  min-width: fit-content;
  height: 30px;
  background: var(--input-bg-color);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4px;
  color: var(--text-color-1);
}
.swap-container .main-content .details .card .row .right .chain-box .select-token {
  color: var(--text-color-2);
}
.swap-container .main-content .details .card .row .right .chain-box .default-icon,
.swap-container .main-content .details .card .row .right .chain-box .chain-icon {
  width: 22px;
  height: 22px;
  border-radius: 22px;
  overflow: hidden;
  margin-right: 3px;
  min-width: 22px;
  min-height: 22px;
}
.swap-container .main-content .details .card .row .right .chain-box .default-icon .micon {
  color: var(--accent-color);
}
.swap-container .main-content .details .card .row .right .chain-box .icon2 {
  margin-left: 3px;
}
.swap-container .main-content .details .card .row.receive-row {
  height: 56px;
}
.swap-container .main-content .details .card .row.receive-row .ant-skeleton-button {
  width: 80px;
  min-width: 80px;
}
.swap-container .main-content .details .card .row.receive-row .right {
  width: 60%;
  text-align: right;
  padding-top: 2px;
}
.swap-container .main-content .details .card .row.receive-row .right > div {
  display: inline-block;
}
.swap-container .main-content .details .card .row.receive-row .right .chain-box {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 30px;
  height: auto;
}
.swap-container .main-content .details .card .row.receive-row .right .chain-box .chain-icon {
  min-width: 22px;
  min-height: 22px;
}
.swap-container .main-content .details .card .row.receive-row .right .chain-box .label {
  white-space: pre-wrap;
  word-break: break-all;
  word-wrap: break-word;
  line-height: 1.2;
  text-align: center;
}
.swap-container .main-content .details .card .row.receive-row .right .chain-box .icon2 {
  min-width: 16px;
  min-height: 16px;
}
.swap-container .main-content .details .card.card1 .row {
  padding: 7px 16px;
}
.swap-container .main-content .details .card.card1 .row .ant-skeleton-button {
  height: 22px;
  width: 80px;
  min-width: 80px;
}
.swap-container .main-content .details .card .icon {
  width: 10px;
  height: 10px;
  margin-left: 8px;
  background-image: var(--icon-src-9);
  background-size: 100% 100%;
}
.swap-container .content-footer {
  z-index: 1;
  width: 100%;
  text-align: center;
  position: initial;
  bottom: 50px;
  background-color: var(--content-background-color);
}
@media screen and (min-width: 600px) {
}
.swap-container .content-footer .confirm-time {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  width: 100%;
  justify-content: center;
  color: var(--text-color-1);
}
.swap-container .content-footer .confirm-time .limit-time {
  background-image: var(--icon-src-19);
  background-size: cover;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.swap-container .content-footer .swap-btn {
  width: 340px;
  font-size: 17px;
  font-weight: 600;
  transition: none;
}
@media screen and (min-width: 600px) {
  .swap-container .content-footer .swap-btn {
    width: 45%;
  }
}
.swap-container .content-footer .please-add {
  color: #777777;
  padding-bottom: 9px;
}
.swap-container .swap-footer {
  z-index: 2;
  width: 100%;
  text-align: center;
  bottom: 50px;
  background-color: var(--content-background-color);
}
@media screen and (min-width: 600px) {
  .swap-container .swap-footer {
    bottom: 0;
  }
}
.swap-tip-container {
  display: flex;
  flex-direction: column;
  color: var(--text-color-2);
  text-align: center;
}
.swap-tip-container .close-btn {
  font-size: 17px;
  font-weight: 600;
  margin-top: 35px;
}
.swap-tip-container .footer {
  padding-bottom: calc(constant(safe-area-inset-bottom) / 3);
  padding-bottom: calc(env(safe-area-inset-bottom) / 3);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swap-tip-container .footer .logo {
  width: 171px;
  height: 17px;
  background-image: var(--footer-icon-src);
  background-size: 100% 100%;
}
.w-model-container.slip-modal .footer {
  display: none;
}
.slip-modal .ant-modal-content {
  overflow: hidden;
  text-align: center;
}
.slip-modal .ant-modal-content .ant-modal-body {
  padding-top: 18px;
  padding-bottom: 28px;
}
.slip-modal .ant-modal-content .ant-modal-body .slip-content {
  display: flex;
  flex-direction: column;
}
.slip-modal .ant-modal-content .ant-modal-body .slip-content .continue-btn {
  font-size: 17px;
  font-weight: 600;
  margin-top: 35px;
}
.slip-modal .ant-modal-content .ant-modal-body .slip-content .ant-radio-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.slip-modal .ant-modal-content .ant-modal-body .slip-content .ant-radio-group .ant-radio-button-wrapper {
  width: 74px;
  height: 38px;
  color: var(--text-color-2);
  background-color: var(--border-color-1);
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slip-modal .ant-modal-content .ant-modal-body .slip-content .ant-radio-group .ant-radio-button-wrapper:before {
  display: none;
}
.slip-modal .ant-modal-content .ant-modal-body .slip-content .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: var(--text-color-1);
  background: var(--accent-color);
  color: #fff;
}
.slip-modal .ant-modal-footer {
  display: none;
}
.slip-drawer .ant-drawer-content {
  overflow: hidden;
  background-color: var(--bg-color-4);
}
.slip-drawer .ant-drawer-content .ant-drawer-body {
  padding: 18px;
  padding-bottom: 0;
  background-color: var(--bg-color-4);
}
.slip-drawer .ant-drawer-content .ant-drawer-header {
  border: none;
  background-color: var(--bg-color-4);
}
.slip-drawer .ant-drawer-content .ant-drawer-header .ant-drawer-close {
  display: none;
}
.slip-drawer .ant-drawer-content .ant-drawer-header .ant-drawer-title {
  text-align: center;
  font-size: 20px;
  line-height: 12px;
  color: var(--text-color-1);
}
.slip-drawer .ant-drawer-content .ant-drawer-extra {
  font-size: 18px;
  color: var(--text-color-1);
}
.slip-drawer .ant-drawer-content .slip-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
}
.slip-drawer .ant-drawer-content .slip-content .continue-btn {
  font-size: 17px;
  font-weight: 600;
  margin-top: 35px;
}
.slip-drawer .ant-drawer-content .slip-content .ant-radio-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.slip-drawer .ant-drawer-content .slip-content .ant-radio-group .ant-radio-button-wrapper {
  width: 74px;
  height: 38px;
  color: var(--text-color-2);
  background-color: var(--border-color-1);
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slip-drawer .ant-drawer-content .slip-content .ant-radio-group .ant-radio-button-wrapper:before {
  display: none;
}
.slip-drawer .ant-drawer-content .slip-content .ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: var(--text-color-1);
  background: var(--accent-color);
  color: #fff;
}
.slip-drawer .ant-drawer-content .slip-content .footer {
  padding-bottom: calc(constant(safe-area-inset-bottom) / 3);
  padding-bottom: calc(env(safe-area-inset-bottom) / 3);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slip-drawer .ant-drawer-content .slip-content .footer .logo {
  width: 171px;
  height: 17px;
  background-image: var(--footer-icon-src);
  background-size: 100% 100%;
}
.swap-modal-confirm.slip-modal .confirm-footer-img {
  display: none;
}
.swap-modal-confirm .ant-drawer-body .confirm-footer-img {
  display: flex !important;
}
.swap-modal-confirm .confirm-footer-img {
  padding-bottom: calc(constant(safe-area-inset-bottom) / 3);
  padding-bottom: calc(env(safe-area-inset-bottom) / 3);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swap-modal-confirm .confirm-footer-img .logo {
  width: 171px;
  height: 17px;
  background-image: var(--footer-icon-src);
  background-size: 100% 100%;
}
.swap-modal-confirm .confirm-title {
  font-size: 16px;
  font-weight: 600;
}
.swap-modal-confirm .confirm-title .title-type {
  font-size: 11px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  align-items: center;
}
.swap-modal-confirm .confirm-title .title-type .title-icon {
  width: 11px;
  height: 11px;
  margin-right: 3px;
}
.swap-modal-confirm .confirm-content0 {
  color: var(--text-color-1);
}
.swap-modal-confirm .confirm-content0 .content-wrap .content-row:last-child {
  border-bottom: 1px solid var(--border-color-1);
}
.swap-modal-confirm .confirm-content0 .content-row {
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  border-top: 1px solid var(--border-color-1);
}
.swap-modal-confirm .confirm-content0 .content-row.last-row {
  border-bottom: 1px solid var(--border-color-1);
}
.swap-modal-confirm .confirm-content0 .content-row .row-left {
  display: flex;
}
.swap-modal-confirm .confirm-content0 .content-row .row-left .left-icon {
  width: 24px;
  height: 24px;
  overflow: hidden;
  border-radius: 12px;
  margin-right: 8px;
}
.swap-modal-confirm .confirm-content0 .confirm-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 21px;
}
.swap-modal-confirm .confirm-content0 .confirm-footer .confirm-time {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.swap-modal-confirm .confirm-content0 .confirm-footer .confirm-time .limit-time {
  background-image: var(--icon-src-19);
  background-size: cover;
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.swap-modal-confirm .confirm-content0 .confirm-footer .swap-now-btn {
  font-size: 17px;
  font-weight: 600;
  width: 100%;
}
.swap-modal-confirm .confirm-content0 .confirm-update p {
  text-align: center;
}
.swap-modal-confirm .confirm-content0 .confirm-update .content {
  margin-bottom: 20px;
  color: var(--text-color-2);
}
.swap-modal-confirm .confirm-content0 .confirm-submitted .list .item {
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  border-top: 1px solid var(--border-color-1);
}
.swap-modal-confirm .confirm-content0 .confirm-submitted .list .item .left {
  display: flex;
  align-items: center;
  gap: 8px;
}
.swap-modal-confirm .confirm-content0 .confirm-submitted .list .item .right {
  display: flex;
  align-items: center;
}
.swap-modal-confirm .confirm-content0 .confirm-submitted .list .item .right .micon {
  transform: rotate(270deg);
  position: relative;
  top: 2px;
}
.default-layout-footer.page-nftDetail {
  display: none;
}
._page_nftDetail .layout-main {
  overflow: hidden;
}
.nft-detail-container {
  position: relative;
  background-color: var(--content-background-color);
  height: 100% !important;
  overflow: auto;
}
@media screen and (max-width: 600px) {
  .nft-detail-container {
    -webkit-overflow-scrolling: touch;
  }
}
.nft-detail-container .wrapper-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background-size: 100%;
  background-repeat: repeat-y;
  filter: blur(100px);
  display: none;
}
@media screen and (min-width: 600px) {
  .nft-detail-container .wrapper-bg {
    display: none;
  }
}
.nft-detail-container > .wrapper {
  position: relative;
  z-index: 2;
  background-color: var(--content-background-color) !important;
}
@media screen and (min-width: 600px) {
  .nft-detail-container > .wrapper {
    display: flex;
    flex-direction: column;
    color: var(--text-color-1) !important;
  }
}
@media screen and (max-width: 600px) {
  .nft-detail-container > .wrapper .header-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.nft-detail-container .main-content {
  overflow-x: hidden;
  max-width: 100vw;
}
@media screen and (min-width: 600px) {
  .nft-detail-container .main-content {
    height: 0px;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
.nft-detail-container .main-content .content-wrapper {
  padding-bottom: 120px;
  box-sizing: border-box;
}
@media screen and (min-width: 600px) {
  .nft-detail-container .main-content .content-wrapper {
    padding-bottom: 0px;
    height: 0;
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 600px) {
  .nft-detail-container .main-content {
    height: 0px;
    flex: 1;
  }
}
.nft-detail-container .main-content .info-content {
  padding: 0px var(--text-distance-1);
}
.nft-detail-container .main-content .info-content .detail-box {
  width: 100%;
  min-height: 39px;
  background: var(--background-color-1);
  border-radius: 18px;
  margin-top: 15px;
  padding: 14px 14px 5px;
  margin-bottom: 0px;
}
.nft-detail-container .main-content .info-content .detail-box .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 9px;
}
.nft-detail-container .main-content .info-content .detail-box .right {
  display: flex;
  align-items: center;
  white-space: nowrap;
  position: relative;
  box-sizing: border-box;
  flex: 1;
  justify-content: flex-end;
  max-width: 70%;
  color: var(--text-color-1);
}
.nft-detail-container .main-content .info-content .detail-box .right > div {
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nft-detail-container .main-content .info-content .detail-box .right .copy-icon {
  width: 14px;
  height: 14px;
  margin-left: 4px;
  cursor: pointer;
}
.nft-detail-container .main-content .info-content .detail-box .right .copy-icon svg {
  color: var(--text-color-1);
}
.nft-detail-container .main-content .info-content .no-description,
.nft-detail-container .main-content .info-content .no-attributes {
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 50px;
  opacity: 0.5;
}
.nft-detail-container .main-content .info-content .attrs-list {
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 10px;
}
@media screen and (min-width: 600px) {
  .nft-detail-container .main-content .info-content .attrs-list {
    flex-wrap: wrap;
    row-gap: 20px;
  }
}
.nft-detail-container .main-content .info-content .attrs-list .attrs-list-item {
  height: 56px;
  background: var(--background-color-1);
  border-radius: 18px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 14px;
}
.nft-detail-container .main-content .info-content .attrs-list .attrs-list-item span {
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: var(--text-color-2) !important;
  opacity: 0.7;
}
.nft-detail-container .main-content .info-content .attrs-list .attrs-list-item h2 {
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  color: var(--text-color-1) !important;
  margin-top: 4px;
  white-space: nowrap;
}
.nft-detail-container .main-content .info-content .info-box {
  margin-top: 20px;
  color: var(--text-color-2) !important;
}
.nft-detail-container .main-content .info-content .info-box .info-line {
  width: 100%;
  height: 0px;
  opacity: 0.7;
  margin-top: 12px;
}
.nft-detail-container .main-content .info-content .info-box .info-box-more {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--text-color-1);
  margin-top: 4px;
}
.nft-detail-container .main-content .info-content .info-box .info-box-body {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 12px;
  white-space: pre-wrap;
  word-break: keep-all;
}
.nft-detail-container .main-content .info-content .info-box .info-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  line-height: 14px;
  color: var(--text-color-1);
  margin-top: 36px;
}
.nft-detail-container .main-content .info-content .info-box .info-title span {
  font-size: 14px;
}
.nft-detail-container .main-content .info-content .info1 {
  padding-bottom: 20px;
}
.nft-detail-container .main-content .info-content .info1 .p1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--text-color-1);
  margin-top: 10px;
  word-break: break-all;
}
.nft-detail-container .main-content .info-content .info1 .p2 {
  font-size: 14px;
  font-weight: 600;
  color: #2d6af6;
  line-height: 14px;
}
.nft-detail-container .main-content .info-content .info2 {
  margin-top: 10px;
}
.nft-detail-container .main-content .info-content .info2 .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1.8;
  margin-bottom: 10px;
}
.nft-detail-container .main-content .info-content .info2 .row .left {
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color-1);
}
.nft-detail-container .main-content .info-content .info2 .row .right {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color-2);
  display: flex;
  align-items: center;
}
.nft-detail-container .main-content .foot-logo1 {
  background-image: var(--footer-icon-src);
  width: 100%;
  height: 40px;
  background-size: 171px 17px;
  background-repeat: no-repeat;
  background-position: center center;
}
@media screen and (min-width: 600px) {
  .nft-detail-container .main-content .foot-logo1 {
    margin-bottom: 0;
  }
}
.nft-detail-container .main-content .footer {
  background-color: var(--content-background-color);
  position: fixed;
  bottom: 0px;
  height: 86px;
  display: flex;
  align-items: center;
  left: 0;
  padding: 0 var(--text-distance-1);
  width: 100%;
  justify-content: center;
  flex-direction: column;
}
@media screen and (min-width: 600px) {
  .nft-detail-container .main-content .footer {
    position: initial;
    padding: 0 70px;
    height: 86px;
    align-items: center;
    bottom: 0;
    margin-top: 50px;
    margin-bottom: 10px;
    margin: 0;
  }
}
.nft-detail-container .main-content .footer button {
  font-size: 17px;
  font-weight: bold;
  height: 46px;
  width: 100%;
  max-width: 400px;
}
.nft-detail-container .c-pointer {
  cursor: pointer;
  text-decoration: underline;
}
.nft-detail-container .detail-icon {
  display: flex;
  align-items: center;
}
.nft-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  padding: 0 var(--text-distance-1);
  box-sizing: border-box;
  z-index: 99;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: var(--text-color-1);
}
.nft-header span {
  color: var(--text-color-1);
}
.nft-header .nft-back-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-image: var(--back-icon-src);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.nft-header .nft-right-icon {
  width: 15px;
  height: 15px;
  cursor: pointer;
  background-image: var(--nft-icon-share);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.nft-header .nft-right-box {
  width: 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
}
.nft-header .nft-left-box {
  width: 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 20px;
}
.address-book-container > .wrapper {
  display: flex;
  flex-direction: column;
  background: var(--content-background-color) !important;
  min-height: 100%;
}
@media screen and (min-width: 600px) {
  .address-book-container > .wrapper {
    min-height: initial;
  }
}
.address-book-container .no-record-container {
  padding-top: 0;
}
.address-book-container .main-content {
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: var(--text-color-1);
}
.address-book-container .main-content .no-record-container {
  height: 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.address-book-container .main-content .list {
  height: 0;
  flex: 1;
  overflow: auto;
}
.address-book-container .main-content .list .item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 60px;
  margin: 0 var(--text-distance-1);
  padding: 0 var(--text-distance-2);
  height: 60px;
  color: var(--text-color-1);
  cursor: pointer;
  background: var(--background-color-1);
  border-radius: 18px;
  margin-bottom: 10px;
}
.address-book-container .main-content .list .item .left {
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  max-width: 500px;
}
.address-book-container .main-content .list .item .left .icon {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  overflow: hidden;
  margin-right: 10px;
}
.address-book-container .main-content .list .item .left .name {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 15px;
}
.address-book-container .main-content .list .item .right {
  width: 190px;
  text-align: right;
}
.address-book-container .main-content .list .item .right .edit-btn {
  cursor: pointer;
  margin-left: 16px;
}
.address-book-container .main-content .list .item .right .delete-icon-btn {
  cursor: pointer;
  margin-left: 16px;
}
.address-book-container .main-content .footer {
  position: fixed;
  bottom: calc(var(--footer-height));
  height: 65px;
  background: var(--content-background-color);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  left: 0;
  padding: 0 var(--text-distance-1);
  padding-bottom: 0px;
  padding-top: 10px;
  width: 100%;
}
@media screen and (min-width: 600px) {
  .address-book-container .main-content .footer {
    position: initial;
    padding: 0 70px;
    height: 70px;
    align-items: center;
    align-items: flex-end;
  }
}
.address-book-container .main-content .footer button {
  height: 46px;
  width: 440px;
  max-width: 100%;
  font-size: 17px;
}
@media screen and (min-width: 600px) {
  .address-book-container .main-content .footer button {
    width: 380px;
  }
}
._page_setting .default-layout {
  background-color: var(--content-background-color);
}
@media screen and (max-height: 500px) {
  .setting-container {
    height: 100% !important;
  }
}
.setting-container > .wrapper {
  background: var(--content-background-color) !important;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 600px) {
  .setting-container > .wrapper {
    min-height: initial;
  }
}
.setting-container .main-content {
  position: relative;
  height: 0;
  flex: 1;
}
.setting-container .main-content .setting-list-wrapper {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: calc(100% - 24px);
  overflow-y: auto;
}
.setting-container .main-content .list {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.setting-container .main-content .list .item {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color-1);
  padding: 0 var(--text-distance-1);
}
.setting-container .main-content .list .item .mini-link-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.setting-container .main-content .list .item .left {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color-1);
  position: relative;
}
.setting-container .main-content .list .item .left.show-red-dot .icon:after {
  content: '';
  border-radius: 100%;
  top: 0px;
  right: -2px;
  width: 8px;
  height: 8px;
  position: absolute;
  border-radius: 50%;
  background-color: #ff0201;
  border: 1px solid #ffffff;
}
.setting-container .main-content .list .item .left .icon {
  width: 27px;
  height: 27px;
  margin-right: 10px;
}
.setting-container .main-content .list .item .right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--text-color-1);
}
.setting-container .main-content .list .item .right span {
  margin-right: 0px;
}
.setting-container .main-content .list .item .right .micon {
  margin-left: 8px;
}
.setting-container .main-content .list .item.language-item .right span {
  margin-right: 0;
}
.setting-container .main-content .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 12px;
  z-index: 1;
  margin-bottom: 0;
  padding-bottom: 0px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: var(--text-color-2);
  height: 20px;
}
.setting-container .main-content .footer .ant-btn-primary {
  width: 100%;
  height: 47px;
  border-radius: 10px;
  font-size: 17px;
  font-weight: bold;
}
.add-custom-token-container {
  background-color: var(--content-background-color) !important;
  min-height: 540px;
  position: relative;
}
@media screen and (max-height: 600px) {
  .add-custom-token-container {
    height: 100% !important;
  }
}
.add-custom-token-container > .wrapper {
  background-color: var(--content-background-color) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 600px) {
  .add-custom-token-container > .wrapper {
    display: flex;
    flex-direction: column;
  }
}
.add-custom-token-container .add-custom-token-main {
  padding: 0 4px;
  padding-top: 10px;
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 600px) {
  .add-custom-token-container .add-custom-token-main {
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0 8px;
  }
}
.add-custom-token-container .add-custom-token-main .tips-Nx9 {
  display: flex;
  align-items: center;
  padding: 0 12px;
  margin-top: 10px;
}
.add-custom-token-container .add-custom-token-main .tips-Nx9 .wrap {
  background: rgba(234, 185, 129, 0.2);
  border-radius: 18px;
  display: flex;
  align-items: flex-start;
  padding: 0 14px;
  width: 100%;
  height: 60px;
  align-items: center;
}
.add-custom-token-container .add-custom-token-main .tips-Nx9 .wrap .left .icon {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.add-custom-token-container .add-custom-token-main .tips-Nx9 .wrap .right {
  font-size: 15px;
  font-weight: 400;
  color: var(--text-color-1);
  line-height: 1.2;
}
.add-custom-token-container .add-custom-token-main .form-content {
  padding-top: 14px;
  flex: 1;
  height: 0;
}
@media screen and (min-width: 600px) {
  .add-custom-token-container .add-custom-token-main .form-content {
    height: 0;
    flex: 1;
  }
}
.add-custom-token-container .add-custom-token-main .form-content .ant-form {
  height: 100%;
}
.add-custom-token-container .add-custom-token-main .form-content .ant-form .scroll-content {
  height: 100%;
  overflow: auto;
  padding: 0 12px;
  padding-bottom: 100px;
}
.add-custom-token-container .add-custom-token-main .form-content .ant-form-item-label {
  padding: 0;
}
.add-custom-token-container .add-custom-token-main .form-content .ant-form-item-label label {
  font-size: 16px;
  line-height: 1;
  color: var(--text-color-1);
  font-weight: bold;
}
.add-custom-token-container .add-custom-token-main .form-content .ant-form-item-label label:before {
  display: none;
}
.add-custom-token-container .add-custom-token-main .form-content .form-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 12px;
  z-index: 1;
  margin-bottom: 0;
  padding-bottom: calc(var(--footer-height) - 8px + constant(safe-area-inset-bottom) / 3);
  background-color: var(--content-background-color);
}
@media screen and (max-width: 600px) {
  .add-custom-token-container .add-custom-token-main .form-content .form-footer {
    position: fixed;
    bottom: 50px;
  }
}
@media screen and (max-width: 600px) and (max-height: 600px) {
  .add-custom-token-container .add-custom-token-main .form-content .form-footer {
    position: fixed;
    bottom: 0;
  }
}
@media screen and (max-height: 600px) {
  .add-custom-token-container .add-custom-token-main .form-content .form-footer {
    height: 56px;
  }
}
@media screen and (min-width: 600px) {
  .add-custom-token-container .add-custom-token-main .form-content .form-footer {
    height: 46px;
    align-items: center;
    padding: 0;
  }
}
.add-custom-token-container .add-custom-token-main .form-content .form-footer .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .add-custom-token-container .add-custom-token-main .form-content .form-footer .ant-form-item-control-input-content {
    padding: 0 70px;
  }
}
.add-custom-token-container .add-custom-token-main .form-content .form-footer .ant-form-item-control-input-content .ant-btn-primary {
  width: 380px;
  max-width: 100%;
  font-size: 17px;
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  .add-custom-token-container .add-custom-token-main .form-content .form-footer .ant-form-item-control-input-content .ant-btn-primary {
    width: 94%;
  }
}
.add-custom-nft-container {
  background-color: var(--content-background-color) !important;
  min-height: 540px;
  position: relative;
}
@media screen and (max-height: 600px) {
  .add-custom-nft-container {
    height: 100% !important;
  }
}
.add-custom-nft-container > .wrapper {
  background-color: var(--content-background-color) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 600px) {
  .add-custom-nft-container > .wrapper {
    display: flex;
    flex-direction: column;
  }
}
.add-custom-token-result-container {
  overflow: hidden !important;
}
.add-custom-token-result-container > .wrapper {
  background-color: var(--content-background-color) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 600px) {
  .add-custom-token-result-container > .wrapper {
    min-height: initial;
  }
}
.add-custom-token-result-container .add-custom-token-main {
  padding-top: 10px;
  height: 0;
  flex: 1;
  position: relative;
}
.add-custom-token-result-container .add-custom-token-main .tips-Nx9 {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color-1);
  height: 40px;
  padding: 0 22px;
  text-align: center;
}
.add-custom-token-result-container .add-custom-token-main .form-content {
  padding-top: 14px;
}
.add-custom-token-result-container .add-custom-token-main .form-content .result-content {
  padding: 0 14px;
  font-size: 16px;
  line-height: 19px;
  color: var(--text-color-1);
  font-weight: bold;
}
.add-custom-token-result-container .add-custom-token-main .form-content .result-content .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add-custom-token-result-container .add-custom-token-main .form-content .result-content .row.row1 .left {
  color: var(--text-color-2);
  font-weight: 400;
}
.add-custom-token-result-container .add-custom-token-main .form-content .result-content .row.row2 {
  font-size: 18px;
  height: 56px;
  border-radius: 18px;
  background-color: var(--background-color-1);
  margin-top: 14px;
  padding: 0px 14px;
}
.add-custom-token-result-container .add-custom-token-main .form-content .result-content .row.row2 .left {
  display: flex;
  align-items: center;
}
.add-custom-token-result-container .add-custom-token-main .form-content .result-content .row.row2 .left .icon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: contain;
}
.add-custom-token-result-container .add-custom-token-main .form-content .result-content .row.row2 .right {
  font-weight: 400;
  color: var(--text-color-1);
}
.add-custom-token-result-container .add-custom-token-main .form-content .form-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 12px;
  z-index: 1;
  margin-bottom: 0;
  padding-bottom: calc(var(--footer-height) - 8px + constant(safe-area-inset-bottom) / 3);
  background-color: var(--content-background-color);
}
@media screen and (max-width: 600px) {
  .add-custom-token-result-container .add-custom-token-main .form-content .form-footer {
    position: fixed;
    bottom: 40px;
  }
}
@media screen and (max-width: 600px) and (max-height: 600px) {
  .add-custom-token-result-container .add-custom-token-main .form-content .form-footer {
    position: fixed;
    bottom: 0;
  }
}
@media screen and (max-height: 600px) {
  .add-custom-token-result-container .add-custom-token-main .form-content .form-footer {
    height: 56px;
  }
}
@media screen and (min-width: 600px) {
  .add-custom-token-result-container .add-custom-token-main .form-content .form-footer {
    height: 46px;
    align-items: center;
    padding: 0;
  }
}
.add-custom-token-result-container .add-custom-token-main .form-content .form-footer .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .add-custom-token-result-container .add-custom-token-main .form-content .form-footer .ant-form-item-control-input-content {
    padding: 0 70px;
  }
}
.add-custom-token-result-container .add-custom-token-main .form-content .form-footer .ant-form-item-control-input-content .ant-btn-primary {
  width: 380px;
  max-width: 100%;
  font-size: 17px;
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  .add-custom-token-result-container .add-custom-token-main .form-content .form-footer .ant-form-item-control-input-content .ant-btn-primary {
    width: 94%;
  }
}
.new-address-container {
  min-height: 450px;
}
.new-address-container > .wrapper {
  background-color: var(--content-background-color) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 600px) {
  .new-address-container > .wrapper {
    display: flex;
    flex-direction: column;
  }
}
.new-address-container .add-address-main {
  padding: 0 var(--text-distance-1);
  padding-top: 10px;
  height: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 600px) {
  .new-address-container .add-address-main {
    height: 0;
    flex: 1;
    position: relative;
  }
}
.new-address-container .add-address-main .tips-Nx9 {
  height: 60px;
  background-color: var(--background-color-1);
  border-radius: 18px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color-1);
  display: flex;
  align-items: center;
}
.new-address-container .add-address-main .tips-Nx9 .icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.new-address-container .add-address-main .form-content {
  padding-top: 18px;
  height: 1;
  flex: 1;
}
.new-address-container .add-address-main .form-content .ant-form {
  height: 100%;
  position: relative;
}
.new-address-container .add-address-main .form-content .ant-form-item {
  padding-bottom: 10px;
}
.new-address-container .add-address-main .form-content .ant-form-item-label {
  padding: 0;
}
.new-address-container .add-address-main .form-content .ant-form-item-label label {
  font-size: 16px;
  line-height: 1;
  color: var(--text-color-1);
  font-weight: bold;
}
.new-address-container .add-address-main .form-content .ant-form-item-label label:before {
  display: none;
}
.new-address-container .add-address-main .form-content .ant-input-number {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.new-address-container .add-address-main .form-content .ant-input,
.new-address-container .add-address-main .form-content .ant-input-number-input {
  border-radius: 10px;
  padding: 11px 11px;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color-1);
}
.new-address-container .add-address-main .form-content .ant-input:focus,
.new-address-container .add-address-main .form-content .ant-input-focused,
.new-address-container .add-address-main .form-content .ant-input-number:focus,
.new-address-container .add-address-main .form-content .ant-input-number-focused {
  box-shadow: none;
}
.new-address-container .add-address-main .form-content .form-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 12px;
  z-index: 1;
  margin-bottom: 0;
  padding: 0;
}
@media screen and (min-width: 600px) {
  .new-address-container .add-address-main .form-content .form-footer {
    padding: 0 0px;
    height: 46px;
    align-items: center;
  }
}
@media screen and (min-width: 600px) {
  .new-address-container .add-address-main .form-content .form-footer .wrap {
    padding: 0 70px;
  }
}
.new-address-container .add-address-main .form-content .form-footer .form-add {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .new-address-container .add-address-main .form-content .form-footer .form-add {
    padding: 0 70px;
  }
}
.new-address-container .add-address-main .form-content .form-footer .form-edit button {
  width: 48%;
}
@media screen and (min-width: 600px) {
  .new-address-container .add-address-main .form-content .form-footer .form-edit button {
    width: 45%;
  }
}
.new-address-container .add-address-main .form-content .form-footer .ant-btn-primary {
  width: 480px;
  max-width: 100%;
  font-size: 17px;
  font-weight: bold;
}
@media screen and (min-width: 600px) {
  .new-address-container .add-address-main .form-content .form-footer .ant-btn-primary {
    width: 380px;
  }
}
.new-address-container .add-address-main .form-content .wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.new-address-container .add-address-main .form-content .wrap .ant-btn-primary,
.new-address-container .add-address-main .form-content .wrap button {
  width: 100%;
}
.no-record-container {
  padding-top: 6vh;
  text-align: center;
}
@media screen and (max-height: 800px) {
  .no-record-container {
    padding: 2vh;
  }
}
.no-record-container .empty-title {
  font-size: 18px;
  color: var(--text-color-1);
}
.no-record-container .empty-sub-title {
  font-size: 12px;
  color: var(--text-color-2);
  width: 300px;
  margin: auto;
  max-width: 100%;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 8px;
  font-size: 14px;
}
@media screen and (min-width: 600px) {
  .no-record-container .empty-sub-title {
    width: 500px;
  }
}
.no-record-container .ant-empty-image {
  display: flex;
  justify-content: center;
  position: relative;
}
.no-record-container .ant-empty-image img {
  width: 120px;
  height: 120px;
}
.no-record-container .ant-empty-image::after {
  content: '';
  width: 120px;
  height: 120px;
  position: absolute;
  background-image: var(--no-record-image);
  background-repeat: no-repeat;
  background-size: 120px 120px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-record-container .ant-empty-image .ant-empty-img-default {
  display: none;
}
.no-record-container .ant-empty-description {
  font-size: 20px;
  font-weight: 600;
  color: #ccd4ec;
  padding-top: 14px;
}
.default-layout-footer.page-nftSend {
  display: none;
}
._page_nftSend .layout-main {
  overflow: hidden;
}
.nft-send-container {
  position: relative;
  background-color: var(--content-background-color);
  height: 100% !important;
  overflow: auto !important;
}
.nft-send-container[data-device='firefox'] {
  overflow: initial !important;
}
.nft-send-container input[type='number']:focus {
  outline: none;
  border: none;
}
.nft-send-container input[type='number']::-webkit-inner-spin-button,
.nft-send-container input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.nft-send-container input[type='number']::-webkit-inner-spin-button:focus,
.nft-send-container input[type='number']::-webkit-outer-spin-button:focus {
  display: none;
  opacity: 0;
  font-size: 0;
  padding: 0;
  width: 0;
  height: 0;
}
.nft-send-container .foot-logo {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-image: var(--footer-icon-src);
  background-size: 171px 17px;
  background-repeat: no-repeat;
  background-position: 50% 0;
  width: 100%;
  height: 40px;
  margin-top: 4px;
  box-sizing: content-box;
  padding-bottom: 16px;
  padding: 0;
  margin: 0;
  background-position: center center;
}
@media screen and (max-width: 600px) {
  .nft-send-container .foot-logo {
    background-color: var(--content-background-color);
    position: fixed;
  }
}
.nft-send-container .wrapper-bg {
  filter: blur(150px);
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: repeat-y;
  display: none;
}
@media screen and (min-width: 600px) {
  .nft-send-container .wrapper-bg {
    display: none;
  }
}
.nft-send-container > .wrapper {
  background-color: var(--content-background-color) !important;
}
@media screen and (max-width: 600px) {
  .nft-send-container > .wrapper .header-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.nft-send-container .input-val {
  font-size: 14px !important;
  color: var(--text-color-1);
  padding: 0;
  height: 30px !important;
}
.nft-send-container .info-content {
  padding: 0px 24px;
}
.nft-send-container .info-content .info1 {
  padding-bottom: 20px;
}
.nft-send-container .info-content .info1 .p1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--text-color-1);
  margin-top: 10px;
  word-break: break-all;
  position: relative;
}
.nft-send-container .info-content .info1 .p2 {
  font-size: 14px;
  font-weight: 600;
  color: #2d6af6;
  line-height: 14px;
}
.nft-send-container .main-content {
  padding-bottom: 130px;
}
@media screen and (min-width: 600px) {
  .nft-send-container .main-content {
    overflow: auto;
    height: calc(100% - var(--header-height));
  }
}
.nft-send-container .main-content .nft-send-form {
  padding: 0 var(--text-distance-1);
  padding-top: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media screen and (min-width: 600px) {
  .nft-send-container .main-content .nft-send-form {
    margin-bottom: 30px;
  }
}
.nft-send-container .main-content .nft-send-form .ant-form {
  width: 100%;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content {
  border: 1px solid var(--background-color-1);
  border-radius: 18px;
  overflow: hidden;
  color: var(--text-color-1);
  background-color: var(--background-color-1);
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content input {
  border: none;
  outline: none;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content .ant-input-number {
  border: none;
  outline: none;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content .input-number {
  background-color: rgba(0, 0, 0, 0);
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content:focus,
.nft-send-container .main-content .nft-send-form .ant-form .input-content[data-focus='true'] {
  border-color: var(--accent-color);
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content.to-input-content .wrap,
.nft-send-container .main-content .nft-send-form .ant-form .input-content.amount-input-content .wrap {
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content.to-input-content .wrap .left,
.nft-send-container .main-content .nft-send-form .ant-form .input-content.amount-input-content .wrap .left {
  flex: 1;
  padding-right: 10px;
  overflow: hidden;
  position: relative;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content.to-input-content .wrap .left .masker,
.nft-send-container .main-content .nft-send-form .ant-form .input-content.amount-input-content .wrap .left .masker {
  display: none;
  width: 20px;
  height: 100%;
  position: absolute;
  z-index: 3;
  background-color: var(--content-background-color);
  right: 0;
  bottom: 0;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content .ant-input {
  height: 44px;
  font-size: 18px;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content .suffix-content {
  display: flex;
  align-items: center;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content .suffix-content .icon1 {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content .suffix-content .icon2 {
  width: 22px;
  height: 22px;
  cursor: pointer;
  background-image: var(--icon-src-23);
  background-size: 100% auto;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content.fee-input-content {
  height: 60px;
  font-size: 12px;
  font-weight: normal;
  color: var(--text-color-1);
  display: flex;
  align-items: center;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content.fee-input-content .wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content.fee-input-content .wrap .gray-color {
  color: var(--text-color-2);
  margin-left: 4px;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content.fee-input-content .wrap .right .sub-wrap {
  display: flex;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content.fee-input-content .wrap .right .sub-wrap .sub-left {
  margin-right: 4px;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content.fee-input-content .wrap .right .sub-wrap .sub-left .row2 {
  color: var(--message-success-color);
  font-weight: bold;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content.fee-input-content .wrap .right .sub-wrap .sub-right {
  display: flex;
  align-items: center;
}
.nft-send-container .main-content .nft-send-form .ant-form .input-content.fee-input-content .wrap .right .sub-wrap .sub-right .icon {
  width: 17px;
  height: 34px;
}
.nft-send-container .main-content .nft-send-form .ant-form .ant-form-item {
  margin-bottom: 14px;
}
.nft-send-container .main-content .nft-send-form .ant-form .ant-form-item-label {
  padding-bottom: 0;
  width: 100%;
}
.nft-send-container .main-content .nft-send-form .ant-form .ant-form-item-label > label {
  width: 100%;
}
.nft-send-container .main-content .nft-send-form .ant-form .ant-form-item-label label {
  font-size: 16px;
  color: var(--text-color-1);
}
.nft-send-container .main-content .nft-send-form .ant-form .ant-form-item-label .amount-label-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nft-send-container .main-content .nft-send-form .ant-form .ant-form-item-label .amount-label-content .tips-Nx9 {
  font-size: 12px;
  font-weight: normal;
  color: #44169f;
  line-height: 1;
}
.nft-send-container .main-content .nft-send-form .ant-form .ant-form-item-label .fee-label-content {
  display: flex;
  align-items: center;
}
.nft-send-container .main-content .nft-send-form .ant-form .ant-form-item-label .fee-label-content .refresh-content {
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color-2);
}
.nft-send-container .main-content .nft-send-form .ant-form .ant-form-item-extra {
  font-size: 12px;
  font-weight: 400;
  color: var(--accent-color);
  padding-top: 6px;
  padding-left: 0px;
  cursor: pointer;
}
.nft-send-container .main-content .form-footer {
  position: relative;
  z-index: 2;
  width: 100%;
  padding: 0 12px;
  padding-top: 10px;
  padding: 0;
  display: flex;
  justify-content: center;
  height: 86px;
  position: absolute;
  bottom: 0;
  background: var(--content-background-color);
}
@media screen and (max-width: 600px) {
  .nft-send-container .main-content .form-footer {
    position: fixed;
    bottom: 0;
    z-index: 4;
  }
}
@media screen and (min-width: 600px) {
  .nft-send-container .main-content .form-footer {
    padding: 0;
    gap: 40px;
    align-items: self-start;
  }
}
.nft-send-container .main-content .form-footer .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
}
.nft-send-container .main-content .form-footer .cancel-btn {
  width: 45%;
  font-weight: 400;
}
.nft-send-container .main-content .form-footer .send-btn {
  width: 45%;
  margin-left: 10px;
  max-width: 400px;
}
.tip-container {
  width: 100%;
}
.tip-container .warning-tip {
  width: 100%;
  font-size: 12px;
  line-height: 1.2;
  font-weight: 600;
  color: #ff7676;
}
.network-fee-sol {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 14px;
  color: var(--text-color-1);
  font-size: 16px;
}
.network-fee-sol span {
  color: var(--text-color-2);
}
.nft-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  padding: 0 var(--text-distance-1);
  box-sizing: border-box;
  z-index: 99;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: var(--text-color-1);
}
.nft-header .nft-back-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-image: var(--back-icon-src);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.nft-header .nft-right-icon {
  width: 15px;
  height: 15px;
}
.nft-header .nft-right-box {
  width: 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
}
.nft-header .nft-left-box {
  width: 15px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 20px;
}
.amount-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.amount-line .max-amount {
  font-size: 14px;
  color: var(--pending-color);
  cursor: pointer;
}
.address-icons {
  display: flex;
  align-content: center;
}
.recend-tips {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  color: #2d6af6;
  margin-bottom: 18px;
}
.loading-box.network-fee-loading {
  position: absolute;
  width: 100%;
  height: 62px;
  background-color: rgba(0, 0, 0, 0.01);
  border-radius: 8px;
  z-index: 3;
}
.loading-box.network-fee-loading .lds-default {
  margin: 0;
  padding: 0;
  transform: scale(0.5);
  position: relative;
  top: -3px;
}
.transaction-detail-container {
  min-height: 500px;
}
.transaction-detail-container > .wrapper {
  background-color: var(--content-background-color) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 600px) {
  .transaction-detail-container > .wrapper {
    display: flex;
    flex-direction: column;
  }
}
.transaction-detail-container .no-detail-record {
  flex: 1;
  height: 0;
  padding-top: 16vh;
}
.transaction-detail-container .main-content {
  height: 0;
  flex: 1;
  overflow: auto;
}
.transaction-detail-container .main-content .info {
  display: flex;
  justify-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.transaction-detail-container .main-content .info .wrap .icon-wrap {
  height: 52px;
}
.transaction-detail-container .main-content .info .wrap .icon {
  width: 52px;
  height: 52px;
  margin: auto;
}
.transaction-detail-container .main-content .info .wrap .micon[data-type='success'] {
  color: var(--message-success-color);
}
.transaction-detail-container .main-content .info .wrap .micon[data-type='failed'] {
  color: var(--message-error-color);
}
.transaction-detail-container .main-content .info .wrap .micon[data-type='pending'] {
  color: var(--pending-color);
}
.transaction-detail-container .main-content .info .wrap .title {
  font-size: 31px;
  opacity: 1;
  margin-bottom: 10px;
  color: var(--text-color-1);
  height: 48px;
}
.transaction-detail-container .main-content .info .wrap .date {
  font-size: 12px;
  font-weight: 400;
  color: var(--text-color-2);
  height: 38px;
}
.solana-transaction-detail-container .main-content .details {
  padding: 0 16px;
}
.solana-transaction-detail-container .main-content .details .card {
  background: var(--background-color-1);
  border-radius: 18px;
  margin-bottom: 10px;
}
.solana-transaction-detail-container .main-content .details .card .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 14px;
}
.solana-transaction-detail-container .main-content .details .card .row .left {
  font-size: 16px;
  color: var(--text-color-1);
  font-weight: 400;
}
.solana-transaction-detail-container .main-content .details .card .row .right {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color-2);
}
.solana-transaction-detail-container .main-content .details .card .row .right.right-success {
  color: var(--message-success-color);
}
.solana-transaction-detail-container .main-content .details .card .row .right.right-failed {
  color: var(--message-error-color);
}
.solana-transaction-detail-container .main-content .details .card .row .right.right-Failed {
  color: var(--message-error-color);
}
.solana-transaction-detail-container .main-content .details .card .row .right[data-type='out'] {
  color: var(--message-error-color) !important;
}
.solana-transaction-detail-container .main-content .details .card.card2 .row:first-child:before,
.solana-transaction-detail-container .main-content .details .card.card3 .row:first-child:before {
  display: none;
}
.solana-transaction-detail-container .main-content .details .card.card2 .row:last-child,
.solana-transaction-detail-container .main-content .details .card.card3 .row:last-child {
  position: relative;
  padding: 15px 14px;
}
.solana-transaction-detail-container .main-content .details .card.card2 .icon,
.solana-transaction-detail-container .main-content .details .card.card3 .icon {
  width: 16px;
  height: 16px;
  background-image: var(--icon-src-9);
  background-size: 100% 100%;
}
.evm-transaction-detail-container .main-content .details {
  padding: 0 var(--text-distance-1);
}
.evm-transaction-detail-container .main-content .details .card {
  background: var(--background-color-1);
  border: 1px solid var(--border-color-3);
  border-radius: 10px;
  margin-bottom: 10px;
}
.evm-transaction-detail-container .main-content .details .card .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 8px;
}
.evm-transaction-detail-container .main-content .details .card .row .left {
  font-size: 16px;
  color: var(--text-color-1);
  font-weight: 400;
}
.evm-transaction-detail-container .main-content .details .card .row .right {
  font-size: 14px;
  font-weight: 400;
  color: var(--text-color-2);
}
.evm-transaction-detail-container .main-content .details .card .row .right.right-failed {
  color: #ff7676;
}
.evm-transaction-detail-container .main-content .details .card.card1 .row:first-child,
.evm-transaction-detail-container .main-content .details .card.card2 .row:first-child {
  padding-bottom: 0;
}
.evm-transaction-detail-container .main-content .details .card.card1 .row:first-child:before,
.evm-transaction-detail-container .main-content .details .card.card3 .row:first-child:before {
  display: none;
}
.evm-transaction-detail-container .main-content .details .card.card1 .row:last-child,
.evm-transaction-detail-container .main-content .details .card.card3 .row:last-child {
  position: relative;
  padding: 14px 8px;
}
.evm-transaction-detail-container .main-content .details .card.card1 .row:last-child:before,
.evm-transaction-detail-container .main-content .details .card.card3 .row:last-child:before {
  content: '';
  position: absolute;
  width: calc(100% - 8px - 8px);
  height: 1px;
  top: 0;
  left: 8px;
  border-top: 1px solid var(--border-color-0);
}
.evm-transaction-detail-container .main-content .details .card.card1 .icon,
.evm-transaction-detail-container .main-content .details .card.card3 .icon {
  width: 16px;
  height: 16px;
  background-image: var(--icon-src-9);
  background-size: 100% 100%;
}
.evm-transaction-detail-container .footer .ant-btn-default:hover,
.evm-transaction-detail-container .footer .ant-btn-default:focus,
.evm-transaction-detail-container .footer .ant-btn-default:active {
  background: rgba(0, 0, 0, 0.4) !important;
  color: #fff;
}
.theme-dark .transaction-detail-container .footer .ant-btn-default:hover,
.theme-dark .transaction-detail-container .footer .ant-btn-default:focus,
.theme-dark .transaction-detail-container .footer .ant-btn-default:active {
  background: #666 !important;
}
.choice-token-list-container > .wrapper {
  background-color: var(--content-background-color) !important;
}
.choice-token-list-container .price {
  font-size: 16px;
  font-weight: 600;
  color: var(--text-color-1);
}
.choice-token-list-container .no-record-container {
  padding: 0;
  padding-bottom: 10%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.choice-token-list-container .wrap-name {
  width: 400px;
}
@media (max-width: 600px) {
  .choice-token-list-container .wrap-name {
    width: 120px;
    word-break: break-all;
    word-wrap: break-word;
    white-space: pre-wrap;
  }
}
.currency-list-container > .wrapper {
  background-color: var(--content-background-color) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.currency-list-container .main-content {
  height: 0px;
  flex: 1;
}
.currency-list-container .list .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 var(--text-distance-1);
  padding: 0 var(--text-distance-2);
  height: 60px;
  color: var(--text-color-1);
  background: var(--background-color-1);
  border-radius: 18px;
  margin-bottom: 10px;
  cursor: pointer;
}
.currency-list-container .list .item .left {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: var(--text-color-1);
}
.currency-list-container .list .item .right {
  width: 14px;
  height: 14px;
}
.currency-list-container .list .item .right .icon.selected {
  width: 100%;
  height: 100%;
  background-image: var(--icon-src-8);
  background-size: 100% 100%;
}
.language-list-container > .wrapper {
  background-color: var(--content-background-color) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.language-list-container .main-content {
  height: 0px;
  flex: 1;
}
.language-list-container .list .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 var(--text-distance-1);
  padding: 0 var(--text-distance-2);
  height: 60px;
  color: var(--text-color-1);
  background: var(--background-color-1);
  border-radius: 18px;
  margin-bottom: 10px;
  cursor: pointer;
}
.language-list-container .list .item .left {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: var(--text-color-1);
}
.language-list-container .list .item .right {
  width: 14px;
  height: 14px;
}
.language-list-container .list .item .right .icon.selected {
  width: 100%;
  height: 100%;
  background-image: var(--icon-src-8);
  background-size: 100% 100%;
}
.connected-list-container > .wrapper {
  background-color: var(--content-background-color) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.connected-list-container .main-content {
  height: 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.connected-list-container .main-content .content-wrapper {
  height: 0;
  flex: 1;
}
.connected-list-container .main-content .content-wrapper .no-record-container {
  height: 100%;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5vh;
}
.connected-list-container .list .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 60px;
  margin: 0 var(--text-distance-1);
  padding: 0 var(--text-distance-2);
  height: 60px;
  color: var(--text-color-1);
  cursor: pointer;
  background: var(--background-color-1);
  border-radius: 18px;
  margin-bottom: 10px;
}
.connected-list-container .list .item .left {
  display: flex;
  align-items: center;
}
.connected-list-container .list .item .left .logo {
  width: 38px;
  height: 38px;
  margin: 0 12px;
  margin-left: 0;
  border-radius: 19px;
}
.connected-list-container .list .item .right {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-color-1);
  flex: 1;
  overflow: hidden;
}
.connected-list-container .list .item .right .content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  color: var(--text-color-1);
}
.connected-list-container .list .item .right .oper {
  font-size: 14px;
  font-weight: 600;
  color: var(--message-error-color);
  cursor: pointer;
  min-width: 60px;
  text-align: right;
}
.disconnect-content {
  color: var(--text-color-1);
}
.disconnect-content .ant-drawer-header {
  padding-bottom: 0px;
}
.disconnect-content .ant-drawer-body {
  padding: 0 var(--text-distance-1);
}
.disconnect-content .modal-des {
  font-size: 14px;
  text-align: center;
  color: var(--text-color-1);
}
.disconnect-content .ant-modal-body {
  padding-top: 0;
}
.disconnect-content .modal-warn {
  text-align: center;
  color: var(--text-color-2);
  margin-top: 20px;
  margin-bottom: 30px;
}
.disconnect-content .btns {
  display: flex;
  gap: var(--text-distance-1);
  justify-content: space-between;
}
.disconnect-content .btns button {
  flex: 1;
}
.disconnect-content .btns button.ant-btn-primary {
  background: var(--message-error-color);
  color: #fff;
}
.disconnect-content .footer {
  padding-bottom: calc(constant(safe-area-inset-bottom) / 3);
  padding-bottom: calc(env(safe-area-inset-bottom) / 3);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 600px) {
  .disconnect-content .footer {
    display: none;
  }
}
.disconnect-content .footer .logo {
  width: 171px;
  height: 17px;
  background-image: var(--footer-icon-src);
  background-size: 100% 100%;
}
.add-address-dialog-container .copy-area {
  padding-top: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 14px;
}
.add-address-dialog-container .copy-area .wrap {
  cursor: pointer;
  width: fit-content;
  background-color: var(--background-color-1);
  color: var(--text-color-2);
  padding: 3px 10px;
  font-size: 13px;
  border-radius: 20px;
  white-space: nowrap;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.add-address-dialog-container .copy-area .wrap .copy-icon svg {
  color: var(--text-color-2);
}
.add-address-dialog-container .name-input {
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 18px;
  background-color: var(--input-bg-color);
  border-color: var(--input-bg-color);
}
.add-address-dialog-container .save-btn {
  width: 100%;
  font-weight: bold;
}
.add-address-dialog-container .ant-drawer-body {
  padding: 18px;
  padding-bottom: 10px;
  padding-top: 0;
}
.add-address-dialog-container .ant-modal-content .ant-modal-body {
  padding-bottom: 4px;
  padding-top: 4px;
}
@media screen and (min-width: 600px) {
  .footer-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    min-height: 40px;
    width: 100%;
  }
  .footer-logo-container .logo {
    width: 171px;
    height: 17px;
    background-image: var(--footer-icon-src);
    background-size: 100% 100%;
  }
}
._page_login .login-container {
  color: var(--text-color-1);
  font-size: 50px;
}
._page_login .login-container:after {
  display: none;
}
._page_login .login-container:before {
  display: none;
}
._page_login #particle-network-container {
  background: none !important;
}
._page_login #particle-network-container [name='particle-network-iframe'][src*='/login'] {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0 !important;
}
.div-input-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}
.div-input-wrap .ant-input {
  padding: 0;
  border: none !important;
  height: 22px;
  min-height: 22px;
  max-height: 44px;
  overflow-y: hidden;
  resize: none;
}
.wallet-connect-container .ant-drawer-content .ant-drawer-body {
  padding: 0;
}
.wallet-connect-container .content {
  position: relative;
  text-align: center;
  min-height: 400px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
}
@media screen and (min-width: 600px) {
  .wallet-connect-container .content {
    padding-left: 0;
    padding-right: 0;
  }
}
.wallet-connect-container .content .logo {
  width: 58px;
  height: 58px;
  margin-top: 23px;
  border-radius: 50%;
  overflow: hidden;
}
.wallet-connect-container .content .website_name {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: var(--text-color-1);
  opacity: 1;
  margin-top: 13px;
}
.wallet-connect-container .content .title {
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  color: var(--text-color-1);
  opacity: 1;
  margin-top: 33px;
  margin-bottom: 12px;
}
.wallet-connect-container .content .desc {
  width: 300px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: var(--text-color-2);
  opacity: 1;
  margin-top: 10px;
}
.wallet-connect-container .content .wallet_info_all {
  width: 339px;
  height: 72px;
  border-radius: 18px;
  margin-top: 25px;
  margin-bottom: 10px;
  background: var(--bg-color-5);
}
.wallet-connect-container .content .wallet_info_all .wallet_info {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.wallet-connect-container .content .wallet_info_all .wallet_info .wallet_info_left {
  margin-left: 20px;
  margin-top: 10px;
}
.wallet-connect-container .content .wallet_info_all .wallet_info .wallet_info_left img {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.wallet-connect-container .content .wallet_info_all .wallet_info .wallet_info_right {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 20px;
  align-items: flex-start;
}
.wallet-connect-container .content .wallet_info_all .wallet_info .wallet_info_right .right_wallet_name {
  font-size: 16px;
  font-weight: bold;
  line-height: 9px;
  color: var(--text-color-1);
}
.wallet-connect-container .content .wallet_info_all .wallet_info .wallet_info_right .right_address {
  font-size: 14px;
  font-weight: 400;
  line-height: 9px;
  margin-top: 10px;
  text-align: left;
  color: var(--text-color-2);
}
.wallet-connect-container .content .buttons {
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}
.wallet-connect-container .content .buttons button {
  flex: 1;
}
.wallet-connect-container .content .wallet_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
}
@media screen and (min-width: 600px) {
  .wallet-connect-container .content .wallet_footer {
    display: none;
  }
}
.wallet-connect-container .content .wallet_footer .logo_logo {
  width: 171px;
  height: 17px;
}
.switch-chain-container .ant-modal-body {
  padding-left: 0;
  padding-right: 0;
}
.switch-chain-container .content {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
}
.switch-chain-container .content .logo {
  width: 58px;
  height: 58px;
  margin-top: 23px;
}
.switch-chain-container .content .website_name {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: var(--text-color-1);
  opacity: 1;
  margin-top: 13px;
}
.switch-chain-container .content .title {
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  color: var(--text-color-1);
  opacity: 1;
  margin-top: 33px;
  padding: 0 var(--text-distance-1);
}
.switch-chain-container .content .switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 23px;
  margin-bottom: 40px;
}
.switch-chain-container .content .switch .left {
  width: 56px;
  height: 56px;
  border-radius: 56px;
  position: relative;
}
.switch-chain-container .content .switch .left .img {
  border-radius: 56px;
}
.switch-chain-container .content .switch .left .switch_word_left {
  position: absolute;
  bottom: -30px;
  left: -60px;
  width: 180px;
  text-align: center;
  color: var(--text-color-1);
}
.switch-chain-container .content .switch .center {
  width: 160px;
  height: 27px;
}
.switch-chain-container .content .switch .right {
  width: 56px;
  height: 56px;
  position: relative;
}
.switch-chain-container .content .switch .right .img {
  border-radius: 56px;
}
.switch-chain-container .content .switch .right .switch_word_right {
  position: absolute;
  bottom: -30px;
  left: -60px;
  width: 180px;
  text-align: center;
  color: var(--text-color-1);
}
.switch-chain-container .content .buttons {
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 0 var(--text-distance-1);
  margin-top: 30px;
}
.switch-chain-container .content .buttons button {
  flex: 1;
}
.switch-chain-container .content .wallet_footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  line-height: 40px;
}
@media screen and (min-width: 600px) {
  .switch-chain-container .content .wallet_footer {
    display: none;
  }
}
.switch-chain-container .content .wallet_footer .logo_logo {
  width: 171px;
  height: 17px;
}
.theme-light .appLoading .power-text {
  color: #666;
}
.theme-light .appLoading .logo-text {
  color: #000 !important;
}
.loading-box {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  width: 100vw;
  height: 100vh;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
}
.loading-box.appLoading,
.loading-box[data-mask='false'] {
  background: none;
  pointer-events: none;
}
.loading-box .power-text {
  color: #666;
  text-align: center;
  margin-top: 15px;
  font-size: 8px;
}
.loading-box .logo-text {
  color: #ffffff;
  text-align: center;
  font-size: 16px;
}
.loading-box .footer-style {
  margin-top: 30px;
  width: 200px;
}
.loading-box .lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 65px;
  margin-top: 40vh;
  margin-bottom: 20px;
}
.loading-box .lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: var(--text-color-1);
  border-radius: 6px;
  animation: lds-default 1.2s linear infinite;
}
.loading-box .lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.loading-box .lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.loading-box .lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.loading-box .lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.loading-box .lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.loading-box .lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.loading-box .lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.loading-box .lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.loading-box .lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.loading-box .lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.loading-box .lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.loading-box .lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%,
  20%,
  80%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
body.pc-page .camera-page-container .camera-wrap {
  width: 420px;
  height: 700px;
  position: relative;
  overflow: hidden;
}
body.pc-page .camera-page-container .camera-scan-border {
  margin-top: 130px;
}
body.pc-page .camera-page-container .camera-content {
  top: 470px;
}
body.pc-page .camera-page-container .camera-not-found {
  margin-top: 130px;
}
.qr-code-header {
  background: none;
}
.qr-code-header .wrapper .back-btn rect {
  fill: #fff;
  stroke: #fff;
}
.qr-code-header .wrapper .back-btn circle {
  fill: var(--address-icon-color) !important;
}
.qr-code-header .wrapper .middle {
  color: #fff;
}
@media screen and (max-width: 400px) {
  .qr-code-header .wrapper .middle span {
    font-size: 14px;
  }
}
@media screen and (max-width: 600px) {
  .camera-modal-container {
    background: #212324;
  }
}
.camera-modal-container.model-visible-0 {
  opacity: 0 !important;
}
.camera-modal-container .ant-modal {
  padding: 0;
  transition: none !important;
  transform: none !important;
}
@media screen and (max-width: 600px) {
  .camera-modal-container .ant-modal {
    top: 0 !important;
  }
}
.camera-modal-container .ant-modal-body {
  padding: 0;
}
.camera-modal-container .ant-modal-content {
  background: none;
}
@media screen and (max-width: 600px) {
  .camera-modal-container .ant-modal-content {
    border-radius: 0;
  }
  .camera-modal-container .ant-modal-content,
  .camera-modal-container .ant-modal-body,
  .camera-modal-container .camera-page-container {
    width: 100%;
    height: 100%;
  }
}
.input-wc-modal {
  z-index: 99999999;
}
.input-wc-modal .ant-modal-content {
  border-radius: 21px;
}
.input-wc-modal .ant-modal-content .modal-input-content {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  color: var(--text-color-1);
}
.input-wc-modal .ant-drawer-header {
  padding-bottom: 0;
}
.input-wc-modal .ant-drawer-body {
  padding: 18px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-wc-modal .ant-drawer-body .input-wc-title {
  margin: 0;
}
.input-wc-modal .ant-drawer-body .footer {
  padding-bottom: calc(constant(safe-area-inset-bottom) / 3);
  padding-bottom: calc(env(safe-area-inset-bottom) / 3);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-wc-modal .ant-drawer-body .footer .logo {
  width: 171px;
  height: 17px;
  background-image: var(--footer-icon-src);
  background-size: 100% 100%;
}
.input-wc-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px;
  margin-top: 18px;
  color: var(--text-color-1);
}
.input-wc-form {
  width: 100%;
}
.input-wc-form .ant-form-item {
  margin-bottom: 0px;
}
.input-wc-form .input-wc {
  width: 100%;
  margin: 15px 0 21px 0;
  height: 47px;
  opacity: 1;
  border-radius: 24px;
  padding: 0 16px;
  font-size: 16px;
  background: var(--bg-color-6);
}
.input-wc-form .input-wc-connect {
  width: 100%;
  position: relative;
}
.camera-page-container {
  overflow: hidden;
}
.camera-page-container .camera-wrap {
  width: 100%;
  height: 100%;
}
.camera-page-container input#qr-input-file {
  margin-bottom: 15px;
  background-color: var(--content-background-color);
  color: var(--text-color-1);
  border: 1px solid var(--text-color-1);
  width: 176px;
  height: 47px;
  border-radius: 18px;
  font-size: 16px;
}
.camera-page-container .header-box {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 10;
}
@media screen and (max-width: 600px) {
  .camera-page-container .header-box .header-container {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
.camera-page-container .header-box .connected-btn {
  background: none;
  position: absolute;
  text-align: center;
  display: flex;
  top: 18px;
  right: -2px;
  align-items: center;
  z-index: 10;
  padding: 0px 10px;
  font-size: 14px;
  color: #fff;
  height: 28px;
  line-height: 28px;
}
.camera-page-container .header-box .connected-btn:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--address-icon-color);
  opacity: 0.3;
  pointer-events: none;
  box-sizing: initial;
  animation: initial;
}
.camera-page-container .camera-login {
  position: absolute;
  width: 100%;
  z-index: 10;
  top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.camera-page-container .camera-login .camera-text {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: var(--text-color-1);
}
.camera-page-container .camera-scan-border {
  z-index: 9;
  position: absolute;
  width: 300px;
  height: 300px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-top: 10vh;
}
.camera-page-container .camera-scan-border .anticon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.camera-page-container .camera-scan-border:after {
  content: '';
  position: absolute;
  width: 256px;
  height: 256px;
  top: 22px;
  left: 22px;
  border-radius: 22px;
  box-shadow: 0 0 0 2000px var(--mack-color-1);
  z-index: -1;
}
.camera-page-container .camera-scan-border .scan-anime {
  background-image: url('/images/img_9_1.png');
  background-repeat: no-repeat;
  background-size: 300px 45px;
  width: 300px;
  height: 45px;
  -webkit-animation: 5s roll linear infinite normal;
  animation: 5s roll linear infinite normal;
}
@keyframes roll {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(500%);
    transform: translateY(500%);
  }
}
.camera-page-container .camera-not-found {
  position: absolute;
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 300px 300px;
  width: 300px;
  height: 300px;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  margin-top: 10vh;
}
.camera-page-container .camera-not-found .text-hint {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-color-2);
  text-align: center;
  line-height: 300px;
}
.camera-page-container .camera-content {
  position: absolute;
  width: 100%;
  top: calc(10vh + 330px);
  bottom: 70px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.camera-page-container .camera-content .camera-login {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  color: var(--text-color-1);
}
.camera-page-container .camera-content .camera-text {
  width: 210px;
  font-size: 20px;
  font-weight: 600;
  color: white;
  text-align: center;
}
.camera-page-container .camera-content .camera-text2 {
  font-size: 16px;
  width: 100%;
  font-weight: 600;
  color: white;
  text-align: center;
  padding: 0 30px;
  line-height: 1.2;
}
.camera-page-container .camera-content .camera-btn {
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.camera-page-container .camera-content .camera-btn button {
  min-width: 176px;
  height: 47px;
  border-radius: 18px;
  font-size: 16px;
  font-weight: 600;
}
.camera-page-container .camera-content .camera-btn .select-btn {
  margin-bottom: 15px;
  color: #fff;
  background: none;
  min-height: 47px;
  width: 100%;
}
.camera-page-container .camera-content .camera-btn .select-btn:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.2;
  pointer-events: none;
  box-sizing: initial;
  animation: initial;
}
.camera-page-container .camera-content .camera-btn .connect-btn {
  margin-bottom: 15px;
  border: none;
  min-height: 47px;
  width: 100%;
}
.camera-page-container .camera-success {
  margin-top: 38px;
  width: 200px;
  height: 200px;
  background: url('/images/duigou.png');
  -webkit-animation: success 1s steps(24) 200s infinite both;
  animation: success 1s steps(24) 200s infinite both;
}
@keyframes success {
  0% {
    background-position: 0 0;
  }
  4% {
    background-position: -100% 0;
  }
  8% {
    background-position: -200% 0;
  }
  12% {
    background-position: -300% 0;
  }
  16% {
    background-position: -400% 0;
  }
  20% {
    background-position: -500% 0;
  }
  24% {
    background-position: -600% 0;
  }
  28% {
    background-position: -700% 0;
  }
  32% {
    background-position: -800% 0;
  }
  36% {
    background-position: -900% 0;
  }
  40% {
    background-position: -1000% 0;
  }
  44% {
    background-position: -1100% 0;
  }
  48% {
    background-position: -1200% 0;
  }
  52% {
    background-position: -1300% 0;
  }
  56% {
    background-position: -1400% 0;
  }
  60% {
    background-position: -1500% 0;
  }
  64% {
    background-position: -1600% 0;
  }
  68% {
    background-position: -1700% 0;
  }
  72% {
    background-position: -1800% 0;
  }
  76% {
    background-position: -1900% 0;
  }
  80% {
    background-position: -2000% 0;
  }
  84% {
    background-position: -2100% 0;
  }
  88% {
    background-position: -2200% 0;
  }
  92% {
    background-position: -2300% 0;
  }
  96% {
    background-position: -2400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
#reader-scan-qrcode {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none !important;
  display: block;
  border-color: rgba(0, 0, 0, 0.48);
  z-index: 9;
  background: var(--bg-color-4);
}
#reader-scan-qrcode .preview-canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: cover;
}
#reader-scan-qrcode video {
  width: auto !important;
  height: auto !important;
  opacity: 0 !important;
}
#reader-scan-qrcode img[alt='Info icon'] {
  display: none;
}
#reader-scan-qrcode #qr-shaded-region {
  display: none;
}
.wallet-particle-approve-popup {
  position: fixed;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 18px;
  padding: 0px 18px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  -webkit-box-shadow: 4px 4px 20px 1px rgba(180, 180, 180, 0.6);
  box-shadow: 4px 4px 20px 1px rgba(180, 180, 180, 0.6);
  top: 30px;
  transition: right 0.3s, top 0.3s;
  right: -600px;
  width: auto;
  height: 78px;
  opacity: 0;
  padding-right: 0;
  display: none;
}
@media screen and (max-width: 600px) {
  .wallet-particle-approve-popup {
    max-width: 90%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding-left: 12px;
    right: 0;
    top: -100px;
    width: 90%;
  }
}
.wallet-particle-approve-popup.wallet-particle-approve-popup-display {
  display: flex;
  opacity: 1;
}
.wallet-particle-approve-popup.wallet-particle-approve-popup-show {
  right: 30px;
  opacity: 1;
}
@media screen and (max-width: 600px) {
  .wallet-particle-approve-popup.wallet-particle-approve-popup-show {
    right: 0;
    top: 30px;
    opacity: 1;
  }
}
.wallet-particle-approve-popup .wallet-particle-pam-left {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
}
.wallet-particle-approve-popup .wallet-particle-pam-left:after {
  content: '';
  position: absolute;
  width: 1px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  top: 0;
  right: 0px;
  height: 100%;
}
.wallet-particle-approve-popup .wallet-particle-pam-left .wallet-particle-pam-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.wallet-particle-approve-popup .wallet-particle-pam-left .wallet-particle-pam-wrap .wallet-particle-pam-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 42px;
  height: 42px;
  min-width: 42px;
  margin-right: 10px;
  border-radius: 42px;
  position: relative;
  overflow: hidden;
}
.wallet-particle-approve-popup .wallet-particle-pam-left .wallet-particle-pam-wrap .wallet-particle-pam-img img {
  width: 100%;
  height: 100%;
}
.wallet-particle-approve-popup .wallet-particle-pam-left .wallet-particle-pam-wrap .wallet-particle-pam-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 240px;
  min-width: 170px;
}
.wallet-particle-approve-popup .wallet-particle-pam-left .wallet-particle-pam-wrap .wallet-particle-pam-content .wallet-particle-pam-title {
  font-weight: bold;
  font-size: 17px;
  color: #181b1f;
}
.wallet-particle-approve-popup .wallet-particle-pam-left .wallet-particle-pam-wrap .wallet-particle-pam-content .wallet-particle-pam-text {
  font-size: 12px;
  color: #8b8ea0;
  opacity: 0.8;
  line-height: 12px;
  padding-right: 8px;
}
.wallet-particle-approve-popup .wallet-particle-pam-right {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #2d6af6;
  font-weight: bold;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 10px;
  min-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wallet-particle-approve-popup .wallet-particle-pam-right:hover {
  color: #2d6af6;
  font-weight: bold;
}
.icon-demo-container {
  background: #1d1d22;
}
.icon-demo-container .wrapper {
  width: 840px;
  margin: auto;
}
.icon-demo-container .wrapper .list {
  background: #000;
  height: 100%;
  border-radius: 18px;
  overflow: auto;
}
.icon-demo-container .wrapper .item {
  display: inline-block;
  text-align: center;
  margin-top: 20px;
}
.icon-demo-container .wrapper .item .name {
  font-size: 12px;
  color: #fff;
}
.icon-demo-container .wrapper .item .icon-wrap {
  width: 120px;
  height: 120px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon-demo-container .wrapper .item .icon-wrap .anticon {
  font-size: 60px;
  transition: font-size 0.2s linear, width 0.2s linear;
}
.icon-demo-container .wrapper .item .icon-wrap:hover .anticon {
  font-size: 120px;
}
.icon-demo-container .wrapper {
  justify-content: center;
}
.icon-demo-container .color-content {
  display: flex;
  gap: 40px;
  justify-content: center;
  margin-top: 340px;
}
.icon-demo-container .color-content .color-item-title {
  color: #fff;
  font-size: 16px;
}
.theme-light .icon-demo-container .wrapper .list {
  background: #fff;
}
.theme-light .icon-demo-container .wrapper .list .name {
  color: #000;
}
.hidden-token-list-container > .wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--content-background-color) !important;
}
.hidden-token-list-container > .wrapper .no-record-container {
  padding-top: 20vh;
}
.hidden-token-list-container .foot-logo {
  background-image: var(--footer-icon-src);
  background-size: 100% auto;
  width: 200px;
  height: 21px;
  margin-bottom: 10px;
  left: calc(50% - 100px);
  position: fixed;
  bottom: 10px;
}
@media screen and (max-width: 600px) {
  .hidden-token-list-container .foot-logo {
    display: none;
  }
}
.custom-style-icon {
  position: fixed;
  bottom: 290px;
  right: 3vh;
  z-index: 99;
  background: #7673f9;
  box-shadow: 0px 0px 4px 4px rgba(255, 255, 255, 0.1);
  cursor: pointer;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 600px) {
  .custom-style-icon {
    display: none;
  }
}
.custom-style-popover {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-style-popover .ant-popover-title {
  height: 60px;
  line-height: 50px;
  font-size: 18px;
}
.color-popover .ant-popover-inner-content {
  padding: 0;
}
.tab-content {
  width: 442px;
  height: 446px;
}
.tab-content.json-content {
  overflow-y: auto;
  user-select: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  position: relative;
}
.tab-content.json-content .copy-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.color-list .item {
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0 16px;
}
.color-list .item:hover {
  background: #f5f5f5;
}
.color-list .item .label {
  margin-right: 10px;
  width: 270px;
}
.color-list .item .color-box {
  width: 130px;
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.color-list .item .color-box .color {
  width: 18px;
  height: 18px;
  border: 1px solid #c0beff;
  cursor: pointer;
}
.color-list .item .color-box .color:hover {
  border-color: #7673f9;
  border-width: 2px;
}
.icon-button-default {
  overflow: hidden;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  position: relative;
  background: var(--primaryIconButtonBackgroundColor);
}
.icon-button-default .micon {
  width: 100%;
  height: 100%;
  position: relative;
}
.icon-button-default .micon svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.erc4337-transaction-container .ant-drawer-content {
  overflow: hidden;
  background: var(--bg-color-4);
  color: var(--text-color-1);
}
.erc4337-transaction-container .ant-drawer-content .ant-drawer-header {
  border-bottom: none;
}
.erc4337-transaction-container .ant-drawer-content .ant-drawer-header .ant-drawer-close {
  color: var(--text-color-1);
  position: absolute;
  right: 6px;
}
.erc4337-transaction-container .ant-drawer-content .ant-drawer-header .ant-drawer-title {
  color: var(--text-color-1);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.erc4337-transaction-container .ant-drawer-content .ant-drawer-body {
  width: 80%;
  max-width: 1000px;
  margin: auto;
  padding: 0 16px;
}
@media screen and (max-width: 600px) {
  .erc4337-transaction-container .ant-drawer-content .ant-drawer-body {
    width: 100%;
  }
}
.erc4337-modal-container {
  z-index: 999899 !important;
}
.erc4337-modal-container .ant-modal-footer {
  display: none;
}
.erc4337-modal-container .ant-modal-body {
  padding-left: 30px;
  padding-right: 30px;
}
.erc4337-modal-container .ant-modal-content .ant-modal-body .wallet_footer {
  display: none;
}
.fee-drawer-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}
.fee-drawer-content .fee-list {
  max-height: 70vh;
  width: 100%;
  overflow: auto;
}
.fee-drawer-content .fee-list .gas-fee-item {
  display: flex;
  align-items: center;
  padding: 12px 10px;
  background: var(--border-color-1);
  border: 1px solid var(--border-color-1);
  border-radius: 14px;
  cursor: pointer;
  margin-bottom: 10px;
  height: 60px;
}
.fee-drawer-content .fee-list .gas-fee-item[data-selected='true'] {
  border-color: var(--accent-color);
  position: relative;
  overflow: hidden;
}
.fee-drawer-content .fee-list .gas-fee-item[data-selected='true']::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--accent-color);
  opacity: 0.1;
  pointer-events: none;
  box-sizing: initial;
  animation: initial;
}
.fee-drawer-content .fee-list .gas-fee-item.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.fee-drawer-content .fee-list .gas-fee-item .fee-radio {
  margin-right: 10px;
  color: var(--accent-color);
}
.fee-drawer-content .fee-list .gas-fee-item .fee-radio[data-checked='false'] {
  color: var(--text-color-3);
}
.fee-drawer-content .fee-list .gas-fee-item .fee-token-icon {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  object-fit: contain;
}
.fee-drawer-content .fee-list .gas-fee-item .fee-token-info {
  margin-left: 12px;
  font-size: 15px;
}
.fee-drawer-content .fee-list .gas-fee-item .fee-token-info .fee-name {
  font-weight: 500;
  color: var(--text-color-1);
}
.fee-drawer-content .fee-list .gas-fee-item .fee-token-info .fee-address {
  font-size: 13px;
  color: var(--text-color-2);
}
.fee-drawer-content .fee-list .gas-fee-item .fee-token-info .universal-tag-wrap {
  width: 130px;
  overflow: hidden;
}
@media screen and (max-width: 350px) {
  .fee-drawer-content .fee-list .gas-fee-item .fee-token-info .universal-tag-wrap {
    width: 87px;
  }
}
.fee-drawer-content .fee-list .gas-fee-item .fee-token-info .universal-tag {
  background: #a257fa;
  height: 20px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  padding-top: 1px;
  transform: scale(0.8);
  position: relative;
  left: -12px;
  min-width: 135px;
}
@media screen and (max-width: 350px) {
  .fee-drawer-content .fee-list .gas-fee-item .fee-token-info .universal-tag {
    transform: scale(0.6);
    left: -26px;
  }
}
.fee-drawer-content .fee-list .gas-fee-item .fee-token-balance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
  flex: 1;
  overflow: hidden;
  color: var(--text-color-1);
}
.fee-drawer-content .fee-list .gas-fee-item .fee-token-balance .gas-fee {
  font-size: 15px;
  color: #ea4335;
  font-weight: 500;
}
@media screen and (max-width: 350px) {
  .fee-drawer-content .fee-list .gas-fee-item .fee-token-balance .gas-fee {
    font-size: 12px;
  }
}
.fee-drawer-content .fee-list .gas-fee-item .fee-token-balance .token-balance {
  font-size: 13px;
  color: var(--text-color-2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: right;
}
@media screen and (max-width: 350px) {
  .fee-drawer-content .fee-list .gas-fee-item .fee-token-balance .token-balance {
    font-size: 12px;
  }
}
.fee-drawer-content .bootom-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  width: 100%;
}
.fee-drawer-content .bootom-action .btn-gasless {
  width: 48%;
  font-size: 17px;
}
.fee-drawer-content .bootom-action .btn-user-paid {
  width: 100%;
  font-size: 17px;
  transition: none;
  height: 47px;
}
.fee-drawer-content .wallet_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
}
.fee-drawer-content .wallet_footer .logo_logo {
  width: 171px;
  height: 17px;
}
.fee-drawer-content .particle-cloudflare-iframe {
  position: absolute !important;
  left: 50%;
  top: auto !important;
  transform: translateX(-50%) !important;
  bottom: 60px !important;
}
@media screen and (max-width: 600px) {
  .fee-drawer-content .particle-cloudflare-iframe {
    bottom: 100px !important;
  }
}
.experimental-container > .wrapper {
  background-color: var(--content-background-color) !important;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.experimental-container > .wrapper .list .item.use-smart-account {
  margin-bottom: 0;
}
.experimental-container > .wrapper .list .item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 var(--text-distance-1);
  padding: 0 var(--text-distance-2);
  height: 60px;
  color: var(--text-color-1);
  background: var(--background-color-1);
  border-radius: 18px;
  margin-bottom: 10px;
}
.experimental-container > .wrapper .list .item.float {
  background: transparent;
  font-size: 16px;
  font-weight: 600;
}
.experimental-container > .wrapper .list .item .left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.experimental-container > .wrapper .list .item .left .item-title {
  color: var(--text-color-1);
  font-size: 14px;
  font-weight: 600;
}
.experimental-container > .wrapper .list .item .left .icon {
  display: flex;
  align-items: center;
}
.experimental-container > .wrapper .list .item .ant-switch.ant-switch-disabled {
  background: var(--border-color-0);
  opacity: var(--disbled-button-opacity);
}
.experimental-container > .wrapper .list .item .right {
  width: 14px;
  height: 14px;
}
.experimental-container > .wrapper .list .item .right .icon.selected {
  width: 100%;
  height: 100%;
  background-image: var(--icon-src-8);
  background-size: 100% 100%;
}
.deploy-wallet-contract-modal .ant-modal-footer {
  display: none;
}
.deploy-wallet-contract-modal .ant-modal-content {
  border-radius: 13px;
}
.deploy-wallet-contract-modal .ant-modal-content .ant-modal-close {
  color: var(--text-color-1);
}
.deploy-wallet-contract-modal .ant-modal-content .ant-modal-header {
  background: transparent;
  border-bottom: none;
}
.deploy-wallet-contract-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  text-align: center;
}
.deploy-wallet-contract-modal .ant-modal-content .ant-modal-body .wallet_footer {
  display: none;
}
.deploy-wallet-contract-drawer .ant-drawer-content {
  overflow: hidden;
  background: var(--bg-color-4);
  color: var(--text-color-1);
}
.deploy-wallet-contract-drawer .ant-drawer-content .ant-drawer-body {
  width: 80%;
  max-width: 968px;
  margin: auto;
  padding: 0 16px;
}
@media screen and (max-width: 600px) {
  .deploy-wallet-contract-drawer .ant-drawer-content .ant-drawer-body {
    width: 100%;
  }
}
.deploy-wallet-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.deploy-wallet-content .deploy-tip {
  font-size: 20px;
  font-weight: 500;
  color: var(--text-color-1);
  margin: 40px 0 8px 0;
  text-align: center;
}
@media screen and (max-width: 600px) {
  .deploy-wallet-content .deploy-tip {
    margin-top: 0;
  }
}
.deploy-wallet-content .bootom-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0 0 0;
  width: 100%;
}
.deploy-wallet-content .bootom-action .btn-modal {
  width: 48%;
  font-size: 17px;
}
.deploy-wallet-content .wallet_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
}
.deploy-wallet-content .wallet_footer .logo_logo {
  width: 171px;
  height: 17px;
}
.did-popover {
  width: 80%;
  max-width: 800px;
}
.did-popover .ant-popover-content {
  top: 10px;
  right: 2px;
}
.did-popover .ant-popover-arrow {
  display: none;
}
.did-popover .ant-popover-inner {
  box-shadow: none;
}
.did-popover .ant-popover-inner-content {
  padding: 0;
}
.did-popover .ant-popover-inner-content .did-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  overflow-x: auto;
  width: 100%;
  gap: 10px;
}
.did-popover .ant-popover-inner-content .did-content .did-item {
  font-size: 10px;
  font-weight: 500;
  height: 24px;
  text-align: center;
  border-radius: 12px;
  padding: 0 8px;
  line-height: 24px;
  flex-direction: row;
  display: flex;
  align-items: center;
  color: #33c759ff;
  background: rgba(51, 199, 89, 0.39);
  gap: 5px;
  white-space: nowrap;
  cursor: pointer;
}
.did-popover .ant-popover-inner-content .did-content .did-item .did-copy-icon {
  font-size: 14px;
}
.scrollContainer {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: var(--text-color-1);
}
.scrollContainer .linear-gradient-up {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 10px;
}
.scrollContainer .linear-gradient-down {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  height: 10px;
}
.double-arrows-container [data-pathname='/tokenDetail.html'],
.double-arrows-container [data-pathname='/tokenDetail'],
.double-arrows-container [data-pathname='tokenDetail.html'] {
  width: 30px !important;
  height: 30px !important;
  position: relative;
}
.double-arrows-container [data-pathname='/tokenDetail.html'] .close-icon,
.double-arrows-container [data-pathname='/tokenDetail'] .close-icon,
.double-arrows-container [data-pathname='tokenDetail.html'] .close-icon {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0 !important;
  left: 7px !important;
}
.double-arrows-container [data-pathname='/tokenDetail.html'] .close-icon .anticon,
.double-arrows-container [data-pathname='/tokenDetail'] .close-icon .anticon,
.double-arrows-container [data-pathname='tokenDetail.html'] .close-icon .anticon {
  position: absolute;
  top: 0;
  left: 0;
}
.double-arrows-container [data-pathname='/tokenDetail.html'] .double-arrow-icon,
.double-arrows-container [data-pathname='/tokenDetail'] .double-arrow-icon,
.double-arrows-container [data-pathname='tokenDetail.html'] .double-arrow-icon {
  position: absolute;
  top: -3px !important;
  left: 7px !important;
}
.double-arrows-container [data-pathname='/tokenDetail.html'] .double-arrow-icon .anticon,
.double-arrows-container [data-pathname='/tokenDetail'] .double-arrow-icon .anticon,
.double-arrows-container [data-pathname='tokenDetail.html'] .double-arrow-icon .anticon {
  position: absolute;
  top: 0;
  left: 0;
}
.double-arrows-container .double_arrow {
  cursor: pointer;
  display: block;
  background: none;
  width: 50px;
  height: 50px;
  position: relative;
  top: 0px;
  left: -10px;
  margin-right: -10px;
}
.double-arrows-container .double_arrow .double-arrow-icon {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 10px;
  left: 8px;
}
.double-arrows-container .double_arrow .double-arrow-icon svg {
  color: var(--text-color-1);
}
.double-arrows-container .double_arrow .close-icon {
  display: none;
  position: absolute;
  top: 10px;
  left: 5px;
}
.double-arrows-container .double_arrow.close {
  background: none;
}
.double-arrows-container .double_arrow.close .double-arrow-icon {
  display: none;
}
.double-arrows-container .double_arrow.close .close-icon {
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  top: 10px;
  left: 8px;
}
