.connected-list-container {
  > .wrapper {
    background-color: var(--content-background-color) !important;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    height: 0px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .content-wrapper {
      height: 0;
      flex: 1;
      .no-record-container {
        height: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5vh;
      }
    }
  }

  .list {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 60px;
      margin: 0 var(--text-distance-1);
      padding: 0 var(--text-distance-2);
      height: 60px;
      color: var(--text-color-1);
      cursor: pointer;
      background: var(--background-color-1);
      border-radius: 18px;
      margin-bottom: 10px;
      .left {
        display: flex;
        align-items: center;

        .logo {
          width: 38px;
          height: 38px;
          margin: 0 12px;
          margin-left: 0;
          border-radius: 19px;
        }
      }
      .right {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--border-color-1);
        flex: 1;
        overflow: hidden;
        // padding-right: 20px;
        .content {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: 14px;
          font-weight: 600;
          color: var(--text-color-1);
        }
        .oper {
          font-size: 14px;
          font-weight: 600;
          color: var(--message-error-color);
          cursor: pointer;
          min-width: 60px;
          text-align: right;
        }
      }
    }
  }
}
.disconnect-content {
  color: var(--text-color-1);
  .ant-drawer-header {
    padding-bottom: 0px;
  }
  .ant-drawer-body {
    padding: 0 var(--text-distance-1);
  }
  .modal-des {
    font-size: 14px;
    text-align: center;
    color: var(--text-color-1);
  }
  .ant-modal-body {
    padding-top: 0;
  }
  .modal-warn {
    text-align: center;
    color: var(--text-color-2);
    margin-top: 20px;
    margin-bottom: 30px;
  }
  .btns {
    display: flex;
    gap: var(--text-distance-1);
    justify-content: space-between;
    button {
      flex: 1;
      &.ant-btn-primary {
        background: var(--message-error-color);
        color: #fff;
      }
    }
  }
  .footer {
    padding-bottom: calc(constant(safe-area-inset-bottom) / 3);
    padding-bottom: calc(env(safe-area-inset-bottom) / 3);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 600px) {
      display: none;
    }
    .logo {
      width: 171px;
      height: 17px;
      background-image: var(--footer-icon-src);
      background-size: 100% 100%;
    }
  }
}
