.did-popover {
  width: 80%;
  max-width: 800px;
  .ant-popover-content {
    top: 10px;
    right: 2px;
  }
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    box-shadow: none;
  }
  .ant-popover-inner-content {
    padding: 0;
    .did-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      overflow-x: auto;
      width: 100%;
      gap: 10px;
      .did-item {
        font-size: 10px;
        font-weight: 500;
        height: 24px;
        text-align: center;
        border-radius: 12px;
        padding: 0 8px;
        line-height: 24px;
        flex-direction: row;
        display: flex;
        align-items: center;
        color: #33c759ff;
        background: rgba(51, 199, 89, 0.39);
        gap: 5px;
        white-space: nowrap;
        cursor: pointer;
        // &.address {
        //   background: rgba(255, 0, 0, 0);
        //   color: var(--text-color-2);
        //   position: relative;
        //   overflow: hidden;
        //   ::after {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background: var(--address-icon-color);
        //     opacity: 0.15;
        //     pointer-events: none;
        //     box-sizing: initial;
        //     animation: initial;
        //   }
        // }
        .did-copy-icon {
          font-size: 14px;
        }
      }
    }
  }
}
