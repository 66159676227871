.drawer-receive-container {
  .ant-drawer-content {
    overflow: hidden;
    background-color: var(--bg-color-4);
    .ant-drawer-body {
      padding: 24px;
      padding-bottom: 0;
    }
    .ant-drawer-header {
      border: none;
      background-color: var(--bg-color-4);
      .ant-drawer-close {
        display: none;
      }
      .ant-drawer-title {
        text-align: center;
        font-size: 20px;
        line-height: 1;
        color: var(--text-color-1);
      }
    }
    .ant-drawer-extra {
      font-size: 18px;
      color: var(--text-color-1);
    }
  }
  .content {
    text-align: center;
    .tips-Nx9 {
      font-size: 16px;
      line-height: 19px;
      color: var(--message-error-color);
      margin-bottom: 22px;
    }
    .qr-code {
      display: flex;
      justify-content: center;
      margin: auto;
      margin-bottom: 22px;
      width: 230px;
      height: 230px;
      border: 1px solid var(--button-color-1);
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon,
      .canvas {
        width: 200px !important;
        height: 200px !important;
      }
    }
    .address {
      width: 230px;
      padding: 8px 0px;
      // background: var(--background-color-1);
      border-radius: 8px;
      font-size: 14px;
      line-height: 16px;
      color: var(--text-color-2);
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 22px;
      span {
        word-break: break-all;
        word-wrap: break-word;
        white-space: pre-wrap;
      }
      .micon {
        display: none;
      }
    }
    .copy-address {
      button {
        width: 100%;
      }
    }
  }
  .footer {
    padding-bottom: calc(constant(safe-area-inset-bottom) / 3);
    padding-bottom: calc(env(safe-area-inset-bottom) / 3);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 600px) {
      display: none;
    }
    .logo {
      width: 171px;
      height: 17px;
      background-image: var(--footer-icon-src);
      background-size: 100% 100%;
    }
  }
  .content-wallet {
    margin: -24px -24px 0;
    padding-bottom: 94px;
    background-color: var(--content-background-color);
    .wallet-line {
      position: absolute;
      width: 100%;
      border-top: 2px solid var(--border-color-0);
      z-index: 10;
    }
    .list {
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 58px;
        padding: 0 28px;
        border-bottom: 2px solid var(--border-color-1);
        background: var(--content-background-color);
        .left {
          display: flex;
          align-items: center;
          font-size: 15px;
          font-weight: 500;
          color: var(--text-color-1);
          .left-img {
            margin-right: 16px;
            width: 36px;
            height: 36px;
          }
        }
        .right {
          width: 14px;
          height: 14px;
          .icon {
            width: 100%;
            height: 100%;
            background-image: var(--icon-src-8);
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .footer-switch {
    border-top: 2px solid var(--border-color-1);
    padding: 20px 27px 0;
    left: 0;
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    color: var(--text-color-1);
    position: absolute;
    bottom: 0;
    z-index: 10;
    background-color: var(--bg-color-4);
    .footer-son {
      // height: 60px;
      display: flex;
      // align-items: center;
      justify-content: space-between;
    }
  }
}
.receive-model {
  * {
    color: #000;
  }
  .ant-modal {
    width: 375px !important;
  }
  .ant-modal-title {
    text-align: center;
  }
  .ant-modal-header {
    background: #fff;
    border-color: #fff;
  }

  .ant-modal-content {
    background: #fff;
    border-radius: 13px;
    overflow: hidden;
    text-align: center;
    .ant-modal-body {
      padding-top: 10px;
      .content {
        .tips-Nx9 {
          color: #ff4444;
          margin-bottom: 20px;
        }
        .qr-code {
          svg {
            width: 148px !important;
            height: 148px !important;
          }
        }
        .address {
          margin: auto;
          line-height: 1.1;
          margin-top: 11px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background: var(--background-color-1);
          border-radius: 10px;
          height: 50px;
          padding: 0 14px;
          width: 80%;
          position: relative;
          overflow: hidden;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--address-icon-color);
            opacity: 0.06;
            pointer-events: none;
          }
          span {
            width: 210px;
            color: var(--text-color-2);
          }
        }
        .copy-address {
          display: none;
        }
      }
    }
  }

  .ant-modal-footer {
    display: none;
  }
}
.theme-dark {
  .receive-model {
    * {
      color: #fff;
    }
    .ant-modal-header {
      background: var(--bg-color-4);
      border-color: #212324;
    }
  }
  .ant-modal-content {
    background: var(--bg-color-4);
    .ant-modal-header {
      background: var(--bg-color-4);
      .ant-modal-title {
        color: #fff;
      }
    }
    .ant-modal-close {
      color: #fff;
    }
    .ant-modal-body {
      .content {
        .address {
          // background: rgba(0, 0, 0, 0.6);
          span {
            color: var(--text-color-2);
          }
        }
      }
    }
  }
}
