.add-custom-token-result-container {
  overflow: hidden !important;
  > .wrapper {
    background-color: var(--content-background-color) !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 600px) {
      min-height: initial;
    }
  }
  .add-custom-token-main {
    padding-top: 10px;
    height: 0;
    flex: 1;
    position: relative;
    .tips-Nx9 {
      font-size: 14px;
      font-weight: 500;
      color: var(--text-color-1);
      height: 40px;
      padding: 0 22px;
      text-align: center;
    }
    .form-content {
      padding-top: 14px;
      .result-content {
        padding: 0 14px;
        font-size: 16px;
        line-height: 19px;
        color: var(--text-color-1);
        font-weight: bold;
        .row {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &.row1 {
            .left {
              color: var(--text-color-2);
              font-weight: 400;
            }
          }
          &.row2 {
            font-size: 18px;
            height: 56px;
            border-radius: 18px;
            background-color: var(--background-color-1);
            margin-top: 14px;
            padding: 0px 14px;
            .left {
              display: flex;
              align-items: center;

              .icon {
                width: 32px;
                height: 32px;
                margin-right: 8px;
                border-radius: 100%;
                overflow: hidden;
                object-fit: contain;
              }
            }
            .right {
              font-weight: 400;
              color: var(--text-color-1);
            }
          }
        }
      }
      .form-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 12px;
        z-index: 1;
        margin-bottom: 0;
        padding-bottom: calc(var(--footer-height) - 8px + constant(safe-area-inset-bottom) / 3);
        background-color: var(--content-background-color);
        @media screen and (max-width: 600px) {
          position: fixed;
          bottom: 40px;
        }
        @media screen and (max-width: 600px) and (max-height: 600px) {
          position: fixed;
          bottom: 0;
        }
        @media screen and (max-height: 600px) {
          height: 56px;
        }
        @media screen and (min-width: 600px) {
          height: 46px;
          align-items: center;
          padding: 0;
        }
        .ant-form-item-control-input-content {
          display: flex;
          justify-content: center;
          @media screen and (min-width: 600px) {
            padding: 0 70px;
          }
          .ant-btn-primary {
            width: 380px;
            max-width: 100%;
            font-size: 17px;
            font-weight: bold;
            @media screen and (max-width: 600px) {
              width: 94%;
            }
          }
        }
      }
    }
  }
}
