.experimental-container {
  & > .wrapper {
    background-color: var(--content-background-color) !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    .list {
      .item.use-smart-account {
        margin-bottom: 0;
      }
      .item {
        cursor: pointer;
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 var(--text-distance-1);
        padding: 0 var(--text-distance-2);
        height: 60px;
        color: var(--text-color-1);
        background: var(--background-color-1);
        border-radius: 18px;
        margin-bottom: 10px;
        &.float {
          background: transparent;
          font-size: 16px;
          font-weight: 600;
        }
        .left {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 6px;
          .item-title {
            color: var(--text-color-1);
            font-size: 14px;
            font-weight: 600;
          }
          .icon {
            display: flex;
            align-items: center;
          }
        }

        .ant-switch.ant-switch-disabled {
          background: var(--border-color-0);
          opacity: var(--disbled-button-opacity);
        }
        .right {
          width: 14px;
          height: 14px;
          .icon.selected {
            width: 100%;
            height: 100%;
            background-image: var(--icon-src-8);
            background-size: 100% 100%;
          }
        }
      }
    }
  }
}
