.header-container {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background: var(--content-background-color);
  height: var(--header-height);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  .wrapper {
    height: var(--header-height);
    padding: 0 var(--text-distance-1);
    line-height: var(--header-height);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 36px;
      height: 100%;
      display: flex;
      align-items: center;
      .back-btn-wrap {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        left: -8px;
        &.hidden {
          display: none;
        }
        .back-btn {
          width: 19px;
          height: 19px;
          line-height: 1;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
    }
    .middle {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 600;
      color: var(--text-color-1);
      line-height: 1;
    }
    .right {
      width: 36px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: relative;
    }
  }
  &.header-search-container {
    .middle {
      .wrap {
        width: 100%;
        .ant-input-affix-wrapper {
          background: var(--background-color-1);

          border-radius: 32px;
          border: 1px solid var(--background-color-1);
          &[data-focus='true'] {
            border-color: var(--accent-color);
          }
          .anticon-close-circle {
            color: var(--text-color-3);
          }
          .ant-input-prefix {
            .anticon {
              color: var(--text-color-3);
            }
          }
        }
      }
    }
    .right {
      width: 0px;
    }
  }
}

._page_buy {
  .back-btn {
    background: none !important;
    --text-color: #000;
  }
}
