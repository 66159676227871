.chat {
  .chat-icon {
    position: fixed;
    bottom: 30px;
    right: 30px;
    width: 40px;
    height: 40px;
    z-index: 10;
    cursor: pointer;
    max-width: var(--widget-width, 400px);
    max-height: var(----widget-height, 680px);
    overflow: hidden;
  }
  :global {
    color: var(--text-color-1);
    .hidden-icon {
      path {
        fill: var(--primary-icon-text-color, #fff);
      }
    }
  }

  .chat-component-modal {
    position: fixed;
    z-index: 10;
    bottom: 80px;
    right: 10px;
  }
}
