.icon-button-default {
  overflow: hidden;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  position: relative;
  background: var(--primaryIconButtonBackgroundColor);

  .micon {
    width: 100%;
    height: 100%;
    position: relative;
    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
