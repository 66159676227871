.slip-modal {
  .ant-modal-content {
    overflow: hidden;
    text-align: center;
    .ant-modal-body {
      padding-top: 18px;
      padding-bottom: 28px;
      .slip-content {
        display: flex;
        flex-direction: column;
        .continue-btn {
          font-size: 17px;
          font-weight: 600;
          margin-top: 35px;
        }
        .ant-radio-group {
          width: 100%;
          display: flex;
          justify-content: space-between;
          .ant-radio-button-wrapper {
            width: 74px;
            height: 38px;
            color: var(--text-color-2);
            background-color: var(--border-color-1);
            border: none;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            &:before {
              display: none;
            }
            &.ant-radio-button-wrapper-checked {
              color: var(--text-color-1);
              background: var(--accent-color);
              color: #fff;
            }
          }
        }
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
}
.slip-drawer {
  .ant-drawer-content {
    overflow: hidden;
    background-color: var(--bg-color-4);
    .ant-drawer-body {
      padding: 18px;
      padding-bottom: 0;
      background-color: var(--bg-color-4);
    }
    .ant-drawer-header {
      border: none;
      background-color: var(--bg-color-4);
      .ant-drawer-close {
        display: none;
      }
      .ant-drawer-title {
        text-align: center;
        font-size: 20px;
        line-height: 12px;
        color: var(--text-color-1);
      }
    }
    .ant-drawer-extra {
      font-size: 18px;
      color: var(--text-color-1);
    }
    .slip-content {
      display: flex;
      flex-direction: column;
      padding-bottom: 0px;
      .continue-btn {
        font-size: 17px;
        font-weight: 600;
        margin-top: 35px;
      }
      .ant-radio-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .ant-radio-button-wrapper {
          width: 74px;
          height: 38px;
          color: var(--text-color-2);
          background-color: var(--border-color-1);
          border: none;
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:before {
            display: none;
          }
          &.ant-radio-button-wrapper-checked {
            color: var(--text-color-1);
            background: var(--accent-color);
            color: #fff;
          }
        }
      }
      .footer {
        padding-bottom: calc(constant(safe-area-inset-bottom) / 3);
        padding-bottom: calc(env(safe-area-inset-bottom) / 3);
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo {
          width: 171px;
          height: 17px;
          background-image: var(--footer-icon-src);
          background-size: 100% 100%;
        }
      }
    }
  }
}
