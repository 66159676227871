.scrollContainer {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: var(--text-color-1);
  .linear-gradient-up {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 999;
    height: 10px;
  }

  .linear-gradient-down {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height: 10px;
  }
}
