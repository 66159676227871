body.pc-page {
  .camera-page-container {
    .camera-wrap {
      width: 420px;
      height: 700px;
      position: relative;
      overflow: hidden;
    }
    .camera-scan-border {
      margin-top: 130px;
    }
    .camera-content {
      top: 470px;
    }
    .camera-not-found {
      margin-top: 130px;
    }
  }
}
.qr-code-header {
  background: none;
  .wrapper {
    .back-btn {
      rect {
        fill: #fff;
        stroke: #fff;
      }
      circle {
        fill: var(--address-icon-color) !important;
      }
    }
    .middle {
      color: #fff;
      span {
        @media screen and (max-width: 400px) {
          font-size: 14px;
        }
      }
    }
  }
}
.camera-modal-container {
  @media screen and (max-width: 600px) {
    background: rgb(33, 35, 36);
  }

  &.model-visible-0 {
    opacity: 0 !important;
  }

  .ant-modal {
    padding: 0;
    transition: none !important;
    transform: none !important;
    @media screen and (max-width: 600px) {
      top: 0 !important;
    }
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-content {
    background: none;
  }
  @media screen and (max-width: 600px) {
    .ant-modal-content {
      border-radius: 0;
    }
    .ant-modal-content,
    .ant-modal-body,
    .camera-page-container {
      width: 100%;
      height: 100%;
    }
  }
}

.input-wc-modal {
  z-index: 99999999;
  .ant-modal-content {
    border-radius: 21px;

    .modal-input-content {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: column;
      color: var(--text-color-1);
    }
  }
  .ant-drawer-header {
    padding-bottom: 0;
  }
  .ant-drawer-body {
    padding: 18px;
    padding-top: 0;
    padding-bottom: 0;
    .input-wc-title {
      margin: 0;
    }
    .footer {
      padding-bottom: calc(constant(safe-area-inset-bottom) / 3);
      padding-bottom: calc(env(safe-area-inset-bottom) / 3);
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo {
        width: 171px;
        height: 17px;
        background-image: var(--footer-icon-src);
        background-size: 100% 100%;
      }
    }
  }
}
.input-wc-title {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px;
  margin-top: 18px;
  color: var(--text-color-1);
}
.input-wc-form {
  width: 100%;
  .ant-form-item {
    margin-bottom: 0px;
  }
  .input-wc {
    width: 100%;
    margin: 15px 0 21px 0;
    height: 47px;
    opacity: 1;
    border-radius: 24px;
    padding: 0 16px;
    font-size: 16px;
    background: var(--bg-color-6);
  }
  .input-wc-connect {
    width: 100%;
    position: relative;
  }
}
.camera-page-container {
  overflow: hidden;
  .camera-wrap {
    width: 100%;
    height: 100%;
  }
  input#qr-input-file {
    margin-bottom: 15px;
    background-color: var(--content-background-color);
    color: var(--text-color-1);
    border: 1px solid var(--text-color-1);
    width: 176px;
    height: 47px;
    border-radius: 18px;
    font-size: 16px;
  }
  .header-box {
    // background-color: var(--content-background-color);
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 10;
    .header-container {
      @media screen and (max-width: 600px) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
    .connected-btn {
      background: none;
      position: absolute;
      text-align: center;
      display: flex;
      top: 18px;
      right: -2px;
      align-items: center;
      z-index: 10;
      padding: 0px 10px;
      font-size: 14px;
      color: #fff;
      height: 28px;
      line-height: 28px;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--address-icon-color);
        opacity: 0.3;
        pointer-events: none;
        box-sizing: initial;
        animation: initial;
      }
    }
  }
  .camera-login {
    position: absolute;
    width: 100%;
    z-index: 10;
    top: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .camera-text {
      font-size: 24px;
      font-weight: 600;
      line-height: 24px;
      color: var(--text-color-1);
    }
  }
  .camera-scan-border {
    z-index: 9;
    position: absolute;

    width: 300px;
    height: 300px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    margin-top: 10vh;
    .anticon {
      // color: var(--text-color-1);
      color: #fff;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    // &::before {
    //   content: '';
    //   position: absolute;
    //   width: 300px;
    //   height: 300px;
    //   background-image: url('/images/pn-wc-border.png');
    //   background-repeat: no-repeat;
    //   background-size: 300px 300px;
    //   top: 0;
    //   left: 0;
    //   z-index: 1;
    // }
    &:after {
      content: '';
      position: absolute;
      width: 256px;
      height: 256px;
      top: 22px;
      left: 22px;
      border-radius: 22px;
      box-shadow: 0 0 0 2000px var(--mack-color-1);
      z-index: -1;
    }
    .scan-anime {
      background-image: url('/images/img_9_1.png');
      background-repeat: no-repeat;
      background-size: 300px 45px;
      width: 300px;
      height: 45px;
      -webkit-animation: 5s roll linear infinite normal;
      animation: 5s roll linear infinite normal;
      // @media screen and (min-width: 600px) {
      //   background-size: 500px 75px;
      //   width: 500px;
      //   height: 75px;
      // }
    }
    @keyframes roll {
      0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
      }

      100% {
        -webkit-transform: translateY(500%);
        transform: translateY(500%);
      }
    }
  }
  .camera-not-found {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: 300px 300px;
    width: 300px;
    height: 300px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    margin-top: 10vh;
    .text-hint {
      font-size: 20px;
      font-weight: 600;
      color: var(--text-color-2);
      text-align: center;
      line-height: 300px;
    }
  }
  .camera-content {
    position: absolute;
    width: 100%;
    top: calc(10vh + 330px);
    bottom: 70px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    // @media screen and (min-width: 600px) {
    //   top: calc(18vh + 530px);
    // }
    .camera-login {
      font-size: 24px;
      font-weight: 600;
      // font-family: SFProText-Semibold;
      line-height: 24px;
      color: var(--text-color-1);
    }
    .camera-text {
      width: 210px;
      font-size: 20px;
      font-weight: 600;
      // font-family: SFProText-Semibold;
      color: white;
      text-align: center;
    }
    .camera-text2 {
      font-size: 16px;
      width: 100%;
      // font-family: SFProText-Semibold;
      font-weight: 600;
      color: white;
      text-align: center;
      padding: 0 30px;
      line-height: 1.2;
    }
    .camera-btn {
      margin-top: 45px;
      display: flex;
      flex-direction: column;
      align-items: center;
      button {
        min-width: 176px;
        height: 47px;
        border-radius: 18px;
        font-size: 16px;
        font-weight: 600;
        // font-family: SFProText-Semibold;
      }
      .select-btn {
        margin-bottom: 15px;
        color: #fff;
        background: none;
        min-height: 47px;
        width: 100%;
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #fff;
          opacity: 0.2;
          pointer-events: none;
          box-sizing: initial;
          animation: initial;
        }
      }
      .connect-btn {
        margin-bottom: 15px;
        border: none;
        min-height: 47px;
        width: 100%;
      }
    }
  }

  .camera-success {
    margin-top: 38px;
    width: 200px;
    height: 200px;
    background: url('/images/duigou.png');
    -webkit-animation: success 1s steps(24) 200s infinite both;
    animation: success 1s steps(24) 200s infinite both;
  }

  @keyframes success {
    0% {
      background-position: 0 0;
    }
    4% {
      background-position: -100% 0;
    }
    8% {
      background-position: -200% 0;
    }
    12% {
      background-position: -300% 0;
    }
    16% {
      background-position: -400% 0;
    }
    20% {
      background-position: -500% 0;
    }
    24% {
      background-position: -600% 0;
    }
    28% {
      background-position: -700% 0;
    }
    32% {
      background-position: -800% 0;
    }
    36% {
      background-position: -900% 0;
    }
    40% {
      background-position: -1000% 0;
    }
    44% {
      background-position: -1100% 0;
    }
    48% {
      background-position: -1200% 0;
    }
    52% {
      background-position: -1300% 0;
    }
    56% {
      background-position: -1400% 0;
    }
    60% {
      background-position: -1500% 0;
    }
    64% {
      background-position: -1600% 0;
    }
    68% {
      background-position: -1700% 0;
    }
    72% {
      background-position: -1800% 0;
    }
    76% {
      background-position: -1900% 0;
    }
    80% {
      background-position: -2000% 0;
    }
    84% {
      background-position: -2100% 0;
    }
    88% {
      background-position: -2200% 0;
    }
    92% {
      background-position: -2300% 0;
    }
    96% {
      background-position: -2400% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
}

#reader-scan-qrcode {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none !important;
  display: block;
  border-color: rgba(0, 0, 0, 0.48);
  z-index: 9;
  background: var(--bg-color-4);
  .preview-canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    object-fit: cover;
  }
  video {
    width: auto !important;
    height: auto !important;
    opacity: 0 !important;
  }
  img[alt='Info icon'] {
    display: none;
  }

  #qr-shaded-region {
    display: none;
  }
}
