.footer-logo-container {
  @media screen and (min-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    min-height: 40px;
    width: 100%;
    .logo {
      width: 171px;
      height: 17px;
      background-image: var(--footer-icon-src);
      background-size: 100% 100%;
    }
  }
}
