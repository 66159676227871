.send-container {
  background: var(--content-background-color);
  min-height: 520px;
  position: relative;
  > .wrapper {
    @media screen and (min-width: 600px) {
      display: flex;
      flex-direction: column;
      min-height: 450px !important;
    }
  }
  .title-symbol {
    vertical-align: middle;
    @media screen and (max-width: 600px) {
      max-width: 144px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
    }
  }
  .send-text {
    vertical-align: middle;
  }
  .choose-token {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 18px;
    height: 60px;
    background-color: var(--background-color-1);
    border-radius: 18px;
    padding: 0 14px;
    cursor: pointer;
    &.choose-chain {
      margin: 0;
    }
    .left {
      .wrap {
        display: flex;
        align-items: center;
        .icon {
          width: 26px;
          height: 26px;
          border-radius: 100%;
          margin-right: 4px;
          overflow: hidden;
        }
        span {
          font-size: 20px;
          color: var(--text-color-1);
          line-height: 1;
          @media screen and (max-width: 600px) {
            max-width: 210px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .right {
      .micon {
        position: relative;
        right: -2px;
      }
    }
  }
  .send-main {
    padding-bottom: 10px;
    background: var(--content-background-color);
    @media screen and (min-width: 600px) {
      height: 0;
      flex: 1;
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      padding-top: 20px;
    }
    .form-content {
      padding: 0 var(--text-distance-1);
      padding-top: 14px;
      @media screen and (min-width: 600px) {
        height: 0;
        flex: 1;
      }
      .ant-form {
        @media screen and (min-width: 600px) {
          height: 100%;
          position: relative;
        }
        .click-cv {
          position: absolute;
          width: 150px;
          height: 20px;
          margin-top: 5px;
          z-index: 3;
        }
        .amount-input-content {
          .ant-input {
            height: 36px;
            font-size: 18px;
            color: var(--text-color-1);
          }
        }
        .input-content {
          border: 1px solid var(--background-color-1);
          border-radius: 18px;
          overflow: hidden;
          color: var(--text-color-1);
          background-color: var(--background-color-1);

          &:focus,
          &[data-focus='true'] {
            border-color: var(--accent-color);
          }
          &.to-input-content {
            .wrap {
              width: 100%;
              display: flex;
              height: 60px;
              align-items: center;
              justify-content: space-between;
              .left {
                flex: 1;
                padding-right: 10px;
                max-width: calc(100% - 24px);
                overflow: hidden;
                .div-input {
                  word-wrap: break-word;
                  word-break: normal;
                  word-break: break-all;
                  line-height: 1.1;
                  &:empty:before {
                    content: attr(placeholder);
                    color: var(--text-color-3);
                    font-size: 16px;
                  }
                }
              }
            }
          }

          .suffix-content {
            display: flex;
            align-items: center;
            .icon1 {
              width: 22px;
              height: 22px;
              // margin-right: 10px;
              cursor: pointer;
              background-image: var(--icon-src-13);
              background-size: 100% 100%;
            }
            .icon2 {
              width: 22px;
              height: 22px;
            }
          }
          .usd-content {
            font-size: 12px;
            font-weight: 400;
            color: var(--text-color-2);
            padding-left: 14px;
            padding-bottom: 4px;
          }
          &.fee-input-content,
          &.total-input-content {
            height: 62px;
            border-color: #cccdce;
            display: flex;
            align-items: center;
            padding: 0 10px;

            font-size: 12px;
            font-weight: normal;
            color: var(--text-color-1);
            display: flex;
            align-items: center;
            .wrap {
              width: 100%;
              display: flex;
              justify-content: space-between;
              .gray-color {
                color: #cccdce;
                margin-left: 4px;
              }
              .right {
                .sub-wrap {
                  display: flex;
                  .sub-left {
                    margin-right: 4px;
                    .row2 {
                      color: var(--message-success-color);
                      font-weight: bold;
                    }
                  }
                  .sub-right {
                    display: flex;
                    align-items: center;
                    .icon {
                      width: 17px;
                      height: 34px;
                    }
                  }
                }
              }
            }
          }
        }
        .ant-form-item {
          margin-bottom: 14px;
        }
        .ant-form-item-label {
          padding-bottom: 0;
          width: 100%;
          & > label {
            width: 100%;
            &::after {
              display: none;
            }
          }
          label {
            font-size: 16px;
            color: var(--text-color-1);
          }
          .amount-label-content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .tips-Nx9 {
              font-size: 12px;
              font-weight: normal;
              color: var(--pending-color);
              line-height: 1;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              cursor: pointer;
              .symbol {
                @media screen and (max-width: 600px) {
                  max-width: 100px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  display: inline-block;
                }
              }
            }
          }
          .fee-label-content {
            padding-top: 30px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .refresh-content {
              font-size: 14px;
              font-weight: 500;
              color: var(--text-color-2);
            }
            .fee-value {
              font-size: 16px;
              font-weight: 400;
              // color: var(--text-color-2);
            }
          }
        }
        .fee-value-son {
          font-size: 12px;
          font-weight: 400;
          color: var(--text-color-2);
          position: absolute;
          right: 0;
          top: 0;
        }
        .ant-form-item-extra {
          font-size: 12px;
          font-weight: 400;
          color: var(--accent-color);
          padding-top: 6px;
          padding-left: 0px;
          cursor: pointer;
        }
        .form-footer {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 0 var(--text-distance-1);
          z-index: 1;
          text-align: center;
          margin: 0;
          @media screen and (min-width: 600px) {
            padding: 0 calc(70px - 24px);
            height: 46px;
            justify-content: space-between;
            align-items: center;
          }
          .ant-btn-primary {
            width: 100%;
            max-width: 340px;
            @media screen and (min-width: 600px) {
              width: 380px;
              max-width: 380px;
            }
          }
        }
      }
    }
  }
}
