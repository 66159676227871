.new-address-container {
  min-height: 450px;
  > .wrapper {
    background-color: var(--content-background-color) !important;
    height: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 600px) {
      display: flex;
      flex-direction: column;
    }
  }
  .add-address-main {
    padding: 0 var(--text-distance-1);
    padding-top: 10px;
    height: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 600px) {
      height: 0;
      flex: 1;
      position: relative;
    }
    .tips-Nx9 {
      display: flex;
      align-items: center;
      height: 60px;
      background-color: var(--background-color-1);
      border-radius: 18px;
      padding-left: 10px;
      padding-right: 10px;
      font-size: 16px;
      font-weight: 400;
      color: var(--text-color-1);
      display: flex;
      align-items: center;
      .icon {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
    }
    .form-content {
      padding-top: 18px;
      height: 1;
      flex: 1;
      .ant-form {
        height: 100%;
        position: relative;
      }
      .ant-form-item {
        // margin-bottom: 10px;
        padding-bottom: 10px;
      }
      .ant-form-item-label {
        padding: 0;
        label {
          font-size: 16px;
          line-height: 1;
          color: var(--text-color-1);
          font-weight: bold;
          &:before {
            display: none;
          }
        }
      }
      .ant-input-number {
        width: 100%;
        border-radius: 10px;
        // height: 46px;
        overflow: hidden;
        .ant-input-number-handler-wrap {
          // width: 100%;
          // opacity: 1;
        }
      }

      .ant-input,
      .ant-input-number-input {
        // height: 46px;
        // line-height: 46px;

        border-radius: 10px;
        padding: 11px 11px;
        font-size: 14px;
        font-weight: 600;
        color: var(--text-color-1);
        &:hover {
        }
      }
      .ant-input:focus,
      .ant-input-focused,
      .ant-input-number:focus,
      .ant-input-number-focused {
        box-shadow: none;
      }
      .form-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0 12px;
        z-index: 1;
        margin-bottom: 0;
        // padding-bottom: calc(var(--footer-height) - 8px + constant(safe-area-inset-bottom) / 3);
        // padding-bottom: calc(var(--footer-height) - 8px + env(safe-area-inset-bottom) / 3);
        padding: 0;
        @media screen and (min-width: 600px) {
          padding: 0 0px;
          height: 46px;
          align-items: center;
        }
        .wrap {
          @media screen and (min-width: 600px) {
            padding: 0 70px;
          }
        }
        .form-add {
          display: flex;
          justify-content: center;

          @media screen and (min-width: 600px) {
            padding: 0 70px;
          }
        }
        .form-edit {
          button {
            width: 48%;
            @media screen and (min-width: 600px) {
              width: 45%;
            }
          }
        }
        .ant-btn-primary {
          width: 480px;
          max-width: 100%;
          font-size: 17px;
          font-weight: bold;
          @media screen and (min-width: 600px) {
            width: 380px;
          }
        }
      }
      .wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .ant-btn-primary,
        button {
          width: 100%;
        }
      }
    }
  }
}
