.default-layout-footer.page-nftDetail {
  display: none;
}
._page_nftDetail .layout-main {
  overflow: hidden;
}

.nft-detail-container {
  position: relative;
  background-color: var(--content-background-color);
  height: 100% !important;
  overflow: auto;

  @media screen and (max-width: 600px) {
    // 禁止 页面滚动回弹
    -webkit-overflow-scrolling: touch;
  }

  .wrapper-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    // height: 100%;
    background-size: 100%;
    background-repeat: repeat-y;
    filter: blur(100px);
    display: none;

    @media screen and (min-width: 600px) {
      display: none;
    }
  }

  > .wrapper {
    position: relative;
    z-index: 2;
    background-color: var(--content-background-color) !important;

    @media screen and (min-width: 600px) {
      display: flex;
      flex-direction: column;
      color: var(--text-color-1) !important;
    }
    .header-container {
      @media screen and (max-width: 600px) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }
  .main-content {
    overflow-x: hidden;
    max-width: 100vw;
    @media screen and (min-width: 600px) {
      height: 0px;
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .content-wrapper {
      padding-bottom: 120px;

      @media screen and (min-width: 600px) {
        padding-bottom: 0px;
        height: 0;
        flex: 1;
        overflow: auto;
        padding-bottom: 20px;
      }
      box-sizing: border-box;
    }
    @media screen and (min-width: 600px) {
      height: 0px;
      flex: 1;
    }

    .info-content {
      padding: 0px var(--text-distance-1);

      .detail-box {
        width: 100%;
        min-height: 39px;
        background: var(--background-color-1);
        border-radius: 18px;
        margin-top: 15px;
        padding: 14px 14px 5px;
        margin-bottom: 0px;

        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 9px;
        }

        .right {
          display: flex;
          align-items: center;
          white-space: nowrap;
          position: relative;
          box-sizing: border-box;
          flex: 1;
          justify-content: flex-end;
          max-width: 70%;
          color: var(--text-color-1);
          // path {
          //   fill: var(--text-color-1);
          // }
          & > div {
            max-width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .copy-icon {
            width: 14px;
            height: 14px;
            margin-left: 4px;
            cursor: pointer;
            svg {
              color: var(--text-color-1);
            }
          }
        }
      }
      .no-description,
      .no-attributes {
        text-align: center;
        width: 100%;
        height: 50px;
        line-height: 50px;
        opacity: 0.5;
      }
      .attrs-list {
        width: 100%;
        overflow-x: auto;
        display: flex;
        align-items: center;
        margin-top: 20px;
        padding-bottom: 10px;

        @media screen and (min-width: 600px) {
          flex-wrap: wrap;
          row-gap: 20px;
        }

        .attrs-list-item {
          height: 56px;
          background: var(--background-color-1);
          border-radius: 18px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 0 14px;

          span {
            font-size: 12px;
            font-weight: 500;
            line-height: 15px;
            color: var(--text-color-2) !important;

            opacity: 0.7;
          }

          h2 {
            font-size: 16px;
            font-weight: 600;
            line-height: 14px;
            color: var(--text-color-1) !important;

            margin-top: 4px;
            white-space: nowrap;
          }
        }
      }

      .info-box {
        margin-top: 20px;
        color: var(--text-color-2) !important;

        .info-line {
          width: 100%;
          height: 0px;
          opacity: 0.7;
          margin-top: 12px;
        }

        .info-box-more {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: var(--text-color-1);
          margin-top: 4px;
        }

        .info-box-body {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-top: 12px;
          white-space: pre-wrap;
          word-break: keep-all;
        }

        .info-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          font-size: 18px;
          font-weight: 600;
          line-height: 14px;
          color: var(--text-color-1);
          margin-top: 36px;
          span {
            font-size: 14px;
          }
        }
      }
      .info1 {
        padding-bottom: 20px;
        .p1 {
          font-size: 20px;
          font-weight: 600;
          line-height: 1.2;
          color: var(--text-color-1);
          margin-top: 10px;
          word-break: break-all;
        }
        .p2 {
          font-size: 14px;
          font-weight: 600;
          color: #2d6af6;
          line-height: 14px;
        }
      }
      .info2 {
        margin-top: 10px;
        .row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 1.8;
          margin-bottom: 10px;
          .left {
            font-size: 14px;
            font-weight: 600;
            color: var(--text-color-1);
          }
          .right {
            font-size: 14px;
            font-weight: 400;
            color: var(--text-color-2);
            display: flex;
            align-items: center;
          }
        }
      }
    }
    .foot-logo1 {
      background-image: var(--footer-icon-src);
      width: 100%;
      height: 40px;
      background-size: 171px 17px;
      background-repeat: no-repeat;
      background-position: center center;
      @media screen and (min-width: 600px) {
        margin-bottom: 0;
      }
    }
    .footer {
      background-color: var(--content-background-color);
      position: fixed;
      bottom: 0px;
      height: 86px;
      display: flex;
      align-items: center;
      left: 0;
      padding: 0 var(--text-distance-1);
      width: 100%;
      justify-content: center;
      flex-direction: column;

      @media screen and (min-width: 600px) {
        position: initial;
        padding: 0 70px;
        height: 86px;
        align-items: center;
        bottom: 0;
        margin-top: 50px;
        margin-bottom: 10px;
        margin: 0;
      }

      button {
        font-size: 17px;
        font-weight: bold;
        height: 46px;
        width: 100%;
        max-width: 400px;
      }
    }
  }

  .c-pointer {
    cursor: pointer;
    text-decoration: underline;
  }
  .detail-icon {
    display: flex;
    align-items: center;
  }
}

.nft-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  padding: 0 var(--text-distance-1);
  box-sizing: border-box;
  // position: sticky;
  // top: 0;
  // left: 0;
  z-index: 99;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: var(--text-color-1);

  span {
    color: var(--text-color-1);
  }

  .nft-back-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    background-image: var(--back-icon-src);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .nft-right-icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
    background-image: var(--nft-icon-share);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .nft-right-box {
    width: 15px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
  }

  .nft-left-box {
    width: 15px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 20px;
  }
}
