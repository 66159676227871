.hidden-token-list-container {
  > .wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    background: var(--content-background-color) !important;
    .no-record-container {
      padding-top: 20vh;
    }
  }

  .foot-logo {
    background-image: var(--footer-icon-src);
    background-size: 100% auto;
    width: 200px;
    height: 21px;
    margin-bottom: 10px;
    left: calc(50% - 100px);
    position: fixed;
    bottom: 10px;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}
