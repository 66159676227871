.wallet-particle-approve-popup {
  position: fixed;
  z-index: 100;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 18px;
  padding: 0px 18px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  -webkit-box-shadow: 4px 4px 20px 1px rgba(180, 180, 180, 0.6);
  box-shadow: 4px 4px 20px 1px rgba(180, 180, 180, 0.6);
  top: 30px;
  transition: right 0.3s, top 0.3s;
  right: -600px;
  width: auto;
  height: 78px;
  opacity: 0;
  padding-right: 0;
  display: none;
}
@media screen and (max-width: 600px) {
  .wallet-particle-approve-popup {
    max-width: 90%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    padding-left: 12px;
    right: 0;
    top: -100px;
    width: 90%;
  }
}

.wallet-particle-approve-popup.wallet-particle-approve-popup-display {
  display: flex;
  opacity: 1;
}

.wallet-particle-approve-popup.wallet-particle-approve-popup-show {
  right: 30px;
  opacity: 1;
}

@media screen and (max-width: 600px) {
  .wallet-particle-approve-popup.wallet-particle-approve-popup-show {
    right: 0;
    top: 30px;
    opacity: 1;
  }
}
.wallet-particle-approve-popup .wallet-particle-pam-left {
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex: 1;
}
@media screen and (max-width: 600px) {
  .wallet-particle-approve-popup .wallet-particle-pam-left {
  }
}
.wallet-particle-approve-popup .wallet-particle-pam-left:after {
  content: '';
  position: absolute;
  width: 1px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  top: 0;
  right: 0px;
  height: 100%;
}
.wallet-particle-approve-popup .wallet-particle-pam-left .wallet-particle-pam-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.wallet-particle-approve-popup .wallet-particle-pam-left .wallet-particle-pam-wrap .wallet-particle-pam-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 42px;
  height: 42px;
  min-width: 42px;
  margin-right: 10px;
  border-radius: 42px;
  position: relative;
  overflow: hidden;
}
.wallet-particle-approve-popup .wallet-particle-pam-left .wallet-particle-pam-wrap .wallet-particle-pam-img img {
  width: 100%;
  height: 100%;
}
.wallet-particle-approve-popup .wallet-particle-pam-left .wallet-particle-pam-wrap .wallet-particle-pam-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 240px;
  min-width: 170px;
}
.wallet-particle-approve-popup .wallet-particle-pam-left .wallet-particle-pam-wrap .wallet-particle-pam-content .wallet-particle-pam-title {
  font-weight: bold;
  font-size: 17px;
  color: #181b1f;
}
.wallet-particle-approve-popup .wallet-particle-pam-left .wallet-particle-pam-wrap .wallet-particle-pam-content .wallet-particle-pam-text {
  font-size: 12px;
  color: #8b8ea0;
  opacity: 0.8;
  line-height: 12px;
  padding-right: 8px;
}
.wallet-particle-approve-popup .wallet-particle-pam-right {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #2d6af6;
  font-weight: bold;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding: 0 10px;
  min-width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wallet-particle-approve-popup .wallet-particle-pam-right:hover {
  color: #2d6af6;
  font-weight: bold;
}
